import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Alert, Card, CardContent, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useContext, useState } from 'react';
import UploadFileComponent from './functions/uploadFile'
import { Campaign } from '../../models/Campaign';
import toast from 'react-hot-toast';
import { CampaignClient } from '../../utils/axios/CampaignClient';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../Store/store';
import ReactCountryFlag from "react-country-flag"

const campaignClient = new CampaignClient();

const CreateACampaign = () => {

    const [activeStep, setActiveStep] = useState(0);
    const [campaignName, setCampaignName] = useState('')
    const [campaignType, setCampaignType] = useState('');
    const [campaignCountry, setCampaignCountry] = useState('');
    const [campaignTags, setCampaignTags] = useState('');
    const [shoppingUrl, setShoppingUrl] = useState('');
    const [showError, setShowError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([])
    const [templateId, setTemplateId] = useState('');
    const campaignObject = new Campaign('title', 'publishDate', 'tags', 'shoppingUrl', 'exponea_template_id', 'type', 'country', 'pdf', 'pdf_name');
    const [campaign, setCampaign] = useState(campaignObject);
    const date = new Date().toISOString().slice(0, 10);
    const [fileToUpload, setFileToUpload] = useState('')
    const [fileName, setFileName] = useState('')
    const [state, setState] = useContext(Context)
    const campaignTypes = []
    let navigate = useNavigate();


    function createCampaignObject() {
        campaign.title = campaignName
        campaign.publish_date = date
        campaign.tags = campaignTags
        campaign.shopping_url = shoppingUrl
        campaign.exponea_template_id = templateId
        campaign.campaign_type = campaignType
        campaign.campaign_country = campaignCountry
        campaign.pdf = fileToUpload;
        campaign.pdf_name = fileName
    }

    function postCampaign() {
        if (templateId){
            createCampaignObject()
            campaignClient.postCampaign(0, campaign)
            .then(res => {
                toast.success('Campaign saved')
                navigate(`/admin/Campaigns`)
            })
            .catch(err => {
                toast.success('Error Occured' + err)
            });
        } else {
            toast.error('No templateId entered, Enter a template Id')
        }

    }


    const validation = () => {
        setValidationErrors([])
        let count = 0;

        if (campaignName === ''){
            setValidationErrors(oldArray => [...oldArray, "Name"]);
            count = count +1
        } 
        
        if (campaignType === ''){
            setValidationErrors(oldArray => [...oldArray, "Type"]);
            count = count +1
        }
        
        if (campaignCountry === ''){
            setValidationErrors(oldArray => [...oldArray, "Country"]);
            count = count +1
        }

        if (templateId === '' && activeStep === 1) {
            setValidationErrors(oldArray => [...oldArray, "Template ID"]);
            count = count +1
        }
        count > 0 ? setShowError(true) : setShowError(false)
    }

    
    const handleNext = () => {  

        validation()

        if (activeStep === 0 && campaignName !== '' && campaignCountry !== '' && campaignType !== '') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }        

        if (activeStep === 1 && templateId !== ''){
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        if (activeStep === 2) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        
      };
    
    const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
    setActiveStep(0);
    };

    const handleSetCampaignName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaignName(event.target.value)
    }

    const handleChangeType = (event: SelectChangeEvent) => {
    setCampaignType(event.target.value as string);
    };

    const handleChangeCountry = (event: SelectChangeEvent) => {
    setCampaignCountry(event.target.value as string);
    };

    const steps = [
        {
        label: 'Select campaign settings',
        introText : `First, lets set some vital settings: `,
        description:  <>
                            <Box my={2}>
                                <TextField label="Campaign Name" fullWidth onChange={handleSetCampaignName} value={campaignName} size="small" required/>
                            </Box>
                            <Box my={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="campaign-select-type-label">Campaign Type</InputLabel>
                                    <Select
                                    labelId="campaign-select-type-label"
                                    id="campaign-select-type"
                                    value={campaignType}
                                    label="Campaign Type"
                                    onChange={handleChangeType}
                                    required
                                    >
                                    {state.campaign_types.map(item => (
                                        <MenuItem key={item.campaign_type} value={item.campaign_type}>{item.campaign_type}</MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box my={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="campaign-select-country-label">Campaign Country</InputLabel>
                                    <Select
                                    labelId="campaign-select-country-label"
                                    id="campaign-select-country"
                                    value={campaignCountry}
                                    label="Campaign Country"
                                    onChange={handleChangeCountry}
                                    required
                                    >
                                    {state.campaign_countries.map(item => (
                                        <MenuItem key={item.campaign_country_abbreviation} value={item.campaign_country}><ReactCountryFlag countryCode={item.campaign_country_abbreviation} svg /> <span style={{marginLeft:'5px'}}>{item.campaign_country}</span></MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            {showError && (
                            <Box>
                                <Alert severity="error">
                                    You didn't provide a: 
                                        {validationErrors.map(item => (
                                                ` ${item}, `                                           
                                        ))}
                                </Alert>
                            </Box>
                            )}
                        </>,
        },
        {
        label: 'Email settings',
        introText : `Now let's handle the settings for emailing: `,
        description:
            <>
            <Box my={2}>
                <Card>
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                            Template ID
                        </Typography>
                        <Typography variant="body2" component="span">
                            Insert the exponea template id
                        </Typography>
                        <Box my={2}>
                            <TextField
                                id="templateId"
                                label="Template ID"
                                helperText=""
                                variant="outlined"
                                size="small"
                                value={templateId}
                                fullWidth
                                required
                                onChange={event => {
                                    setTemplateId(
                                        event.target.value)
                                }}
                            />
                        </Box>
                        {showError && (
                            <Box>
                                <Alert severity="error">
                                    You didn't provide a: 
                                        {validationErrors.map(item => (
                                                ` ${item}, `                                           
                                        ))}
                                </Alert>
                            </Box>
                        )}
                    </CardContent>
                </Card>            
            </Box>
            <Box my={2}>
                <Card>
                    <CardContent>
                        <UploadFileComponent fileToUpload={fileToUpload} setFileToUpload={setFileToUpload} setFileName={setFileName}/>
                    </CardContent>
                </Card>
            </Box>
            </>,
        },
        {
        label: 'Extra settings',
        introText : `Some extra settings which are optional `,
        description:
            <>
            <Box my={2}>
                <TextField
                    id="tags"
                    label="Tags"
                    variant="outlined"
                    size="small"
                    value={campaignTags}
                    fullWidth
                    onChange={event => {
                        setCampaignTags(
                            event.target.value)
                    }}
                />
            </Box>
            <Box my={2}>
                <TextField
                    id="shoppingUrl"
                    label="Shopping URL"
                    variant="outlined"
                    size="small"
                    value={shoppingUrl}
                    fullWidth
                    required
                    onChange={event => {
                        setShoppingUrl(
                            event.target.value)
                    }}
                />
            </Box>
            
            </>,
        },
    ];

    

    return (
        <>
            <Box mb={3}>
                <Typography variant="h4">
                    Create a Campaign
                </Typography>
            </Box>
            <Box sx={{ maxWidth: 500 }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel>
                        {step.label}
                        </StepLabel>
                        <StepContent>
                        <Typography>{step.introText}</Typography>
                        <Box my={2}>
                            <Typography>{step.description}</Typography>
                        </Box>
                        
                        <Box sx={{ mb: 2 }}>
                            <div>
                            <Button
                                variant="contained"
                                onClick={handleNext}
                                sx={{ mt: 1, mr: 1 }}
                            >
                                {index === steps.length - 1 ? 'Finish' : 'Continue'}
                            </Button>
                            <Button
                                disabled={index === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                            >
                                Back
                            </Button>
                            </div>
                        </Box>
                        </StepContent>
                    </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Card>
                        <CardContent>
                            <Typography>All done! You can now save the campaign or reset and start over!</Typography>
                            <Button onClick={postCampaign} sx={{mt: 1, mr:1}} variant="contained">
                                Save Campaign
                            </Button>
                            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                Reset
                            </Button>
                        </CardContent>
                    </Card>
                )}
            </Box>
        </>
    )
}

export default CreateACampaign;