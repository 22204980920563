import {BackendClient} from './_BackendClient'

export class CollaborationClient extends BackendClient {
    constructor(){
        super();
        this.apiurl = this.apiurl + '/collaboration'
    }

    public getAllCollaborations(id:string, status?:string){
        return this.makeRequest('GET', `in-campaign/${id}`)
    }

    public createCollaboration(createCollaborationDataObject:any){
        return this.makeRequest('POST', 'add-to-campaign', createCollaborationDataObject)
    }

    public deleteCollaboration(deleteCollaborationDataObject:any){
        return this.makeRequest('PUT', 'remove-from-campaign', deleteCollaborationDataObject)
    }

    public updateShoppingMoneyAndSwipeUpLink(updateShoppingMoneyAndSwipeUpLinkDataObject:any){
        return this.makeRequest('PUT', 'update-shopping-money-swipe-up-link', updateShoppingMoneyAndSwipeUpLinkDataObject)
    }

    public sendReminderToPost(sendReminderToPostDataObject:any){
        return this.makeRequest('POST', 'post-reminder', sendReminderToPostDataObject)
    }

    public sendSwipeUpLinkEmail(sendSwipeUpLinkEmailDataObject:any){
        return this.makeRequest('POST', 'swipe-up-link', sendSwipeUpLinkEmailDataObject)
    }

    public renewShoppingMoneyVoucher(sendRenewShoppingMoneyVoucherDataObject: any){
        return this.makeRequest("POST", 'renew-voucher', sendRenewShoppingMoneyVoucherDataObject)
    }

    public updateStatus(statusUpdatedCollaboration:any){
        return this.makeRequest("POST", "update-status", statusUpdatedCollaboration)
    }

    public removeFromCampaign(collaborationsToRemove:any){
        return this.makeRequest("PUT", "remove-from-campaign", collaborationsToRemove)
    }
}