import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    Link,
    Typography,
    Checkbox,
    CircularProgress,
    FormHelperText,
    Grid,
} from '@mui/material';
import { Formik } from 'formik';
import Page from '../page/Page';
import StatusAlert from './components/InfluencerRegistration/FormComponents/statusAlert'
import LanguageSwitch from '../multilanguage/languageSwitch'
import { InfluencerClient } from '../../utils/axios/InfluencerClient'
import influencerRegistrationValidator from '../../utils/formik/Validatiors/influencerRegistrationValidator'
import influencerRegistrationInitialValues from '../../utils/formik/initialValues/influencerRegistrationInitialValues'
import InfluencerRegistrationFormPartOne from './components/InfluencerRegistration/FormParts/influencerRegistrationFormPartOne'
import InfluencerRegistrationFormPartTwo from './components/InfluencerRegistration/FormParts/influencerRegistrationFormPartTwo'
import InfluencerRegistrationFormPartThree from './components/InfluencerRegistration/FormParts/influencerRegistrationFormPartThree'
import InfluencerRegistrationFormSuccesPage from './components/InfluencerRegistration/FormParts/influencerRegistrationFormSuccesPage'
import { Utilities } from '../../utils/axios/utilities';
import { FormatString } from '../../utils/formatString';
import DateFunctions from '../../utils/dates/dateClass';
import PublicBackendClient from '../../utils/axios/_PublicBackendClient';



const InfluencerRegistrationForm = () => {
    const [isAlertVisible, setAlertVisible] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isRetry, setIsRetry] = useState<boolean>(false);
    const [isErrorAlertVisible, setErrorAlertVisible] = useState<boolean>(false);
    const [isApiErrorVisible, setisApiErrorVisible] = useState<boolean>(false);
    const [isEmailRegistered, setisEmailRegistered] = useState<boolean>(false);
    const [statusSuccess, setStatusSuccess] = useState<boolean>(false);
    const [showFirstForm, setShowFirstForm] = useState<boolean>(true);
    const [showSecondForm, setShowSecondForm] = useState<boolean>(false);
    const [showThirdForm, setShowThirdForm] = useState<boolean>(false);
    const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false);
    const [statusError, setStatusError] = useState('');
    const [nextRetryMessage, setNextRetryMessage] = useState('');
    const publicClient = new PublicBackendClient();
    const axiosUtils = new Utilities();
    const dateFunctions = new DateFunctions();
    const { t } = useTranslation();

    const goBackOnePage = () => {
        setShowSecondForm(true);
        setShowThirdForm(false);
        setShowSubmitButton(false)
    }

    const submitForm = (
        values, {
            resetForm,
            setErrors,
            setStatus,
            setSubmitting
        }) => {
        try{
            publicClient.createInfluencerFromRegistration(
                axiosUtils.createInfluencerCreatePayload(values)
            )
            .then(res => {
                if (res.status === 200 && res.data.RESULTS.response_code !== 11) {
                    resetForm();
                    setStatus({ success: true });
                    setStatusSuccess(true);
                    setAlertVisible(true);
                    setSubmitting(false);
                    setShowThirdForm(false);
                    setShowFirstForm(false);
                    setShowSubmitButton(false);
                    setIsRetry(res.data.RESULTS.response_code === 10);
                    if (isApiErrorVisible || isErrorAlertVisible || statusSuccess) {
                        setisApiErrorVisible(false);
                        setErrorAlertVisible(false);
                        setisEmailRegistered(false);
                    }
                    setIsSuccess(true);
                } else if (res.status === 200 && res.data.RESULTS.response_code === 11) {
                    setShowFirstForm(true);
                    setShowSubmitButton(false);
                    setShowThirdForm(false);
                    setSubmitting(false);
                    setCannotRetryMessage(res.data.RESULTS.next_retry_date);
                    setisEmailRegistered(true);
                } else {
                    setSubmitting(false);
                }
            })
            .catch(error => {
                console.log(error)
                setSubmitting(false);
                setisApiErrorVisible(true)
                
            })
        } catch(err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setStatusError(err.message)
            setSubmitting(false);
            setisApiErrorVisible(true)
        }
    }

    const returnFromSuccessToStart = () => {
            setShowSecondForm(false)
            setShowThirdForm(false)
            setShowFirstForm(true)
            setIsSuccess(false)
            setShowSubmitButton(false)
            if (isApiErrorVisible || isErrorAlertVisible ){
                setisApiErrorVisible(false)
                setErrorAlertVisible(false)
                setStatusSuccess(false)
            }
    }

    const setCannotRetryMessage = (dateTime) => {
        let msg = t('cannotRetryRegistration');
        let date = dateFunctions.genericDate(dateTime)
        setNextRetryMessage(new FormatString(msg).format(date));
    }

    return (
        <Page
            title="My jewellery influencer registration"
        >
            <Container
                maxWidth="sm"
            >
                <Box
                    mb={8}
                    mt={8}
                    display="flex"
                    justifyContent="center"
                >
                    <img style={{ width: '150px', filter:'grayscale(1)' }} alt='mjlogo' src='https://www.my-jewellery.com/media/logo/default/my-jewellery-logo.png' />
                </Box>
                <Box mb={3}>
                    <div>
                            <Formik
                            initialValues={influencerRegistrationInitialValues}
                            validationSchema={influencerRegistrationValidator()}
                            onSubmit={submitForm}
                        >
                            {({
                                errors,
                                setErrors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values
                            }) => (
                                <div>
                                    {isSuccess ? (
                                        <InfluencerRegistrationFormSuccesPage goBack={returnFromSuccessToStart} isRetry={isRetry}/>
                                        ) : 
                                        (
                                        <div>
                                        <Card>
                                        <Box m={3}>
                                            <Grid container spacing={1}>
                                                <Grid item md={8} className="flex">
                                                    <Typography variant="h5" className="centerSelf">
                                                        {t('Registration')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={4} style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}} >
                                                    <LanguageSwitch/>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Divider />
                                            <CardContent>
                                                {isErrorAlertVisible ? (
                                                    <StatusAlert
                                                        onCloseAction={() => setErrorAlertVisible(false)}
                                                        severityType={"error"}
                                                        textcontent={t("nosocialInput")}
                                                    />
                                                ) : ('')}
                                                {isApiErrorVisible ? (
                                                    <StatusAlert
                                                        onCloseAction={() => setisApiErrorVisible(false)}
                                                        severityType={"error"}
                                                        textcontent={t("apiError")}
                                                    />
                                                ) : ('')}
                                                {isEmailRegistered ? (
                                                    <StatusAlert
                                                        onCloseAction={() => setisEmailRegistered(false)}
                                                        severityType={"error"}
                                                        textcontent={nextRetryMessage}
                                                    />
                                                ) : ('')}


                                                {isSubmitting  ? (
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        my={5}
                                                    >
                                                        <CircularProgress />
                                                    </Box>
                                                )
                                                : 
                                                    (
                                                        <form onSubmit={handleSubmit}>
                                                            {showFirstForm ? (
                                                                <InfluencerRegistrationFormPartOne
                                                                    values={values}
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    handleBlur={handleBlur}
                                                                    handleChange={handleChange}
                                                                    showFirstForm={setShowFirstForm}
                                                                    showSecondForm={setShowSecondForm}
                                                                />
                                                            ) : ''}
                                                            {showSecondForm ?
                                                                <InfluencerRegistrationFormPartTwo
                                                                    values={values}
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    handleBlur={handleBlur}
                                                                    handleChange={handleChange}
                                                                    showThirdForm={setShowThirdForm}
                                                                    showFirstForm={setShowFirstForm}
                                                                    showSecondForm={setShowSecondForm}
                                                                    showSubmitButton={setShowSubmitButton}
                                                                    isAlertVisible={setErrorAlertVisible}
                                                                />
                                                                : ''}
                                                            {showThirdForm ? (
                                                                <InfluencerRegistrationFormPartThree
                                                                    values={values}
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    handleBlur={handleBlur}
                                                                    handleChange={handleChange}
                                                                />
                                                            ) : ''}
                                                            {showSubmitButton ? (
                                                                <div className="showSubmit" id="showSubmit">
                                                                    <Box
                                                                        alignItems="center"
                                                                        display="flex"
                                                                        mt={2}
                                                                        ml={-1}
                                                                    >
                                                                        <Checkbox
                                                                            checked={values.policy}
                                                                            name="policy"
                                                                            onChange={handleChange}
                                                                        />
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="textSecondary"
                                                                        >
                                                                            {t('iHaveReadThe')} {' '}
                                                                            <Link
                                                                                component="a"
                                                                                href={t("InfluencerTermsLink")}
                                                                                color="primary"
                                                                                target="_blank"
                                                                            >
                                                                                {t('termsAndConditions')}
                                                                            </Link>
                                                                        </Typography>
                                                                    </Box>
                                                                    {Boolean(touched.policy && errors.policy) && (
                                                                        <FormHelperText error>
                                                                            {errors.policy}
                                                                        </FormHelperText>
                                                                    )}

                                                                    <Box mt={2}>
                                                                        <Grid container spacing={1}>                                                                            
                                                                            <Grid item md={6}>
                                                                                <Button
                                                                                    fullWidth
                                                                                    size="large"
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    onClick={goBackOnePage}
                                                                                >
                                                                                    {t('goBack')}
                                                                                </Button>
                                                                            </Grid>
                                                                            <Grid item md={6}>
                                                                                <Button
                                                                                color="primary"
                                                                                disabled={isSubmitting}
                                                                                fullWidth
                                                                                size="large"
                                                                                type="submit"
                                                                                variant="contained"
                                                                                >
                                                                                    {t('signUp')}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                        

                                                                    </Box>
                                                                </div>
                                                            ) : ''}

                                                        </form>
                                                    )}
                                            </CardContent>
                                            </Card>
                                        </div>
                                        )
                                    }
                                </div>
                            )}
                        </Formik>
                        
                    </div>
                </Box>
            </Container>
        </Page>
    );
    };
export default InfluencerRegistrationForm;
