import { useState, FC, useContext, useEffect } from 'react';
import type {
    ChangeEvent
} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Grid,
    InputAdornment,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    IconButton,
    Tooltip,
    Card,
    Tabs,
    Tab,
    Autocomplete,
} from '@mui/material';
import {
    Search as SearchIcon,
    Edit as EditIcon,
    Users as UsersIcon,
} from 'react-feather';
import { CampaignClient } from '../../../utils/axios/CampaignClient'
import { useNavigate } from "react-router-dom";
import 'moment/min/locales';
import DateFunctions from '../../../utils/dates/dateClass';
import SkeletonTable from '../../../components/skeletonTable/skeletonTable';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {Context} from '../../../Store/store'
import { forEach } from 'lodash';
import ReactCountryFlag from 'react-country-flag';

const dateFunctions = new DateFunctions();


interface ResultsProps {
    className?: string;
    campaigns: any[];
    selectedProducts: any[];
    setSelectedProducts: any;
    loading: boolean;
    setLoading: (isLoading: boolean) => void;
    getArchivedCampaigns: any;
    getCampaigns: any;
    filterByCountry: any;
}

interface Filters {
    status?: any,
    contact?: string,
    updateDate?: string,
    shoppingMoney?: string,
    campaignCountryFilter?: string
}

const columns = [
    { field: 'title', headerName: 'Title' },
    { field: 'campaign_type', headerName: 'Type' },
    { field: 'campaign_country', headerName: 'Country' },
    { field: 'publish_date', headerName: 'Publish date' },
    { field: 'numberOfInfluencers', headerName: 'Number of influencers' },
    { field: 'actions', headerName: 'Actions' }
];


const applyFilters = (campaigns: any[], query: string, filters: Filters): any[] => {
    return campaigns.filter((campaigns) => {
        let matches = true;


        if (query.toLowerCase() && !campaigns.title.toLowerCase().includes(query.toLowerCase())) {
            matches = false;
        }

        return matches;
    });
};

const applyPagination = (campaigns: any[], page: number, limit: number): any[] => {
    campaigns = campaigns.slice(page * limit, page * limit + limit);
    return campaigns
};

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}




const Results: FC<ResultsProps> = ({ className, campaigns, setSelectedProducts, selectedProducts, loading, setLoading, getCampaigns, getArchivedCampaigns, filterByCountry }) => {
    const campaignClient = new CampaignClient()
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0)
    const [state, setState] = useContext(Context)
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [query, setQuery] = useState<string>('');
    const [filters, setFilters] = useState<Filters>({
        status: null,
        contact: null,
        updateDate: null,
        shoppingMoney: null,
        campaignCountryFilter: null
    });

    const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.persist();
        setQuery(event.target.value);

    };

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };

    const goToCampaign = (campaignId) => {
        navigate("/admin/campaignDetail/" + campaignId);
    }

    const goToCollaboration = (campaignId) => {
        navigate("/admin/collaboration/" + campaignId);
    }

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        switch (newValue) {
            case 0:
                getCampaigns()
                break;

            case 1:
                getArchivedCampaigns()
                break;

            default:
                break;
        }
        setTabValue(newValue);
    };

    const handleCountryFilter = (e, v) => {
        if (v === null) {
            getCampaigns()
        } else {
            filterByCountry(v.campaign_country)
        }
    }



    const filteredProducts = applyFilters(campaigns, query, filters);
    const paginatedProducts = applyPagination(filteredProducts, page, limit);
    const enableBulkOperations = selectedProducts.length > 0;
    
    return (
        <Card>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
            >
                <Tab label="Active campaigns" {...a11yProps(0)} />
                <Tab label="Archived campaigns" {...a11yProps(1)} />
            </Tabs>
            <Box p={2}>
                <Grid container spacing={0}>
                    <Grid item md={12}>
                        <TextField
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon
                                            fontSize="small"
                                            color="action"
                                        >
                                            <SearchIcon />
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleQueryChange}
                            placeholder="Search.."
                            value={query}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Box
                            mt={3}
                            display="flex"
                            alignItems="center"
                        >
                              <Autocomplete
                                id="country-select-demo"
                                sx={{ width: 300 }}
                                options={state.campaign_countries}
                                autoHighlight
                                onChange={handleCountryFilter}
                                getOptionLabel={(option : any) => option.campaign_country}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.id} value={option.campaign_country}>
                                        <ReactCountryFlag countryCode={option.campaign_country_abbreviation} svg /> <span style={{marginLeft:'5px'}}/>{option.campaign_country}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Choose a country"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                    />
                                )}
                                />
                </Box>
                    </Grid>
                </Grid>
            </Box>
            <PerfectScrollbar>
                <Box minWidth={1200}>
                    {loading ?
                        <SkeletonTable columns={4} rows={10}/>
                        :
                        <div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map(item => {
                                            return <TableCell key={item.field} id={item.field}>{item.headerName}</TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedProducts.map((campaigns) => {
                                        const isProductSelected = selectedProducts.some(id => id.id === campaigns.id);
                                        return (
                                            <TableRow
                                                hover
                                                key={campaigns.id}
                                                selected={isProductSelected}
                                                aria-checked={isProductSelected}
                                            >
                                                <TableCell>
                                                    {campaigns.title}
                                                </TableCell>
                                                <TableCell>
                                                    {campaigns.campaign_type}
                                                </TableCell>
                                                <TableCell>
                                                    {campaigns.campaign_country}
                                                </TableCell>
                                                <TableCell>
                                                    {dateFunctions.genericDate(campaigns.publish_date)}
                                                </TableCell>
                                                <TableCell>
                                                    {campaigns.collaborations.length}
                                                </TableCell>
                                                <TableCell>
                                                    <span>
                                                        <Tooltip title={'Edit campaign'}>
                                                            <IconButton onClick={() => goToCampaign(campaigns.id)} size="large">
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </span>
                                                    <span>
                                                        <Tooltip title={'Go to collaborations'}>
                                                            <IconButton onClick={() => goToCollaboration(campaigns.id)} size="large">
                                                                <UsersIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                count={filteredProducts.length}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleLimitChange}
                                page={page}
                                rowsPerPage={limit}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            />
                        </div>
                    }
                </Box>
            </PerfectScrollbar>
        </Card>
    );
};

export default Results;
