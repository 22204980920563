import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Grid, SvgIcon, Typography } from '@mui/material';
import {
    PlusCircle as PlusCircleIcon,
} from 'react-feather';
import FullscreenDialog from '../../components/dialogs/fullscreenDialog'
import InfluencerIndex from './influencersOverviewNotInCampaign/Index'
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


interface HeaderProps {
    className?: string;
    title?: string;
    campaignName?: string;
    campaignId?: any;
    getCollaborations?: any;
    user: any;
}


const Header: FC<HeaderProps> = ({ className, title, campaignName, campaignId, getCollaborations, user, ...rest }) => {
    let navigate = useNavigate();
    const buttonTitle = title === 'Influencers coupled to campaign' ? 'Add influencer to campaign' : 'new influencer'
    const headertitle = campaignName ? campaignName + '- collaborations' : 'No campaign title found'

    const actionSave = () => {
        getCollaborations();
    }


    const goBack = () => {
        navigate(`/admin/Campaigns`)
    }

    return (
        <Grid
            container
            spacing={3}
            justifyContent="space-between"
            className={clsx(className)}
            {...rest}
        >
            <Grid item>
                <Typography
                    variant="h2"
                    color="textPrimary"
                    className="font40 Capitalize"
                >
                    {headertitle}
                </Typography>
            </Grid>
            <Grid item>
                <Button variant='contained' color='primary' onClick={goBack} startIcon={<ArrowBackIcon />} style={{ margin: '0 10px 0 0' }}>
                    Go back
                </Button>
                <FullscreenDialog
                    className={''}
                    startIcon={<SvgIcon fontSize="small"><PlusCircleIcon /></SvgIcon>}
                    content={<InfluencerIndex title={`Influencers not in campaign - ${campaignName}`} campaignId={campaignId} user={user} />}
                    variant='contained'
                    color="primary"
                    buttonTitle={buttonTitle}
                    actionSave={actionSave}
                    typeButton="button"

                />

            </Grid>
        </Grid>
    );
};

Header.propTypes = {
    className: PropTypes.string
};

export default Header;
