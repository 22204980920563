import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { alpha, Chip } from '@mui/material';

interface LabelProps {
    className?: string;
    color?: 'primary' | 'secondary' | 'error' | 'warning' | 'success' | 'info';
    children?: ReactNode;
    style?: {};
}



const Label: FC<LabelProps> = ({
    className = '',
    color = 'primary',
    children,
    style,
    ...rest
}) => {

    return (
        <Chip label={children} color={color} />
    );
};

Label.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success'])
};

export default Label;
