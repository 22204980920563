import {FC, useState, useEffect,} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, Checkbox, FormControlLabel, Grid, TextField} from '@mui/material';
import { ShoppingMoneyClient } from '../../utils/axios/ShoppingMoneyClient'
import toast from 'react-hot-toast';

interface ResultsProps {
    title?: string;
    text?: string;
    className?: string;
    buttonTitle?: string;
    variant?: any;
    color?: any;
    actionYes?: any;
    influencers:any;
}

const AddInfluencerToCollab: FC<ResultsProps> = ({ title, className, buttonTitle, color, variant, actionYes, influencers }) => {
    const [open, setOpen] = useState(false);
    const [shoppingMoneyList, setShoppingMoneyList] = useState([])
    const [selectedShoppingMoney, setSelectedShoppingMoney] = useState('Kies een bedrag');
    const shoppingMoneyClient = new ShoppingMoneyClient()
    const [addDiscountCode, setAddDiscountCode] = useState<boolean>(false)
    const discountCodes = [];

    useEffect(() => {
        try {
            shoppingMoneyClient.getShoppingMoneyList()
            .then(res => {
                setShoppingMoneyList(res.data.RESULTS)
            })
        } catch (err) {
            console.error(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event) => {
        setSelectedShoppingMoney(event.target.value)
    }

    const checkboxAddDiscountChange = () => {
        setAddDiscountCode(!addDiscountCode)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDiscountChange = (event) => {
        discountCodes.push([event.target.value])
    }


    return (
        <span>
            <Button
                variant={variant}
                color={color}
                className={className}
                onClick={handleClickOpen}
            >
                {buttonTitle}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container spacing={1}>
                            <Grid className="language" item md={12}>
                            You are on the verge of adding {influencers.map(item => {return (item.name)})} to the campaign
                            </Grid>
                            <Grid item md={12}>
                                <div className="language">Choose an amount of shopping money for these influencer(s).</div>
                                <TextField
                                    fullWidth
                                    name="option"
                                    onChange={handleChange}
                                    select
                                    value={selectedShoppingMoney}
                                    SelectProps={{ native: true }}
                                    variant="outlined"
                                    id='shopMoneySelect'
                                >
                                    <option disabled={selectedShoppingMoney === 'Choose amount' ? true : false} key={'Choose amount'} value={'Choose amount'}>Choose amount</option>
                                    {shoppingMoneyList.map((option) => (
                                        <option
                                            key={option[0]}
                                            value={option[0]}
                                        >
                                            {option[0]}
                                        </option>
                                    ))}
                                </TextField>
                                <Box className="language" my={2}>
                                    <FormControlLabel
                                        control={<Checkbox onChange={checkboxAddDiscountChange} checked={addDiscountCode} name="addDiscountCodes" />}
                                        label="I want to add a discount codes for these influencer(s)"
                                    />
                                </Box>
                                
                                {addDiscountCode &&
                                    <div>
                                        {influencers.map(item =>{
                                            return (
                                                <div key={item.id}>
                                                    <div>
                                                        Add a discount code for {item.name}
                                                    </div>
                                                    <TextField
                                                        className="language"
                                                    fullWidth
                                                    name="discount_code"
                                                    onBlur={handleDiscountChange}
                                                    variant="outlined"
                                                    id={`discount_code${item.id}`}
                                                    
                                                    >

                                                    </TextField>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                                
                                
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                    disabled={selectedShoppingMoney === 'Choose amount'? true : false}
                    onClick={() => {
                        if (addDiscountCode){
                            if (discountCodes.length === 0){
                                toast.error("'You forgot to add a discount code(s)!")
                                return;
                            }
                        }
                        if (selectedShoppingMoney !== 'Choose amount'){
                            handleClose();
                            actionYes(selectedShoppingMoney,discountCodes);
                        } else {
                            toast.error("You forgot to add shoppingmoney!")
                        }

                    }}
                    className="language"
                        color="primary">
                        Yes
                    </Button>
                    <Button className="language" onClick={handleClose} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </span>

    );
}

export default AddInfluencerToCollab;