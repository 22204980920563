import { socials } from './../../types/influencer';
export class Utilities {
    public createInfluencerCreatePayload(values) {
        const influencer = {
                'name': values.fullName,
                'email': values.email,
                'contact_person': 'Niet toegekend',
                'birth_date': values.birthdate,
                'status': 1,
                'motivation': values.whyworkwithus,
                'is_deleted':false,
                'influencer_type': values.instagram ? 'Instagram' : values.tiktok ? 'TikTok' : values.youtube ? 'Youtube' : 'Anders/Of Meerdere',
                'language': values.language,
        };

   
        const address = {
                'street': values.street,
                'house_number_and_addition': values.streetnumber,
                'postal_code': values.zipcode,
                'city': values.city,
                'region': null,
                'country_iso_code': values.country
        };
    
        const influencerDataPayload = {
            influencer,
            address,
            "socials": this.createInfluencerCreateSocialsData(values),
            "tags": []
        };
        
        return influencerDataPayload;
    }

    public createUpdatedInfluencerPayload(values) {
        const influencer = {
            'name': values.name,
            'email': values.email,
            'contact_person': values.contact_person,
            'birth_date': values.birthdate,
            'influencer_type': values.influencer_type,
            'language': values.language,
            'tags': values.tags
        };
        const address = values.address ? {
                'id': values.address.id,
                'street': values.address.street,
                'house_number_and_addition': values.address.house_number_and_addition,
                'postal_code': values.address.postal_code,
                'city': values.address.city,
                'region': values.address.region,
                'country_iso_code': values.address.country
        } : {
                'street': '',
                'house_number_and_addition': '',
                'postal_code': '',
                'city': '',
                'region': '',
                'country_iso_code': ''
        };

        const socials = []
        const tags = []

        // eslint-disable-next-line array-callback-return
        values.tags.map(item => {
            tags.push({
                "id": item.id ? item.id : null,
                "tag": item.tag ? item.tag : item,
                "creation_date":item.creation_date ? item.creation_date : null,
                "modified_date":item.modified_date ? item.modified_date : null,
                "tagger":item.tagger ? item.tagger : null
            })
        })
        
        // eslint-disable-next-line array-callback-return
        values.socials.map(item =>{
            socials.push({
                "id": item.id,
                "account_id": item.account_id,
                "account_url": item.account_url,
                "followers": item.followers,
            })
        })

        const influencerDataPayload = {
            influencer,
            address,
            "socials": socials,
            "tags": tags
        };
    
    return influencerDataPayload;
    }

    public createInfluencerCreateSocialsData(values){
        let insta = values.instagram;
        let tiktok = values.tiktok;
        let youtube = values.youtube;

        let influencerSocialsData = [];

        if (insta) {
            let instaObject = {
                'account_id': insta,
                'account_url': `https://www.instagram.com/${insta}`,
                'followers': values.instagramFollowers
            }
            influencerSocialsData.push(instaObject);
        }
        if (tiktok) {
            let tikTokObject = {
                'account_id': tiktok,
                'account_url': `https://www.tiktok.com/@${tiktok}`,
                'followers': values.tiktokFollowers
            }
            influencerSocialsData.push(tikTokObject);
        }
        if (youtube) {
            let youtubeObject = {
                'account_id': youtube,
                'account_url': `https://www.youtube.com/channel/${youtube}`,
                'followers': values.youtubeFollowers
            }
            influencerSocialsData.push(youtubeObject);
        }

        return influencerSocialsData;
    }

    public createGetInfluencerNotInCampaignPayload(id, amount, search_term, page){
        const influencerNotInCampaignPayload = {
                "campaign_id" : id,
                "amount": amount,
                "search_term": search_term === '' ? 'null' : search_term,
                "page":page
        };

        return influencerNotInCampaignPayload;
    }

    public createInfluencerPaginationPayload(status, limit, term, page, checkDate, tags, country){
        const influencerPaginationPayload = {
            "status": status,
            "limit": limit,
            "term":term === '' ? 'null' : term,
            "page":page,
            "check_date":checkDate,
            "tags": tags,
            "country": country
        }

        return influencerPaginationPayload;
    }

    public createInfluencerNotInCampaignPaginationPayload(campaignid, limit, term, page,tags, checkDate?){
        const influencerNotInCampaignPaginationPayload = {
            "campaign_id":campaignid,
            "limit": limit,
            "term":term === '' ? 'null' : term,
            "page":page,
            "tags": tags,
            "check_date":checkDate,            
        }

        return influencerNotInCampaignPaginationPayload;
    }

    public createRenewShoppingMoneyVoucherPayload(collaboration){
        const renewShoppingMoneyVoucherPayload = []
        collaboration.map((item) => (
            renewShoppingMoneyVoucherPayload.push(item.id)
        ))

        return renewShoppingMoneyVoucherPayload;
    }
}

