import NextButton from "../FormComponents/Button";
import TextInput from "../FormComponents/textInput";
import AdornmentInput from "../FormComponents/adornmentTextInput";
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid } from "@mui/material";

const InfluencerRegistrationFormPartTwo = ({ values, touched, errors, handleBlur, handleChange, showFirstForm, showSecondForm, showThirdForm, showSubmitButton, isAlertVisible }) => {

    const { t } = useTranslation();


    const checkifFollowers = () => {
        let valid = false
        let socials = []
        if (values.instagram){
            if (values.instagramFollowers){
                valid = true
                socials.push(values.instagramFollowers)
            } else {
                valid = false
            } 
        }
        if (values.tiktok){
            if (values.tiktokFollowers){
                valid = true
                socials.push(values.tiktokFollowers)
            } else {
                valid = false
            } 
        }
        if (values.youtube){
            if (values.youtubeFollowers){
                valid = true
                socials.push(values.youtubeFollowers)
            } else {
                valid = false
            } 
        }

        socials.forEach(item => {
            if (item < 1){
                valid = false
            }
        })

        return valid

    }

    const checkIfValid = () => {
        if (values.whyworkwithus !== '') {
            if (values.instagram || values.tiktok || values.youtube ) {
                if (checkifFollowers()){
                    showSecondForm(false)
                    showSubmitButton(true)
                    showThirdForm(true)
                    return
                } else {
                    isAlertVisible(true)
                    return
                }
            } else {
                isAlertVisible(true)
                return
            }
        }
    }

    const goBackOnePage = () => {
        showSecondForm(false);
        showFirstForm(true);
    }

    return (
        <div>
            <TextInput
                error={Boolean(touched.whyworkwithus && errors.whyworkwithus)}
                fullWidth
                helperText={touched.whyworkwithus && errors.whyworkwithus}
                label={t('whyworkwithus')}
                name="whyworkwithus"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.whyworkwithus}
                isMultiLine={true}
                isType="text"
            />

            <div className="registerText" id='test'>{t('nextSocials')}</div>
            <Grid container spacing={1}>
                <Grid item md={9} sm={12}>
                    <AdornmentInput
                        error={Boolean(touched.instagram && errors.instagram)}
                        fullWidth
                        helperText={touched.instagram && errors.instagram}
                        label={t('instagram')}
                        name="instagram"
                        Istype='text'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.instagram}
                        isMultiLine={false}
                    />
                </Grid>
                <Grid item md={3} sm={12}>
                    <AdornmentInput
                        error={Boolean(touched.instagramFollowers && errors.instagramFollowers)}
                        fullWidth
                        helperText={touched.instagramFollowers && errors.instagramFollowers}
                        label={t('followers')}
                        name="instagramFollowers"
                        Istype='number'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.instagramFollowers}
                        isMultiLine={false}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item md={9} sm={12}>
                    <AdornmentInput
                        error={Boolean(touched.tiktok && errors.tiktok)}
                        fullWidth
                        helperText={touched.tiktok && errors.tiktok}
                        label={t('tiktok')}
                        name="tiktok"
                        Istype='text'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.tiktok}
                        isMultiLine={false}
                    />

                </Grid>
                <Grid item md={3} sm={12}>
                    <AdornmentInput
                        error={Boolean(touched.tiktokFollowers && errors.tiktokFollowers)}
                        fullWidth
                        helperText={touched.tiktokFollowers && errors.tiktokFollowers}
                        label={t('followers')}
                        name="tiktokFollowers"
                        onBlur={handleBlur}
                        Istype='number'
                        onChange={handleChange}
                        value={values.tiktokFollowers}
                        isMultiLine={false}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item md={9} sm={12}>
                    <AdornmentInput
                        error={Boolean(touched.youtube && errors.youtube)}
                        fullWidth
                        helperText={touched.youtube && errors.youtube}
                        label={t('youtube')}
                        name="youtube"
                        Istype='text'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.youtube}
                        isMultiLine={false}
                    />
                </Grid>
                <Grid item md={3} sm={12}>
                    <AdornmentInput
                        error={Boolean(touched.youtubeFollowers && errors.youtubeFollowers)}
                        fullWidth
                        helperText={touched.youtubeFollowers && errors.youtubeFollowers}
                        label={t('followers')}
                        name="youtubeFollowers"
                        onBlur={handleBlur}
                        Istype='number'
                        onChange={handleChange}
                        value={values.youtubeFollowers}
                        isMultiLine={false}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item md={6}>
                <Box mt={2}>
                    <Button
                        color="primary"
                        fullWidth
                        size="large"
                        variant="contained"
                        onClick={goBackOnePage}
                    >
                        {t('goBack')}
                    </Button>
                </Box>
                </Grid>
                <Grid item md={6}>
                <NextButton
                    setcolor="primary"
                    isDisabled={false}
                    isFullwidth={true}
                    setSize={"large"}
                    setVariant={"contained"}
                    textContent={t('continue')}
                    action={checkIfValid}
                />
                </Grid>
            </Grid>


        </div>
    )
}

export default InfluencerRegistrationFormPartTwo


