export type InfluencerType =
    | 'Model'
    | 'DJ'
    | 'TV';

export type InfluencerStatus =
    | '0'
    | 'accepted'
    | 'pending'
    | 'rejected';

export const InfluencerBackendStatus = {
    WAITINGFORREVIEW: 1,
    ACCEPTED: 2,
    REJECTED: 3,
    IGNORED: 4,
    DELETED: 5
}

export interface Influencer {
    last_collaboration_date: Date;
    id: string;
    status: number;
    adressCity: string;
    adressCountryIsoCode: string;
    adressHouseNumber: string;
    adressPostalCode: string;
    adressRegion: string;
    adressStreet: string;
    address: address;
    contact_person: string;
    email: string;
    influencer_type: string;
    name: string;
    notes: string;
    language: any;
    last_comment: string;
    update_date: any;
    campaign_title: string;
    birth_date: string;
    socials: socials[];
    tags: any[]
}

export interface address {
    city: string;
    country_iso_code: string;
    house_number_and_addition: string;
    postal_code: string;
    region: string;
    street: string;
}

export interface socials {
    id: number;
    account_id: string;
    account_url: string;
    followers: number;
}

export interface Registration {
    name: string;
    email: string;
    language: string;
    id: number;
    address: any;
    socials: any;
    contact_person: string;
    influencer_type: string;
    birth_date: string;
    motivation: string;
}