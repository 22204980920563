import React, { FC, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CollaborationClient } from '../../utils/axios/CollaborationClient';
import { Alert, FormControl, MenuItem, Select, Tooltip } from '@mui/material';
import { collabData } from '../../types/collabData';
import Snackbar from '@mui/material/Snackbar';
import toast, { Toaster } from 'react-hot-toast';


interface ResultsProps {
    title?: any;
    className?: string;
    variant?: any;
    color?: any;
    actionYes?: any;
    data: collabData;
    buttonContent? : any;
    setLoadingTable: any;
    showDeleted: boolean;
}

const StatusChangeDialog: FC<ResultsProps> = ({ title, color, variant, actionYes, data, buttonContent, showDeleted, setLoadingTable}) => {
    const [open, setOpen] = useState(false);
    const [collabStatus, setCollabStatus] = useState(0);
    const collaborationClient = new CollaborationClient();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdate = () => {
        setLoadingTable(true)
        collaborationClient.updateStatus(data)
        .then(res => {
            setLoadingTable(false)
            if(res.status === 200){
                showDeleted ? actionYes('8') : actionYes();
                toast.success(`Status of ${data.influencer.name} changed to ${setStatusLabel(data.status)}`)
                setLoadingTable(false)
            } else {
                toast.error(`Something went wrong, try again or contact development`)
                setLoadingTable(false)
            }
        })        
    }

    const handleChange = (e) => {
        e.preventDefault();
        setCollabStatus(e.target.value)
        data.status = e.target.value
    }

    const handleYes = () => {
        handleClose();
        handleUpdate();
    }

    const collaborationStatusList = [
        {status: 1, label: 'Invited'},
        {status: 2, label: 'Shopping Money reminder sent'},        
        {status: 3, label: 'Shopping Money used'},
        {status: 4, label: 'Swipe up link sent'},
        {status: 5, label: 'Reminder to post sent'},
        {status: 6, label: 'Post rejected'},
        {status: 7, label: 'Has posted'},
        {status: 8, label: 'Deleted'},
        {status: 9, label: 'Completed'},
    ]

    const setStatusLabel = (status) => {
        let label = 'de nieuwe status';
        collaborationStatusList.forEach(item => {
            if (item.status === status) {
                label = item.label
            }
        })
        return label
    }

    useEffect(() => {
        collaborationStatusList.forEach((item) => {
            if (item.status === data.status){
                setCollabStatus(item.status);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <span>
            <Tooltip title={'Wijzig de status'}>
                <span>
                <Button
                    color={color}
                    onClick={handleClickOpen}
                >
                    {buttonContent}
                </Button>
                </span>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                
            >
                <DialogTitle id="alert-dialog-title" style={{fontSize:'16px', fontWeight:'bold'}}>Wijzig de status voor {data.influencer.name}</DialogTitle>
                <DialogContent style={{minWidth:'400px'}}>
                    <DialogContentText id="alert-dialog-description">
                    <FormControl style={{width:'100%'}}>
                        <Select
                        id="changeStatus"
                        variant='outlined'
                        value={collabStatus}
                        style={{width:'100%'}}
                        onChange={(e) => handleChange(e)}
                        >
                            {collaborationStatusList.map((item) => {
                                return (
                                    <MenuItem key={item.status} value={item.status}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleYes} color="primary">
                        Ja
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Nee
                    </Button>
                </DialogActions>
            </Dialog>
        </span>
    );
}

export default StatusChangeDialog;