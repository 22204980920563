import React, {
    useEffect, useState,
} from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import VoucherOverview from './voucherOverview';
import VouchersAdd from './vouchersAdd';
import { ShoppingMoneyClient } from '../../../utils/axios/ShoppingMoneyClient'
import Page from '../../../components/page/Page';
import { Container, Paper, Typography } from '@mui/material';
import VouchersRedeemed from './vouchersRedeemed';
import VoucherHeader from '../voucherHeader';


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <Page
            className={classes.root}
            title="Vouchers"
        >
            <Container maxWidth={false}>
                <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`scrollable-force-tabpanel-${index}`}
                    aria-labelledby={`scrollable-force-tab-${index}`}
                    {...other}
                >
                    {value === index && (
                        <Box p={3}>
                            <div>{children}</div>
                        </Box>
                    )}
                </div>
            </Container>
        </Page>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',

    },
}));

export default function Vouchers() {
    const classes = useStyles();
    const shoppingMoneyClient = new ShoppingMoneyClient();
    const [value, setValue] = useState(0);
    const [vouchers, setVouchers] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        try {
            shoppingMoneyClient.getVoucherInfo()
                .then(res => {
                    setVouchers(res.data.RESULTS);
                    setLoading(false)
                })
        } catch (err) {
            console.error(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Paper>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="scrollable force tabs example"
                >
                    <Tab label="Vouchers overview" {...a11yProps(0)} />
                    <Tab label="Add vouchers" {...a11yProps(1)} />
                    <Tab label="Used vouchers" {...a11yProps(2)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <VoucherOverview vouchers={vouchers} loading={loading} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <VouchersAdd vouchers={vouchers} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <VouchersRedeemed />
                </TabPanel>
            </Paper>
        </div>
    );
}