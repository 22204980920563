import axios from 'axios'
import { callMsGraph } from '../../authentication/msGraphApiCall';
import { EnvironmentVariablesProvider } from '../../environment/EnvironmentVariablesProvider';

export class BackendClient {
    protected headers: any;
    protected apiurl: string;
    private environmentVariablesProvider: EnvironmentVariablesProvider;
    private accessToken: string;    

    constructor(){
        this.environmentVariablesProvider = new EnvironmentVariablesProvider();
        this.apiurl = this.environmentVariablesProvider.getVariable('api_url');
    }

    protected async makeRequest(method: string, url: string, payload: any = null, customHeaders: any = null): Promise<any> {

        let requestHeaders = { "Authorization": 'Bearer ' + window.sessionStorage.getItem('token'),
        "Content-Type": "application/json"};
        if (customHeaders)
        requestHeaders = { ...requestHeaders, ...customHeaders }

        const axiosRequest:any = {method: method, url: `${this.apiurl}/${url}`, headers: requestHeaders};
            if (payload) {
                axiosRequest["data"] = payload;
            }

        return await axios(axiosRequest)
    }
}

export default BackendClient;