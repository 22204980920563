import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminView from './views/login/login'
import InfluencerRegistration from './views/InfluencerRegistration/InfluencerRegistration'

import './assets/styling/Sass/App.scss'
import RequestInterceptor from './utils/axios/interceptors/RequestInterceptor';

export default function App() {  
  return (
        <BrowserRouter>
          <Routes>
            <Route path='/*' element={<InfluencerRegistration />} />
            <Route path='/admin/*' element={<AdminView />} />           
          </Routes>
        </BrowserRouter>
  ); 
}