import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, useMediaQuery, useTheme, Select, MenuItem, Box, Typography, TextField, CircularProgress, Checkbox } from "@mui/material";
import { useState } from "react";
import toast from "react-hot-toast";
import { Influencer, InfluencerBackendStatus } from "../../../types/influencer";
import { InfluencerClient } from "../../../utils/axios/InfluencerClient";

interface Props {
    influencer: Influencer;
    onRefetch: () => void;
}

export default function AddOrDeleteInfluencer({ influencer, onRefetch }: Props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const [addComment, setAddComment] = useState(true);
    const [comment, setComment] = useState("");
    const [requestInProgress, setRequestInProgress] = useState(false);

    const handleSetAddComment = () => setAddComment(!addComment);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const influencerClient = new InfluencerClient();

    const successHandler = (isAdd: boolean) => {
        onRefetch();
        toast.success(influencer.name + ` successfully ${isAdd ? 'added' : 'removed'}.`)
        setRequestInProgress(false);
        handleClose();
    }

    const errorHandler = (res) => {
        toast.error("Something went wrong with " + influencer.name + " contact development.")
        setRequestInProgress(false);
    }

    const handleAdd = () => {
        setRequestInProgress(true);
        influencerClient
            .undoDeleteInfluencer(influencer.id)
            .then(_ => successHandler(true))
            .catch(errorHandler);
    }

    const handleRemove = () => {
        setRequestInProgress(true);
        influencerClient
            .deleteInfluencer(influencer.id, comment)
            .then(_ => successHandler(false))
            .catch(errorHandler);

    }

    const dialogTitle = (influencer: Influencer): string =>  {
        let name = influencer ? influencer.name : '';
        let isAdd = influencer && influencer.status === InfluencerBackendStatus.DELETED;
        if (isAdd) return `Add ${name} to`;
        else return `Remove ${name}`;
    }

    return (
        <div>
            <div onClick={handleOpen}>
                {influencer && influencer.status === InfluencerBackendStatus.DELETED ?
                    <Typography>Add Influencer </Typography> :
                    <Typography>Remove Influencer</Typography>
                }
            </div>

            <Dialog
                maxWidth={false}
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">{dialogTitle(influencer)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {influencer && influencer.status === InfluencerBackendStatus.DELETED ?
                            <div>Add {influencer ? influencer.name : ''} again?</div>
                            :
                            <div>Do you want to add a comment? <Checkbox checked={addComment} onChange={handleSetAddComment} /></div>
                        }
                    </DialogContentText>
                    {addComment && influencer && influencer.status === InfluencerBackendStatus.ACCEPTED ?
                        <TextField autoFocus value={comment} variant='outlined' onChange={e => setComment(e.target.value)} fullWidth multiline />
                        :
                        <div></div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    {influencer && influencer.status === InfluencerBackendStatus.DELETED ?
                        <Button disabled={requestInProgress}
                            onClick={handleAdd}
                            variant='contained'
                            color="primary" autoFocus>
                            Add
                        </Button>
                        :
                        <Button disabled={(addComment && comment.length === 0) || requestInProgress}
                            onClick={handleRemove}
                            variant='contained'
                            color="primary" autoFocus>
                            Remove
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}