import React, { useState } from 'react';

import type {
    FC,
    ChangeEvent
} from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Grid,
    Select,
    MenuItem,
    IconButton,
    Checkbox,
    Container,
    Button,
    CircularProgress,
    Backdrop,
    ButtonGroup,
} from '@mui/material';

import { makeStyles } from "@mui/styles";

import {
    ArrowRight as NextIcon,
    ArrowLeft as PreviousIcon,
    Send as SendIcon,
    Delete as DeleteIcon,
} from 'react-feather';

import type { MailJob } from '../../types/mailJob';
import DateFunctions from '../../utils/dates/dateClass';
import { ConfirmProvider, useConfirm } from 'material-ui-confirm';
import { MailJobClient } from '../../utils/axios/MailJobClient';
import { MailJobTypeMapping } from '../../models/MailJobType';
import toast from 'react-hot-toast';


interface ResultsProps {
    className?: string;
    mailJobs: MailJob[];
    nextPage: number;
    numberOfPages: number;
    previousPage: any;
    currentPage: number;
    limit: number;
    loading: boolean;
    setLoading: any;
    isBusy: boolean;
    selection: number[];
    setSelection: (selection: React.SetStateAction<number[]>) => void;
    setIsBusy: (value: boolean) => void;
    search: (value: string) => void;
    setPage: (value: number) => void;
    setLimit: (value: number) => void;
    refetch: () => void;
}

const dateFunctions = new DateFunctions();

const useStyles = makeStyles((theme: any) => ({
    root: {},
    bulkOperations: {
        position: 'relative'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        background: "rgba(0,0,0,0.8)",
        fontSize: "18px",
    },
    bulkActions: {
        paddingLeft: 4,
        paddingRight: 4,
        marginTop: 6,
        position: 'absolute',
        width: '100%',
        zIndex: 2,
        backgroundColor: theme.palette.background.default
    },
    bulkAction: {
        marginLeft: theme.spacing(2)
    },
    queryField: {
        width: 500
    },
    categoryField: {
        flexBasis: 200
    },
    availabilityField: {
        marginLeft: theme.spacing(2),
        flexBasis: 200
    },
    stockField: {
        marginLeft: theme.spacing(2)
    },
    shippableField: {
        marginLeft: theme.spacing(2)
    },
    imageCell: {
        fontSize: 0,
        width: 68,
        flexBasis: 68,
        flexGrow: 0,
        flexShrink: 0
    },
    image: {
        height: 68,
        width: 68
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'center'
    },
    flexStart: {
        justifyContent: 'flex-start'
    },

    flexEnd: {
        justifyContent: 'flex-end'
    },
}));

const Results: FC<ResultsProps> = ({ className, mailJobs, numberOfPages, nextPage, previousPage, currentPage, limit, loading, setLoading, isBusy, selection, setSelection, setIsBusy, search, setLimit, setPage, refetch, ...rest }) => {
    const classes = useStyles();
    const confirm = useConfirm();

    const client = new MailJobClient();

    const isSelected = (id: number) => selection.indexOf(id) >= 0;

    const handleSelect = (event: ChangeEvent<HTMLInputElement>, jobId: any): void => {
        if (!selection.some(id => id === jobId)) {
            setSelection(prevSelected => [...prevSelected, jobId]);
        } else {
            setSelection(prevSelected => prevSelected.filter(_id => _id !== jobId));
        }
    };

    const handleSelectall = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelection(event.target.checked ? mailJobs.map(n => n.id) : []);
    }

    const showConfirm = (title: string, description: string) => {
        return confirm({
            title: title,
            description: description,
            cancellationButtonProps: { color: 'primary' },
            confirmationButtonProps: { color: 'primary' }
        });
    }

    const handleProcessMail = (event: React.MouseEvent<HTMLButtonElement>, job: MailJob) => {
        showConfirm("Send email", "Are you sure you want to send these email?")
            .then(_ => processMail(job))
            .catch(_ => { });
    };

    const handleProcessMails = () => {
        showConfirm("Send emails", "Are you sure you want to send these emails?")
            .then(_ => processMails())
            .catch(_ => { });
    };

    const handleDeleteMail = (event: React.MouseEvent<HTMLButtonElement>, job: MailJob) => {
        showConfirm("Delete email", "Are you sure you want to delete these email?")
            .then(_ => deleteMail(job))
            .catch(_ => { });
    };

    const handleDeleteMails = () => {
        showConfirm("Delete email", "Are you sure you want to delete these emails?")
            .then(_ => deleteMails())
            .catch(_ => { });
    };

    const processMail = (job: MailJob) => {
        setIsBusy(true);

        client.processMailJob(job.id)
            .then(res => {
                if (res.status === 200) {
                    toast.success('Mail sent')
                    refetch(); // Refetch manually to force up to date listing
                }

                else showGenericErrorMessage();

                setIsBusy(false);
            })
            .catch(catchPromise);
    }

    const processMails = () => {
        setIsBusy(true);

        const selectionToProcess = selection;
        client.processMailJobs(selection)
            .then(res => {
                if (res.status === 200) {
                    toast.success("Mails sent")
                    setSelection(selection.filter(id => selectionToProcess.indexOf(id) === -1));

                    refetch(); // Refetch manually to force up to date listing
                }

                else showGenericErrorMessage();

                setIsBusy(false);
            })
            .catch(catchPromise);
    }

    const deleteMail = (job: MailJob) => {
        setIsBusy(true);

        client.deleteJob(job.id)
            .then(res => {
                if (res.status === 200) {
                    toast.success('Mail deleted')

                    refetch(); // Refetch manually to force up to date listing
                }

                else showGenericErrorMessage();

                setIsBusy(false);
            })
            .catch(catchPromise);
    }

    const deleteMails = () => {
        setIsBusy(true);

        const selectionToProcess = selection;
        client.deleteJobs(selection)
            .then(res => {
                if (res.status === 200) {
                    toast.success('Mails deleted')
                    setSelection(selection.filter(id => selectionToProcess.indexOf(id) === -1));

                    refetch(); // Refetch manually to force up to date listing
                }

                else showGenericErrorMessage();

                setIsBusy(false);
            })
            .catch(catchPromise);
    }

    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value))
    };

    const handlePreviousPage = () => {
        setPage(previousPage)
    }

    const handleNextPage = () => {
        setPage(nextPage)
    }

    const catchPromise = _ => {
        showGenericErrorMessage();
        setIsBusy(false);
    };

    const showGenericErrorMessage = () => {
        toast.error("Something went wrong, try again or contact Development")
    };

    return (
        <div>
            <Backdrop className={classes.backdrop} open={isBusy} style={{ display: "flex", flexDirection: "column" }}>
                <CircularProgress color="primary" />
                Processing request
            </Backdrop>
            <ConfirmProvider>
                <PerfectScrollbar>
                    <Box minWidth={1200} style={{ backgroundColor: 'white' }}>
                        <Box mt={1} >
                            <Container maxWidth={false} style={{ visibility: selection.length > 0 ? 'visible' : 'hidden' }}>
                                <ButtonGroup aria-label="outlined button group">
                                    <Button
                                        disabled={isBusy}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleProcessMails()}>
                                        Sending selection
                                    </Button>
                                    <Button
                                        disabled={isBusy}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleDeleteMails()}>
                                        Deleting selection
                                    </Button>
                                </ButtonGroup>
                            </Container>
                        </Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            indeterminate={selection.length > 0 && selection.length < mailJobs.length}
                                            checked={selection.length > 0 && selection.length === mailJobs.length}
                                            onChange={handleSelectall}
                                            inputProps={{
                                                'aria-label': 'Select all mails',
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        Campaign
                                    </TableCell>
                                    <TableCell>
                                        Type
                                    </TableCell>
                                    <TableCell>
                                        Sender
                                    </TableCell>
                                    <TableCell>
                                        Receiver
                                    </TableCell>
                                    <TableCell>
                                        Date created
                                    </TableCell>
                                    <TableCell>
                                        Date send
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mailJobs.map((job) => {
                                    return (
                                        <TableRow hover key={job.id} selected={isSelected(job.id)}>
                                            <TableCell padding='checkbox'>
                                                <Checkbox
                                                    color="primary"
                                                    checked={isSelected(job.id)}
                                                    onChange={e => handleSelect(e, job.id)}
                                                    inputProps={{
                                                        'aria-labelledby': `mail-job-checkbox-${job.id}`,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {job.params.campaign_name}
                                            </TableCell>
                                            <TableCell>
                                                {job ? MailJobTypeMapping[job.type] : ''}
                                            </TableCell>
                                            <TableCell>
                                                {job.sender_email}
                                            </TableCell>
                                            <TableCell>
                                                {job.receiver_email}
                                            </TableCell>
                                            <TableCell>
                                                {dateFunctions.genericDateTime(job.creation_date)}
                                            </TableCell>
                                            <TableCell>
                                                {job.completion_date ? dateFunctions.genericDateTime(job.completion_date) : 'In the queue'}
                                            </TableCell>
                                            <TableCell>
                                                {job.is_processed ?
                                                    ''
                                                    :
                                                    <IconButton
                                                        disabled={isBusy}
                                                        aria-controls="options-menu"
                                                        aria-haspopup="true"
                                                        onClick={(e) => handleProcessMail(e, job)}
                                                        size="large">
                                                        <SendIcon />
                                                    </IconButton>
                                                }

                                                <IconButton
                                                    disabled={isBusy}
                                                    aria-controls="options-menu"
                                                    aria-haspopup="true"
                                                    onClick={(e) => handleDeleteMail(e, job)}
                                                    size="large">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <div>
                            <Grid container>
                                <Grid item md={5}>

                                </Grid>
                                <Grid item md={3} className={`${classes.gridFlex} ${classes.flexEnd}`}>
                                    Rows per page:
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={limit}
                                        onChange={handleLimitChange}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={25}>25</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item md={2} className={`${classes.gridFlex} ${classes.flexEnd}`}>
                                    Pagina {currentPage} van {numberOfPages}
                                </Grid>
                                <Grid item md={2} className={`${classes.gridFlex} ${classes.flexEnd}`}>
                                    <div >
                                        <IconButton
                                            disabled={previousPage === 0}
                                            aria-label="previous"
                                            onClick={handlePreviousPage}
                                            size="large">
                                            <PreviousIcon />
                                        </IconButton>
                                        <IconButton
                                            disabled={currentPage === numberOfPages || numberOfPages <= 0}
                                            aria-label="next"
                                            onClick={handleNextPage}
                                            size="large">
                                            <NextIcon />
                                        </IconButton>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </PerfectScrollbar>
            </ConfirmProvider>
        </div>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    mailJobs: PropTypes.array.isRequired
};

Results.defaultProps = {
    mailJobs: []
};

export default Results;
