import BackendClient from "./_BackendClient";

export class SettingsClient extends BackendClient {
    constructor() {
        super();
        this.apiurl = this.apiurl + '/settings'
    }

    public get_all_campaign_types() {
        return this.makeRequest('GET', 'get_all_campaign_types')
    }

    public get_all_campaign_countries() {
        return this.makeRequest('GET', 'get_all_campaign_countries')
    }

    public create_campaign_country(payload){
        return this.makeRequest("POST", 'campaign_country', payload)
    }

    public create_campaign_type(payload){
        return this.makeRequest("POST", 'campaign_type', payload)
    }

    public delete_campaign_country(id){
        return this.makeRequest("DELETE", `campaign_country/${id}`)
    }

    public delete_campaign_type(id){
        return this.makeRequest("DELETE", `campaign_type/${id}`)
    }
}