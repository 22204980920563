import { Grid } from '@mui/material'
import InfluencerRegistrationForm from '../../components/inputs/influencerRegistrationForm'
import loginnew22 from '../../assets/images/loginnew22.jpg'

function InfluencerRegistration() {
    return (
            <Grid container spacing={1}>
                <Grid item md={3} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    <div style={{backgroundImage:`url(${loginnew22})`, height:'100vh', width:'100%', backgroundSize:'cover'}}/>
                </Grid>
                <Grid item md={9} sm={12}>
                    <InfluencerRegistrationForm/>
                </Grid>
            </Grid>
    )
}
export default InfluencerRegistration