import React, { FC, ReactElement } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
interface ResultsProps {
    title?: any;
    text?: any;
    className?: string;
    buttonTitle?: any;
    variant?: any;
    color?: any;
    actionYes?: any;
}

const AlertDialog: FC<ResultsProps> = ({ title, text, className, buttonTitle, color, variant, actionYes }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <span>
            <Button
                variant={variant}
                color={color}
                className={className}
                onClick={handleClickOpen}
            >
                {buttonTitle}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="language" onClick={() => {
                        handleClose();
                        actionYes();
                    }}
                        color="primary">
                        Yes
                    </Button>
                    <Button className="language" onClick={handleClose} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </span>

    );
}

export default AlertDialog;