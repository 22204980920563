import React, { useContext, useState } from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from "@mui/styles";
import TextField from '@mui/material/TextField';
import { Influencer } from "../../types/influencer"
import { Checkbox } from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Context } from '../../Store/store';

const filter = createFilterOptions<any>();


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 500,
      '& > * + *': {
        marginTop: theme.spacing(3),
      },
    },
  }),
);


interface props {
    influencer: Influencer
}


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function TagComponent ({influencer}: props) {
    const [value, setValue] = useState<any>(influencer.tags);
    const [state, setState] = useContext(Context)

    function handleChange(newValue: any) {
      setValue(newValue)
      influencer.tags = newValue
    }

    return (
        <>
            <Autocomplete
                multiple
                disableCloseOnSelect
                selectOnFocus
                freeSolo
                clearOnBlur
                id="tags-standard"
                options={state.tags}
                value={value}
                onChange={(event: any, newValue: any) => {
                  if (typeof newValue === 'string') {
                    handleChange(newValue)
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    handleChange(newValue.inputValue);
                  } else {
                    handleChange(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  // Suggest the creation of a new value
                  if (params.inputValue !== '') {
                    filtered.push(
                      {
                        inputValue: params.inputValue,
                        tag: params.inputValue,
                      }
                    );
                  }
          
                  return filtered;
                }}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.tag;
                }}
                defaultValue={[]}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.tag}
                  </li>
                )}
                renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Tags"
                />
                )}
            />
        </>
    )
}

export default TagComponent