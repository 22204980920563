import BackendClient from "./_BackendClient";

export class PostClient extends BackendClient {
    constructor() {
        super();
        this.apiurl = this.apiurl + '/post'
    }

    public reviewPost(postId:number, postReview:number, ratingContent:string,  sendMail:boolean) {
        return this.makeRequest('POST', 'review-post', { 'post_id': postId, 'post_review':postReview, 'reason': ratingContent, 'send_mail': sendMail });
    }
}