import { Avatar, Box, Backdrop, Fab, Grid, Paper, TextField, Tooltip, Typography, CircularProgress } from "@mui/material"
import SpeedDial, { SpeedDialProps } from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Theme } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import createStyles from '@mui/styles/createStyles';
import { useEffect, useState } from "react";
import {
    X as CancelIcon
} from 'react-feather'
import { useParams, useNavigate } from "react-router-dom";
import { Influencer } from "../../types/influencer";
import { InfluencerClient } from '../../utils/axios/InfluencerClient'
import { Utilities } from "../../utils/axios/utilities";
import TagComponent from "../../components/tags/TagComponent"

const influencerClient = new InfluencerClient();
const axiosUtils = new Utilities();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    speedDial: {
      position: 'fixed',
      bottom:'5% !important',
      right:'2% !important',
      '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
      },
    },
  }),
);



const InfluencerProfileIndex = () => {
    const classes = useStyles();
    const params = useParams()
    const navigate = useNavigate();
    const [id, setId] = useState(null)
    const [influencer, setInfluencer] = useState<Influencer>(null)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [open, setOpen] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, selector:string) => {
        switch(selector) {
            case 'personal':
                setInfluencer({...influencer, [e.currentTarget.id] : e.currentTarget.value})
            break;
            case 'address':
                const theState = {...influencer};
                theState.address[e.currentTarget.id] = e.currentTarget.value;
                setInfluencer(theState)
                
            break;
            case 'socials':
                const currentState = {...influencer};
                const account: number = e.currentTarget.id.substring(e.currentTarget.id.lastIndexOf("-")).replace('-', '') as unknown as number;

                // eslint-disable-next-line array-callback-return
                currentState.socials.map(item => {
                    if (item.id === account) {
                        if (e.currentTarget.id.split('-')[0] === 'followers'){
                            item[e.currentTarget.id.split('-')[0]] = parseInt(e.currentTarget.value)
                        } else {
                            item[e.currentTarget.id.split('-')[0]] = e.currentTarget.value
                        }
                    }
                })
                setInfluencer(currentState)

            break;
        }
        
    }

    const cancelEdit = () => {
        navigate('/admin/influencers')
    }

    const handleSave =() => {
        setIsSaving(true);
        const payload = axiosUtils.createUpdatedInfluencerPayload(influencer)
        influencerClient.updateInfluencer(influencer.id, payload)
        .then(res => {
            if (res.status === 200) {
                setIsSaving(false)
                cancelEdit()
            } else {
                setIsSaving(false)
            }
        })
        .catch(err => setIsSaving(false))
    }

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleOpen = () => {
    setOpen(true);
    };

    const actions = [
        { icon: <CancelIcon />, name: 'Cancel', func: cancelEdit },
        { icon: <SaveIcon />, name: 'Save', func: handleSave },
    ];

    useEffect(() => {
        setIsLoading(true)
        if (params.id !== '0') {
            setId(params.id as unknown as number)
            influencerClient.getInfluencerById(params.id)
            .then(res => {
                const results = res.data.RESULTS.influencer

                if (results.address == null){
                    results.address = {
                        'street': '',
                        'house_number_and_addition': '',
                        'postal_code': '',
                        'city': '',
                        'region': '',
                        'country_iso_code': ''
                    }
                }
                setInfluencer(results);
                setIsLoading(false)
            })    
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
         <Backdrop
            className='backdropIMT'
            open={isSaving || isLoading}
            style={{ display: "flex", flexDirection: "column" }}
        >
            <CircularProgress color="primary" />
            {isSaving && (
                <>
                    Saving...
                </>
            )}
            {isLoading && (
                <>
                    Fetching influencers...
                </>
            )}
        </Backdrop>
            {influencer && (
                <div style={{width:'90%'}}>         
                    <Typography
                            variant="h2"
                            color="textPrimary"
                            className="font40"
                        >
                            Influencer Profile: {influencer.name}
                    </Typography>
                    <Box mt={2}>
                        <Paper style={{padding:'20px'}}>
                            <Grid container spacing={4}>
                                <Grid item md={5}>
                                    <Box mb={2}>
                                        <Typography variant="h6" color="textPrimary">Data</Typography>
                                    </Box>

                                    <Box mb={2}>
                                        <TextField value={influencer.name ? influencer.name : ''} variant='outlined' onChange={(e) => handleChange(e, 'personal')} label='Name' id='name' fullWidth/>
                                    </Box>

                                    <Box mb={2}>
                                        <TextField value={influencer.email ? influencer.email : ''} variant='outlined' onChange={(e) => handleChange(e, 'personal')}  label='Email' id='email' fullWidth/>
                                    </Box>

                                    <Box mb={2}>
                                        <TextField value={influencer.influencer_type ? influencer.influencer_type : ''} variant='outlined' onChange={(e) => handleChange(e,'personal')}  label='Type' id='influencer_type' fullWidth/>
                                    </Box>

                                    <Box mb={2}>
                                        <TextField value={influencer.contact_person ? influencer.contact_person : ''} variant='outlined' onChange={(e) => handleChange(e,'personal')}  label='Contact Person' id='contact_person' fullWidth/>
                                    </Box>

                                    <Box mb={2}>
                                        <TextField value={influencer.birth_date ? influencer.birth_date : ''} variant='outlined' onChange={(e) => handleChange(e,'personal')}  label='Birthdate' id='birth_date' fullWidth/>
                                    </Box>

                                    <Box mb={2}>
                                        <TextField value={influencer.language ? influencer.language : ''} variant='outlined' onChange={(e) => handleChange(e,'personal')}  label='Language' id='language' fullWidth/>
                                    </Box>




                                </Grid>
                                <Grid item md={7}>
                                    <Box mb={2}>
                                        <Typography variant="h6" color="textPrimary">Comments</Typography>
                                    </Box>
                                    <Box mb={2}>
                                        {influencer.last_comment ? (
                                            <div className='profileComment'>
                                                <pre>{influencer.last_comment}</pre>
                                            </div>
                                        )
                                        :
                                        (
                                            <Paper className='profileComment'>
                                                No comments
                                            </Paper>
                                        )
                                        }
                                        
                                        
                                        
                                    </Box>
                                    <Box mb={2}>
                                        <TagComponent influencer={influencer}/>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>

                    <Box mt={2}>
                        <Paper style={{padding:'20px'}}>
                            <Grid container spacing={4}>
                                <Grid item md={12}>
                                    <Box mb={2}>
                                        <Typography variant="h6" color="textPrimary">Socials</Typography>
                                    </Box>
                                    

                                    {influencer.socials.map(item => (
                                        <Box mb={2}>
                                            <TextField value={item.account_id} variant='outlined' label='Account name' id={`account_id-${item.id}`} style={{marginRight:'10px'}} onChange={(e) => handleChange(e,'socials')}  />
                                            <TextField value={item.account_url} variant='outlined' label='Account url' id={`account_url-${item.id}`} style={{marginRight:'10px', minWidth:'550px'}} onChange={(e) => handleChange(e,'socials')}  />
                                            <TextField value={item.followers} variant='outlined' label='Followers' id={`followers-${item.id}`} onChange={(e) => handleChange(e,'socials')}  />
                                        </Box>
                                    ))}
                                    
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>

                    <Box mt={2}>
                        <Paper style={{padding:'20px'}}>
                            <Grid container spacing={4}>
                                <Grid item md={12}>
                                    <Box mb={2}>
                                        <Typography variant="h6" color="textPrimary">Address</Typography>
                                    </Box>

                                    <Box mb={2}>
                                        <TextField value={influencer.address.street} variant='outlined' label='Street name' onChange={(e) => handleChange(e,'address')}  id='street' fullWidth/>
                                    </Box>

                                    <Box mb={2}>
                                        <TextField value={influencer.address.house_number_and_addition} variant='outlined' onChange={(e) => handleChange(e,'address')}  label='Number' id='house_number_and_addition' fullWidth/>
                                    </Box>

                                    <Box mb={2}>
                                        <TextField value={influencer.address.postal_code} variant='outlined' label='Postal code' onChange={(e) => handleChange(e,'address')}  id='postal_code' fullWidth/>
                                    </Box>

                                    <Box mb={2}>
                                        <TextField value={influencer.address.city} variant='outlined' label='City' id='city' onChange={(e) => handleChange(e,'address')}  fullWidth/>
                                    </Box>

                                    <Box mb={2}>
                                        <TextField value={influencer.address.region} variant='outlined' label='Province' onChange={(e) => handleChange(e,'address')}  id='region' fullWidth/>
                                    </Box>


                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                    <SpeedDial
                        ariaLabel="speedDial"
                        className={classes.speedDial}
                        icon={<SpeedDialIcon />}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        open={open}
                        direction={'up'}
                        >
                        {actions.map((action) => (
                            <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={action.func}
                            />
                        ))}
                        </SpeedDial>
                </div>
            )}
        </>       
    )
}


export default InfluencerProfileIndex