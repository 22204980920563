import React, { ReactType, useCallback, useContext, useEffect, useState } from 'react';
import type {
    FC,
    ChangeEvent
} from 'react';
import clsx from 'clsx';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Checkbox,
    InputAdornment,
    Link,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Grid,
    Select,
    MenuItem,
    IconButton,
    Button,
    Menu,
    CircularProgress,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    Search as SearchIcon,
    ArrowRight as NextIcon,
    ArrowLeft as PreviousIcon,
    MessageCircle as Message,
    Edit as EditIcon,
    Settings,
    MessageCircle
} from 'react-feather';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import type { Influencer } from '../../types/influencer';
import { InfluencerBackendStatus } from '../../types/influencer';
import GenericDialog from '../../components/dialogs/genericDialog';
import InfluencerCommentsView from '../../components/modals/influencerCommentsView';
import { socialLinkToIcon } from "../../utils/socialLinkToIcon";
import SkeletonTable from '../../components/skeletonTable/skeletonTable';
import { useNavigate, useParams } from "react-router-dom";
import DateFunctions from '../../utils/dates/dateClass';
import AddToCampaign from './addToCampaign/addToCampaign';
import AddOrDeleteInfluencer from './addOrDeleteInfluencer/addOrDeleteInfluencer';
import { Context } from '../../Store/store';
import { Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface ResultsProps {
    className?: string;
    influencers: Influencer[];
    nextPage: number;
    numberOfPages: number;
    previousPage: any;
    currentPage: number;
    checkDate: boolean;
    loading:boolean;
    setLoading:any;
    search: (value:string) => void;
    setPage: (value:number) => void;
    setPageLimit: (value: number) => void;
    setNewComment: (value:boolean) => void;
    refetch: () => void;
    newComment:boolean;
    tags: any,
    setTags: any;
    filterCountry:any;
    setFilterCountry: any
}

interface Filters {
    influencerType:string;
  }

const dateFunctions = new DateFunctions();

const applyFilters = (
    influencerData: Influencer[],
    filters: Filters
) : Influencer[] => {
    return influencerData.filter((influencerData) => {
        let matches = true;
        if (filters.influencerType){
            if (filters.influencerType && influencerData.influencer_type !== filters.influencerType){
                matches = false;
            }
        }

        return matches;
    })
}

const useStyles = makeStyles((theme: any) => ({
    root: {},
    bulkOperations: {
        position: 'relative'
    },
    bulkActions: {
        paddingLeft: 4,
        paddingRight: 4,
        marginTop: 6,
        position: 'absolute',
        width: '100%',
        zIndex: 2,
        backgroundColor: theme.palette.background.default
    },
    bulkAction: {
        marginLeft: theme.spacing(2)
    },
    queryField: {
        width: 500
    },
    categoryField: {
        flexBasis: 200
    },
    availabilityField: {
        marginLeft: theme.spacing(2),
        flexBasis: 200
    },
    stockField: {
        marginLeft: theme.spacing(2)
    },
    shippableField: {
        marginLeft: theme.spacing(2)
    },
    imageCell: {
        fontSize: 0,
        width: 68,
        flexBasis: 68,
        flexGrow: 0,
        flexShrink: 0
    },
    image: {
        height: 68,
        width: 68
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'center'
    },
    flexStart: {
        justifyContent: 'flex-start'
    },

    flexEnd: {
        justifyContent: 'flex-end'
    }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Results: FC<ResultsProps> = ({ className, influencers, numberOfPages, nextPage, previousPage, currentPage, checkDate, loading, setLoading, search, setPageLimit, setPage, setNewComment, refetch, newComment, tags, setTags, filterCountry, setFilterCountry, ...rest }) => {
    const classes = useStyles();
    const influencerTypes = [];
    const [state, setState] = useContext(Context)
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [limit, setLimit] = useState<number>(25);
    const [query, setQuery] = useState<string>('');
    const [selectedInfluencer, setSelectedInfluencer] = useState(null);
    const [showCommentsDialog, setShowCommentsDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [socialAnchor, setSocialAnchor] = useState<null | HTMLElement>(null);
    const [chosenInfluencer, setChosenInfluencer] = useState<Influencer | null>(null)
    let navigate = useNavigate();

    const influencerCountries = ['NL', 'BE', 'DE', 'FR']


    const openCommentsDialog = (influencer) => {
        setSelectedInfluencer(influencer);
        setShowCommentsDialog(true);
    }

    const closeCommentsDialog = () => {
        setSelectedInfluencer(null);
        setShowCommentsDialog(false);
        setNewComment(!newComment);
    }

    const handleEditInfluencer = (influencer) => {
        navigate(`/admin/influencer-profile/${influencer.id}`)
    }

    const handleQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTimeout(() => {
            search(event.target.value)
        }, 1000);

        setQuery(event.target.value)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSocialClose = () => {
        setSocialAnchor(null)
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, influencer) => {
        setAnchorEl(event.currentTarget);
        setChosenInfluencer(influencer)
    };

    const openSocialsMenu = (event: React.MouseEvent<HTMLButtonElement>, influencer) => {
        setSocialAnchor(event.currentTarget);
        setChosenInfluencer(influencer)
    }

    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setPageLimit(parseInt(event.target.value))
        setLimit(parseInt(event.target.value))
    };

    const handlePreviousPage = () => {
        setPage(previousPage)
    }

    const handleNextPage = () => {
        setPage(nextPage)
    }

    const handleCountryFilter = (e, v) => {
        if (v === null) {
            setFilterCountry('null')
        } else {
            setFilterCountry(v)
        }
    }

    const populateInfluencerTypeFilter = (influencerData) => {
        const influencerTypesFromData = [];
        influencerData.map((item) => influencerTypesFromData.push(item.influencer_type));
        const uniqueInfluencerTypes = Array.from(new Set(influencerTypesFromData))

        uniqueInfluencerTypes.sort();
        uniqueInfluencerTypes.unshift("All");
        uniqueInfluencerTypes.forEach((item, index) => {
            influencerTypes.push({
                id:`${item}`,
                name:`${item}`
            })
        })
    
    }

    populateInfluencerTypeFilter(influencers)

    const [filters, setFilters] = useState<Filters>({
        influencerType: null,
    });

    const handleInfluencerTypeFilter = (event: ChangeEvent<HTMLInputElement>): void => {
        event.persist();

        let value = null;

        if (event.target.value !== "All") {
            value = event.target.value;
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            influencerType: value,
        }))
    }

    let filteredInfluencers = applyFilters(influencers, filters)


    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Box p={2}>
                <Box
                    display="flex"
                    alignItems="center"
                >
                    <TextField
                        className={classes.queryField}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SvgIcon
                                        fontSize="small"
                                        color="action"
                                    >
                                        <SearchIcon />
                                    </SvgIcon>
                                </InputAdornment>
                            )
                        }}
                        onChange={handleQueryChange}
                        placeholder="Search.."
                        value={query}
                        variant="outlined"
                    />
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        fullWidth
                        freeSolo
                        limitTags={3}
                        style={{maxWidth:'50%', marginLeft:'10px'}}                        
                        options={state.tags}
                        value={tags}
                        onChange={(event: any, newValue: any) => {
                           setTags(newValue);
                        }}
                        getOptionLabel={(option) => {
                            return option.tag;
                        }}
                        defaultValue={[]}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.tag}
                            </li>
                          )}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Search by tag"
                        />
                        )}
                    />
                </Box>
                <Box
                    mt={3}
                    display="flex"
                    alignItems="center"
                >
                    <Grid container spacing={1}>
                        <Grid item md={3}>
                            <TextField
                                label="Influencer Type"
                                name="InfluencerType"
                                onChange={handleInfluencerTypeFilter}
                                select
                                fullWidth
                                SelectProps={{native: true}}
                                value={filters.influencerType || "All"}
                                variant="outlined"
                                >
                                    {influencerTypes.map((type) => (
                                        <option key={type.id} value={type.id}>
                                            {type.name}
                                        </option>
                                    ))}
                            </TextField>
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                id="filter-influencer-by-country"
                                sx={{ width: 300 }}
                                options={influencerCountries}
                                autoHighlight
                                onChange={handleCountryFilter}
                                getOptionLabel={(option : any) => option}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option} value={option}>
                                        {option}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Choose a country"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>


                </Box>
            </Box>
            <PerfectScrollbar>
                <Box minWidth={1200} style={{backgroundColor:'white'}}>
                    {loading ?  (
                        <SkeletonTable columns={8} rows={10}/>
                    )
                     : 
                    (
                    <>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Name
                                </TableCell>
                                <TableCell>
                                    Email
                                </TableCell>
                                <TableCell>
                                    Socials
                                </TableCell>
                                <TableCell>
                                    Country
                                </TableCell>
                                <TableCell>
                                    Influencer Type
                                </TableCell>
                                <TableCell>
                                    Contact Person
                                </TableCell>
                                <TableCell>
                                    Last collaboration
                                </TableCell>
                                <TableCell>
                                    Last comment
                                </TableCell>
                                <TableCell>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredInfluencers.map((influencer) => {
                                let isProductSelected = false
                                selectedProducts.forEach(item => {
                                    if (item.id === influencer.id) {
                                        isProductSelected = true
                                    }
                                })
                                return (
                                    <TableRow
                                        hover
                                        key={influencer.id}
                                        selected={isProductSelected}
                                        aria-checked={isProductSelected}
                                    >
                                        <TableCell>
                                            <Link
                                                variant="subtitle2"
                                                color="textPrimary"
                                                underline="none"
                                                target='_blank'
                                                href={influencer.socials && influencer.socials.length > 0 ? influencer.socials[0].account_url : ''}
                                            >
                                                {influencer.name ? influencer.name : 'No data'}
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            {influencer.email ? influencer.email : 'No data'}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                aria-controls="options-menu"
                                                aria-haspopup="true"
                                                onClick={(e) => openSocialsMenu(e, influencer)}
                                                size="large">
                                                <MoreVertIcon/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            {
                                            influencer.address && influencer.address.country_iso_code ? influencer.address.country_iso_code : 'No data'
                                            
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {influencer.influencer_type ? influencer.influencer_type : 'No data'}
                                        </TableCell>
                                        <TableCell>
                                            {influencer.contact_person ? influencer.contact_person : 'No data'}
                                        </TableCell>
                                        <TableCell>
                                            {influencer.last_collaboration_date ? dateFunctions.genericDate(influencer.last_collaboration_date) : 'No collaboration'}
                                        </TableCell>
                                        <TableCell>
                                            {influencer.last_comment !== null ? parse(influencer.last_comment) : 'No comment' }
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                aria-controls="options-menu"
                                                aria-haspopup="true"
                                                onClick={(e) => handleClick(e, influencer)}
                                                size="large">
                                                <MoreVertIcon/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <div>
                        <Grid container>
                            <Grid item md={5}>

                            </Grid>
                            <Grid item md={3} className={`${classes.gridFlex} ${classes.flexEnd}`}>
                                Rows per page:
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={limit}
                                    onChange={handleLimitChange}
                                    style={{ marginLeft: '10px' }}
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item md={2} className={`${classes.gridFlex} ${classes.flexEnd}`}>
                                Page {currentPage} of {numberOfPages}
                            </Grid>
                            <Grid item md={2} className={`${classes.gridFlex} ${classes.flexEnd}`}>
                                <div >
                                    <IconButton
                                        disabled={previousPage === 0 ? true : false}
                                        aria-label="previous"
                                        onClick={handlePreviousPage}
                                        size="large">
                                        <PreviousIcon />
                                    </IconButton>
                                    <IconButton aria-label="next" onClick={handleNextPage} size="large">
                                        <NextIcon />
                                    </IconButton>
                                </div>
                            </Grid>
                        </Grid>
                    </div> 
                    </>
                    )}                     
                </Box>
            </PerfectScrollbar>
            <GenericDialog
                content={<InfluencerCommentsView influencer={selectedInfluencer} />}
                buttons={[<Button variant={'contained'} color={'primary'} onClick={_ => closeCommentsDialog()}>Close</Button>]}
                isOpen={showCommentsDialog}
                onClose={_ => closeCommentsDialog()}
            />
            <Menu
                id="options-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>
                    <div onClick={_ => openCommentsDialog(chosenInfluencer)}>
                        Comments
                    </div>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <div onClick={() => handleEditInfluencer(chosenInfluencer)}>
                        Edit influencer
                    </div>
                </MenuItem>
                {chosenInfluencer && chosenInfluencer.status !== InfluencerBackendStatus.DELETED ?
                    <MenuItem onClick={handleClose}>
                        <AddToCampaign influencer={chosenInfluencer}/>
                    </MenuItem> : ''
                }
                <MenuItem onClick={handleClose}>
                    <AddOrDeleteInfluencer influencer={chosenInfluencer} onRefetch={refetch}/>
                </MenuItem>
            </Menu>
                
            <Menu
                id="socials-menu"
                anchorEl={socialAnchor}
                keepMounted
                open={Boolean(socialAnchor)}
                onClose={handleSocialClose}
            >
                {chosenInfluencer ? chosenInfluencer.socials.map(social => (
                    <MenuItem onClick={handleSocialClose}>
                        {socialLinkToIcon(social.account_url)}
                    </MenuItem>                    
                ))
            : ''}
                
            </Menu>
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    influencers: PropTypes.array.isRequired
};

Results.defaultProps = {
    influencers: []
};

export default Results;
