const resources = {
    en: {
        translation: {
        "Registration": "Influencer Registration",
        "Fullname": "Full name",
        "Email":"Email",
        "accountName": "What is your Instagram account name?",
        "Required": "Required",
        "birthdate": "Birthdate",
        "age": "Age",
        "country": "Country",
        "language": "Choose your language",
        "street": "Street",
        "streetnumber": "Housenumber",
        "zipcode": "Zipcode",
        "city": "City",
        "continue": "Continue",
        "whyworkwithus": "Why do you want to work with us?",
        "nextSocials": "Next please fill in the social media accounts you want to sign up with",
        "instagram": "Instagram",
        "tiktok": "TikTok",
        "youtube": "Youtube",
        "nosocialInput": "You didnt enter a social media account",
        "applicationSend": "Your application has been send",
        "followers": "Followers",
        "iHaveReadThe": "I have read the",
        "termsAndConditions": "Terms and Conditions",
        "InfluencerTermsLink":"https://www.my-jewellery.com/en/conditions-influencer-collaborations",
        "signUp": "Sign up",
        "goBack": "Go back",
        "apiError": "Oh no! Something went wrong with sending your application, please try again or contact My Jewellery",
        "successPageText": "Your application has been sent, we will contact you soon",
        "success":"Thank you for registering!",
        "successPageTextRetry":"Thank you for registering! It seems you're already registered in our system, we'll process your request as soon as possible.",
        "dateOfBirth": "Date of birth",
        "influencerCountry": "Influencer country",
        "influencerLanguage": "Influencer language",
        "chooseLanguage": "Choose a language",
        "cannotRetryRegistration": "Thank you for your application. According to our records you've already registered in the past 3 months. Therefore it is not possible to start a new collaboration at the moment. You can apply again on {0}, sincerely Team My Jewellery."
        }
    },
    nl: {
        translation: {
        "Registration": "Influencer Registratie",
        "Fullname": "Volledige naam",
        "Email": "E-mailadres",
        "accountName": "Wat is je Instagram account naam?",
        "Required": "Required",
        "birthdate": "Geboortedatum",
        "age": "Leeftijd",
        "country": "Land",
        "language": "Kies je taal",
        "street": "Straat",
        "streetnumber": "Huisnummer",
        "zipcode": "Postcode",
        "city": "Stad",
        "continue": "Ga verder",
        "whyworkwithus": "Waarom wil je met ons samenwerken?",
        "nextSocials": "Vul aub je social media kanalen in waarmee je mee wilt doen",
        "instagram": "Instagram",
        "tiktok": "TikTok",
        "youtube": "Youtube",
        "nosocialInput": "Je hebt geen social media account of aantal volgers opgegeven",
        "applicationSend": "Je aanmelding is verstuurd",
        "followers": "Volgers",
        "iHaveReadThe": "Ik ga akkoord met de",
        "termsAndConditions": "Voorwaarden voor samenwerkingen.",
        "InfluencerTermsLink": "https://www.my-jewellery.com/voorwaarden-influencer-samenwerkingen",
        "signUp": "Meld je aan!",
        "goBack": "Ga terug",
        "apiError": "Er ging iets niet goed met het versturen van je aanmelding, probeer het nog een keer of neem contact op met My Jewellery",
        "successPageText": "Je aanvraag is verstuurd, we nemen zo spoedig mogelijk contact met je op",
        "successPageTextRetry":"Bedankt voor het registreren! We zien dat je al bekend bent in ons systeem, we zullen je aanmelding zo snel mogelijk behandelen.",
        "success":"Bedankt voor het registreren!",
        "dateOfBirth": "Geboortedatum",
        "influencerCountry": "Influencer land",
        "influencerLanguage": "Influencer taal",
        "chooseLanguage": "Kies een taal",
        "cannotRetryRegistration": "Bedankt voor je aanmelding. Volgens onze administratie is gebleken dat je je in de afgelopen 3 maanden al eens hebt aangemeld. Op dit moment is het daarom helaas niet mogelijk om een nieuwe samenwerking te starten. Vanaf {0} kun je je opnieuw aanmelden, Liefs Team My Jewellery"
        }
    },
    fr: {
        translation: {
            "Registration": "Influencer Inscription",
            "Fullname": "Nom complet",
            "Email": "Adresse e-mail",
            "accountName": "Quel est le nom de ton compte Instagram?",
            "Required": "Requis",
            "birthdate": "Date de naissance",
            "age": "Âge",
            "country": "Pays",
            "language": "Langue",
            "street": "Adresse",
            "streetnumber": "N° Rue",
            "zipcode": "Code postal",
            "city": "Ville",
            "continue": "Continuer",
            "whyworkwithus": "Purquoi aimerais-tu collaborer avec nous?",
            "nextSocials": "Remplis les réseaux sociaux avec lesquels tu veux participer",
            "instagram": "Instagram",
            "tiktok": "TikTok",
            "youtube": "Youtube",
            "nosocialInput": "Il manque tes réseaux sociaux ou/et le nombre de followers",
            "applicationSend": "Ta demande a été envoyée",
            "followers": "Followers",
            "iHaveReadThe": "J’accepte",
            "termsAndConditions": "les conditions générales.",
            "InfluencerTermsLink": "https://www.my-jewellery.com/fr/conditions-collaboration-influenceur?___store=con_fr",
            "signUp": " Inscris-toi!",
            "goBack": "Retour",
            "apiError": "Il y a eu un problème avec ta demande d'influenceur. Essaie à nouveau ou contacte My Jewellery",
            "successPageText": "Ta demande a été envoyée, nous te contacterons dès que possible.",
            "successPageTextRetry":"Merci pour ton inscription. Nous voyons que tu es déjà dans notre système, nous allons examiner ta demande dès que possible.",
            "success":"Merci pour ton inscription!",
            "dateOfBirth": "Date de naissance",
            "influencerCountry": "Pays de l'influenceur",
            "influencerLanguage": "Langue de l'influenceur",
            "chooseLanguage": "Choisis une langue",
            "cannotRetryRegistration": "Merci pour ton inscription. Selon notre administration, tu t'es déjà inscrit à une collaboration au cours des 3 derniers mois. Pour l'instant, il n'est pas possible de commencer une nouvelle collaboration. À partir de {0}, tu pourras t'inscrire à nouveau ! With love, Team My Jewellery "
            }
    },
    de: {
        translation : {
            "Registration": "Influencer Anfrage",
            "Fullname": "Vollständiger Name",
            "Email": "E-Mail Adresse",
            "accountName": "Wie lautet dein Instagram Name?",
            "Required": "Pflichtfeld",
            "birthdate": "Geburtsdatum",
            "age": "`Alter",
            "country": "Land",
            "language": "Wähle deine Sprache",
            "street": "Straße",
            "streetnumber": "Hausnummer",
            "zipcode": "Postleitzahl",
            "city": "Stadt",
            "continue": "Weiter",
            "whyworkwithus": "Warum möchtest du mit uns zusammenarbeiten?",
            "nextSocials": "Gib hier deine Social Media Channels ein, mit denen du mitmachen möchtest.",
            "instagram": "Instagram",
            "tiktok": "TikTok",
            "youtube": "YouTube",
            "nosocialInput": "Du hast keinen Social Media Account oder eine Anzahl Follower angegeben",
            "applicationSend": "Deine Anmeldung wurde verschickt",
            "followers": "Followers",
            "iHaveReadThe": "Ich stimme den folgenden Bedingungen zu",
            "termsAndConditions": "Allgemeinen Geschäftsbedingungen zur Kooperation.",
            "signUp": "Melde dich an!",
            "goBack": "Zurück",
            "apiError": "Leider ist ein Fehler aufgetreten beim Versenden deiner Anfrage. Probiere es erneut oder kontaktiere das Team von My Jewellery",
            "successPageText": "Deine Anfrage wurde verschickt. Wir melden uns so schnellst möglich bei dir.",
            "succes":"Danke für deine Anmeldung!",
            "dateOfBirth": "Geburtsdatum",
            "emailRegistered": "Diese Mail Adresse ist bereits registriert.",
        }
    }
};

export default  resources