import {
    useState,
    useEffect,
} from 'react';
import type { FC } from 'react';
import { Backdrop, Box, Card, CircularProgress, Container, Tab, Tabs } from '@mui/material';
import { makeStyles } from "@mui/styles";
import Page from '../../components/page/Page';
import type { Influencer } from '../../types/influencer';
import Header from './influencerHeader';
import Results from './influencerSummary';
import { InfluencerClient } from '../../utils/axios/InfluencerClient'
import { useQuery } from 'react-query';


interface ResultsProps {
    title?: string;
    type?: string;
    url?: string
}


function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const useStyles = makeStyles((theme: any) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: 100
    }
}));





const ProductListView: FC<ResultsProps> = ({ title, url }) => {
    const classes = useStyles();
    const [pageLimit, setPageLimit] = useState(25);
    const [page, setPage] = useState(1);
    const [value, setValue] = useState(0);
    const [status, setStatus] = useState(2);
    const [countryFilter, setCountryFilter] = useState('null')
    const [tags, setTags] = useState([])
    const [isReFetching, setIsRefetching] = useState(false);
    const [checkDate, setCheckDate] = useState(false);
    const [search_term, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [newComment, setNewComment] = useState<boolean>(false);
    const influencerClient = new InfluencerClient();

    const { isLoading, error, data, refetch, isFetching } = useQuery(['getInfluencers', status], () =>
        influencerClient.getAllInfluencers(status, pageLimit, search_term, page, checkDate, tags, countryFilter)
    )

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        switch (newValue) {
            case 0:
                setStatus(2)
                break;

            case 1:
                setStatus(5)
                break;

            default:
                break;
        }
        setValue(newValue);
    };


    useEffect(() => {
        refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageLimit, search_term, page, newComment, tags, countryFilter])

    return (
        <Page
            className={classes.root}
            title="Influencer List"
        >
            <Container maxWidth={false}>
                <Header title={title} />
                {isLoading  ? (
                    <div>Retrieving Influencer data...</div>
                ) : (
                    <Box mt={3}>
                        <Card>
                            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                <Tab label="Active influencers" {...a11yProps(1)} />
                                <Tab label="deleted influencers" {...a11yProps(2)} />
                            </Tabs>
                            {isFetching ? (
                                <Backdrop
                                sx={{  zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={true}
                              >
                                <CircularProgress />
                              </Backdrop>
                            ) : (<></>)}
                            <Results
                                loading={loading}
                                setLoading={setLoading}
                                influencers={data.data.RESULTS.influencers}
                                nextPage={data.data.RESULTS.nextPage}
                                previousPage={data.data.RESULTS.prevPage ? data.data.RESULTS.prevPage : 0}
                                numberOfPages={data.data.RESULTS.pages}
                                currentPage={data.data.RESULTS.currentPage}
                                checkDate={checkDate}
                                search={setSearchTerm}
                                setPageLimit={setPageLimit}
                                setPage={setPage}
                                setNewComment={setNewComment}
                                refetch={refetch}
                                newComment={newComment}
                                tags={tags}
                                filterCountry={countryFilter}
                                setFilterCountry={setCountryFilter}
                                setTags={setTags} />
                        </Card>
                    </Box>
                )}
            </Container>
        </Page>
    );
};

export default ProductListView;