import {
    Box,
    Typography,
    Divider,
    Card,
    CardActions,
    CardContent,
    Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext, useState } from "react";
import { Context } from "../../Store/store";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DateFunctions from "../../utils/dates/dateClass";
import { useConfirm } from "material-ui-confirm";
import { TagClient } from "../../utils/axios/TagsClient";
import Collapse from '@mui/material/Collapse';
import toast from "react-hot-toast";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowParams,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';





const useStyles = makeStyles({
    root: {
      maxWidth: 275,
      minWidth: 275,
      margin:'20px 20px 20px 0',
    },
    bold: {
        fontWeight:800
    }
  });


const TagSettings = () => {
    const [state, setState] = useContext(Context);
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const dateFunctions = new DateFunctions();
    const tagClient = new TagClient();
    const confirm = useConfirm();

    const handleClick = () => {
        setOpen(!open);
    };

    function deleteTag (id:any, tagname: string){
        confirm({ description: `You are about to delete tag with id: ${id} and name : ${tagname}` , confirmationButtonProps: { autoFocus: true }})
          .then(() => { tagClient.deleteTag(id).then(res=> {
           if (res.status === 200){
            toast.success('Tag has been deleted')
            tagClient.getTags()
            .then(res =>{
              const tags = [];
              res.data.RESULTS.forEach(item => {
                  tags.push(
                      {
                          creation_date: item.creation_date,
                          id: item.id,
                          modified_date: item.modified_date,
                          tag: item.tag,
                          tagger: item.tagger,
                      })
              })
              
              setState({
                  ...state,
                  tags: tags
              })
        })
           } else {
            toast.error("Something went wrong, try again or contact Development");
           }
          })})
          .catch(() => { /* ... */ });
    };

    const columns = [
      { field: 'id', headerName: 'ID', width: 90 },
      {
        field: 'tag',
        headerName: 'Tag',
        width: 250,
        editable: true,
      },
      {
        field: 'tagger',
        headerName: 'Creator',
        width: 300,
        editable: true,
      },
      {
        field: 'creation_date',
        headerName: 'Creation date',
        type: 'date',
        width: 250,
        editable: true,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName:'Delete',
        getActions: (params: any) => [
          <GridActionsCellItem icon={<DeleteIcon />} onClick={() => deleteTag(params.id, params.row.tag)} label="Delete" />,
        ]
      }
    ];

    return (
            <Box style={{display:'flex', flexWrap:'wrap'}}>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={state.tags}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  disableSelectionOnClick
                />
              </div>
            </Box>        
    )
}

export default TagSettings;







