import BackendClient from "./_BackendClient";

export class VoucherClient extends BackendClient {
    constructor() {
        super();
        this.apiurl = this.apiurl + '/voucher'
    }

    public getRedeemedInPeriod(start: Date, end: Date, page: number = 1, limit: number = 50) {
        return this.makeRequest('POST', 'get-redeemed-in-period', { start, end, page, limit });
    }
}