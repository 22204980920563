import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";


interface HeaderProps {
    className?: string;
}

const useStyles = makeStyles((theme: any) => ({
    root: {},
    action: {
        marginBottom: theme.spacing(1),
        '& + &': {
            marginLeft: theme.spacing(1)
        }
    }
}));


const Header: FC<HeaderProps> = ({ className, ...rest }) => {
    const classes = useStyles();

    return (
        <Grid
            container
            spacing={3}
            justifyContent="space-between"
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Grid item>
                <Typography
                    variant="h2"
                    color="textPrimary"
                    className="font40 Capitalize"
                >
                    Mail overview
                </Typography>
            </Grid>
        </Grid>
    );
};

Header.propTypes = {
    className: PropTypes.string
};

export default Header;
