import axios from 'axios'
import { EnvironmentVariablesProvider } from '../../environment/EnvironmentVariablesProvider';

export class PublicBackendClient {
    protected headers: any;
    protected apiurl: string;
    private environmentVariablesProvider: EnvironmentVariablesProvider;
    private accessToken: string;    

    constructor(){
        this.environmentVariablesProvider = new EnvironmentVariablesProvider();
        this.apiurl = this.environmentVariablesProvider.getVariable('api_url');
        this.accessToken = window.sessionStorage.getItem('token')
        this.headers = { "Authorization": this.accessToken ? 'Bearer ' + this.accessToken : "" ,
                        "Content-Type": "application/json", "Route":"public"}
    }

    protected async makeRequest(method: string, url: string, payload: any = null, customHeaders: any = null): Promise<any> {
        
        let requestHeaders = this.headers;
        if (customHeaders)
            requestHeaders = { ...this.headers, ...customHeaders }
        
        const axiosRequest:any = {method: method, url: `${this.apiurl}/${url}`, headers: requestHeaders};
        if (payload) {
            axiosRequest["data"] = payload;
        }
        return await axios(axiosRequest);
    }

    public createInfluencerFromRegistration(createInfluencerDataObject: any) {
        return this.makeRequest("POST", "/influencer/create-influencer", createInfluencerDataObject)
    }
}

export default PublicBackendClient;