import { Box, Button, Grid, Modal, CircularProgress } from "@mui/material";
import { FC, useState } from "react";
import { collabData, post } from "../../types/collabData";
import DateFunctions from "../../utils/dates/dateClass";
import HeartRating from "../Rating/rating.component";
import {PostClient} from "../../utils/axios/PostClient";
import {Alert} from '@mui/material';

interface postReviewProps {
  post: post;
  collaboration:collabData;
}

const InstaPostReview: FC<postReviewProps> = ({ post, collaboration }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = useState<number>(0);
  const [sendMail, setSendMail] = useState<boolean>(false);
  const [ratingContent, setRatingContent] = useState<string>('');
  const [saveStatus, setSaveStatus] = useState({
    saveSuccess: false,
    saveFailed: false,
    failedError:''
  });
  const dateFunctions = new DateFunctions();
  const postClient = new PostClient();
  const [loading, setLoading] = useState<boolean>(false);

  const saveRating = () => {
    setLoading(true)
    postClient.reviewPost(post.id, value, ratingContent, sendMail)
    .then(res => {
        if (res.status === 200){
            setLoading(false)
            setSaveStatus({...saveStatus, saveSuccess: true})
        } else {
            setSaveStatus({...saveStatus, saveFailed: true})
            setSaveStatus({...saveStatus, failedError: res.status})
            setLoading(false)
        }
    })
    .catch(err => {
        setSaveStatus({...saveStatus, saveFailed: true})
        setSaveStatus({...saveStatus, failedError: err})
        setLoading(false)
    })
}

  return (
    <div style={{width:'100%'}}>
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid item md={4}>
            <img
              onClick={handleOpen}
              src={post.media_url}
              alt='post_image'
              style={{ width: "100%", cursor: "pointer" }}
            />
          </Grid>
          <Grid item md={8} style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <div>
                <div>
                    <div className="reviewTitle">
                        Caption:
                    </div>
                    <div className="reviewsubTitle">
                        {post.caption}
                    </div>

                    <div className="reviewTitle">
                        User:
                    </div>
                    <div className="reviewsubTitle">
                        {post.username}
                    </div>

                    <div className="reviewTitle">
                        Date:
                    </div>
                    <div className="reviewsubTitle">
                        {dateFunctions.genericDate(post.timestamp)}
                    </div>

                    <div className="reviewTitle">
                        Link:
                    </div>
                    <div className="reviewsubTitle">
                        <Button variant="outlined" color="primary" href={post.permalink} target='_blank' size='small'>
                            Open socials
                        </Button>
                    </div>

                    <div className="reviewTitle">
                        Rating:
                    </div>
                    <div className="reviewsubTitle">
                        <HeartRating key={post.id} post={post.score} id={post.id} reason={post.comment} setRating={setValue} setSendMail={setSendMail} setRatingContent={setRatingContent} sendMail={sendMail} />
                        <div className='relative'>
                            {post.score == null && 
                                <Button variant='contained' color='primary' onClick={saveRating} disabled={loading || value === 0} style={{marginTop:'10px', position:'relative'}} size='small'>
                                    Save review
                                    {loading && <CircularProgress size={24} className='buttonProgress' />}
                                </Button>
                            } 
                            <Box pt={2}>
                            {saveStatus.saveSuccess && <Alert severity="success">Rating saved!</Alert> }
                            {saveStatus.saveFailed && <Alert severity="error">Something went wrong! <span style={{fontWeight:'bold'}}>{saveStatus.failedError}</span></Alert> }
                            </Box>
                        </div>
                    </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ width: "40%", margin: "auto" }}
      >
        <Box p={2}>
          <img
            src={post.media_url}
            style={{ width: "100%", margin: "0 auto", display: "block" }}
            alt='modal'
          />
        </Box>
      </Modal>
    </div>
  );
};

export default InstaPostReview;
