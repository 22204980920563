import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext, useReducer, useState } from 'react';
import { SettingsClient } from '../../../utils/axios/SettingsClient';
import { Context } from '../../../Store/store';
import AddIcon from '@mui/icons-material/Add';

const settingsClient = new SettingsClient();
const initialState = {countryName: "", countryAbbreviation: ""}

const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case 'reset':
      return initialState
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

const CreateCountryDialog = () => {
    const [open, setOpen] = useState(false);    
    const [state, dispatch] = useReducer(reducer, initialState);
    const [contextState, setContextState] = useContext(Context);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
    setOpen(false);
    };

    const inputAction = (event) => {
        dispatch({
          type: 'update',
          payload: { key: event.target.id, value: event.target.value },
        });
      };

    const saveCountry = () => {
      const payload = {campaign_country: state.countryName, campaign_country_abbreviation: state.countryAbbreviation}
      settingsClient.create_campaign_country(payload).then(res => {
        if (res.status === 200){
          settingsClient.get_all_campaign_countries().then(response =>{
            setContextState({
              ...contextState,
              campaign_countries : response.data.RESULTS
            })
            setOpen(false);
            dispatch({ type: "reset" })
          })
        }
      })
    }



    return (
        <div>
            <Button variant="outlined" color='primary' onClick={handleClickOpen} startIcon={<AddIcon />}>
                Add new country
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add new country</DialogTitle>
                <DialogContent>
                <TextField
                    margin="dense"
                    id="countryName"
                    label="Name of country"
                    type="text"
                    value={state.countryName}
                    fullWidth
                    variant="outlined"
                    onChange={inputAction}
                />
                <TextField
                    margin="dense"
                    id="countryAbbreviation"
                    label="Abbreviation"
                    type="text"
                    value={state.countryAbbreviation}
                    fullWidth
                    variant="outlined"
                    onChange={inputAction}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} variant='outlined' color='secondary'>Cancel</Button>
                <Button onClick={saveCountry} variant='contained' color='primary'>Save country</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default  CreateCountryDialog;