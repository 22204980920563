import {
    useState,
    useEffect,
} from 'react';
import type { FC } from 'react';
import { AppBar, Box, Card, Container, Tab, Tabs } from '@mui/material';
import Page from '../../../components/page/Page';
import type { Influencer } from '../../../types/influencer';
import Header from './campaignHeader';
import Results from './campaignOverview';
import { CampaignClient } from '../../../utils/axios/CampaignClient';
import { useNavigate } from "react-router-dom";

interface ResultsProps {
    title?: string;
    type?: string;
    url?: string
}

function a11yProps(index: any) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const ProductListView: FC<ResultsProps> = ({ title, url }) => {
    const [selectedProducts, setSelectedProducts] = useState<any>([]);
    const [campaigns, setCampaigns] = useState<Influencer[]>([]);
    const [archivedCampaigns, setArchivedCampaigns] = useState<Influencer[]>([]);
    const [loading, setLoading] = useState(true)
    const campaignClient = new CampaignClient();
    const [value, setValue] = useState(0);
    const navigate = useNavigate();

    const getCampaigns = () => {
        try {
            setLoading(true);
            campaignClient.getAllCampaigns()
                .then(res => {
                    setCampaigns(res.data.RESULTS);
                    setLoading(false);
                })
        } catch (err) {
            console.error(err);
        }
    };

    const getArchivedCampaigns = () => {
        try {
            setLoading(true);
            campaignClient.getAllArchivedCampaigns()
                .then(res => {
                    setCampaigns(res.data.RESULTS);
                    setLoading(false);
                })
        } catch (err) {
            console.error(err);
        }
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        getCampaigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const createCampaign = () => {
        if (selectedProducts.length === 0) {
            const selectedId = 0;
            campaignClient.trackCreation()
                .catch(err => {
                    console.log(err);
                    Error("Error occured: " + err)
                })
            navigate("/admin/campaignDetail/" + selectedId);
        }
    }

    const filterByCountry = (country: any) => {
        campaignClient.filterByCountry(country).then((res :any) => {setCampaigns(res.data.RESULTS)})
    
    }

    return (
        <Page
            title="Campaign overview"
         >
            <Container maxWidth={false}>
                <Header title={title} createCampaign={createCampaign} />
                {campaigns && (
                    <Box mt={3}>
                        <Results
                            selectedProducts={selectedProducts}
                            setSelectedProducts={setSelectedProducts}
                            campaigns={campaigns}
                            loading={loading}
                            setLoading={setLoading}
                            getCampaigns={getCampaigns}
                            filterByCountry={filterByCountry}
                            getArchivedCampaigns={getArchivedCampaigns}/>
                    </Box>
                )}
            </Container>
        </Page>
    );
};

export default ProductListView;
