import React, { useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {
    value: string;
    setValue: (value:string) => void
}

export default function TextEditor ({value, setValue} : Props) {
    
    let toolbarOptions = [
        [{ 'size': [] }],
        [ 'bold', 'italic', 'underline', 'strike' ],
        [ { 'align': [] }],
        [{ 'color': [] }, { 'background': [] }],
        ['blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet'}],
    ]
        
    return (
        <>
            <ReactQuill theme="snow" modules={{toolbar:toolbarOptions}} value={value} onChange={setValue}/>
        </>


    )
}