import React from 'react';
import type { FC } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { makeStyles } from "@mui/styles";
import Page from '../../components/page/Page';
import AreaChart from './AreaChart';
import LineChart from './LineChart';
import RadialChart from './RadialChart';

const useStyles = makeStyles((theme: any) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    }
}));

const ApexChartsView: FC = () => {
    const classes = useStyles();

    return (
        <Page
            className={classes.root}
            title="Dashboard"
        >
            <Container maxWidth="lg">
                <Box mt={0}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <LineChart />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={8}
                        >
                            <AreaChart />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            <RadialChart />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Page>
    );
};

export default ApexChartsView;
