import { Box, Button, Card, CardContent, Grid, Typography, Divider} from '@mui/material'
import {
    CheckCircle as Check
} from 'react-feather';
import { useTranslation } from 'react-i18next';

const InfluencerRegistrationFormSuccesPage = ({goBack, isRetry}) => {
    const { t } = useTranslation();
    return (
        <Box mb={4}>
            <Card>
                <Box m={3}>
                    <Grid container spacing={1}>
                        <Grid item md={12} className='flexCenter'>
                            <Typography variant="h4" className="centerSelf">
                                {t('success')}
                            </Typography>                        
                        </Grid>
                    </Grid>
                </Box>
                    <Divider />
                    <CardContent>
                        <div className='flexCenter'>
                            <Check className="check5050Primary"/>
                            </div>
                        <div className="flexCenter textCenter" style={{padding:'30px 50px'}}>
                            <Typography>            
                            {isRetry ?  t('successPageTextRetry') : t('successPageText')}
                            </Typography>
                        </div>
                        <div className='flexCenter'>
                        <Button variant='contained' color="primary" onClick={goBack}>{t('goBack')}</Button> 
                        </div>
                    </CardContent>
            </Card>
        </Box>

    )
}

export default InfluencerRegistrationFormSuccesPage;