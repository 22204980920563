import React, { FC, useState , useEffect} from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import createStyles from '@mui/styles/createStyles';
import { Modal, Backdrop, Fade, Button, Grid, TextField, InputAdornment, CircularProgress, IconButton,  } from '@mui/material';
import {CollaborationClient} from '../../utils/axios/CollaborationClient'
import {ShoppingMoneyClient} from '../../utils/axios/ShoppingMoneyClient'
import {
    Edit as EditIcon
} from 'react-feather';
import toast from 'react-hot-toast';


interface Propjes {
    modalTitle?: string;
    contentTitle?: string;
    className?: string;
    variant?: any;
    color?: any;
    collaborationData: any;
    startIcon?: any;
    getCollaborations: any;
    shoppingMoneyListFromParent: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        buttonProgress: {
            color: '#e40b7D',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    }),
);

const InfluencerCampaignEdit: FC<Propjes> = ({ modalTitle, contentTitle, className, variant, color, startIcon, collaborationData, getCollaborations, shoppingMoneyListFromParent }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [shoppingMoneyList, setShoppingMoneyList] = useState(shoppingMoneyListFromParent)
    const [shopMoney, setShopMoney] = useState('')
    const [swipeUpLink, setSwipeUpLink] = useState('');
    const [sendingSwipeUpLink, setSendingSwipeUpLink] = useState(false)
    const shoppingMoneyClient = new ShoppingMoneyClient()
    const collaborationClient = new CollaborationClient()

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const sendMailWithSwipeUpLink = () => {
        setSendingSwipeUpLink(true)
        collaborationClient.sendSwipeUpLinkEmail([collaborationData])
        .then(res => {
            if (res.status === 200) {
                toast.success(`Reminder send to: ${collaborationData.influencer.name}`)
                setSendingSwipeUpLink(false)
            } else {
                toast.error(`Something went wrong, try again or contact development`)
                setSendingSwipeUpLink(false)
            }
        })
    }

    const handleDiscount = (event) => {
        setSwipeUpLink(event.target.value)
    }

    const handleClick = () => {

        let data = {
            collaboration_id: collaborationData.id,
            shopping_money: shopMoney,
            swipe_up_link: swipeUpLink
        }

        collaborationClient.updateShoppingMoneyAndSwipeUpLink(data)
            .then(res => {
                if (res.status === 200){
                    toast.success('Data saved')

                    handleClose()
                    getCollaborations()
                } else {
                    toast.error('Something went wrong, try again or contact development')
                }
            }).catch(e => {
                toast.error('Something went wrong, try again or contact development with the following:: ' + e)
            });
    }

    useEffect(() => {
        setShopMoney(collaborationData.shopping_money_voucher.shopping_money);
        setSwipeUpLink(collaborationData.swipe_up_link);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <span>
            <IconButton color={color} onClick={handleOpen} size="large">
                <EditIcon/>
            </IconButton>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="noOutlineBorder flexCenter"
                open={open}
                
                onClose={handleClose}
                closeAfterTransition
            >
                <Fade in={open}>
                    <div className={classes.paper} style={{ outline: 0, border: 'none', width: '70%' }}>
                        <div key={collaborationData.id}><h2 id="transition-modal-title"> Change campaign details of influencer:  {collaborationData.influencer.name}, influencer ID : {collaborationData.influencer_id}</h2></div>
                        <div id="transition-modal-description">
                            <Grid container spacing={3}>
                                <Grid item md={6}>
                                    <TextField
                                        fullWidth
                                        label="Name of influencer"
                                        name="name"
                                        disabled
                                        value={collaborationData.influencer.name}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        fullWidth
                                        name="shoppingMoney"
                                        label="Allocated shopping money"
                                        onChange={(event) => setShopMoney(event.target.value)}
                                        select
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                        }}
                                        className='MuiFormLabel-filled'
                                        SelectProps={{ native: true }}
                                        value={shopMoney === collaborationData.shopping_money_voucher.shopping_money ? collaborationData.shopping_money_voucher.shopping_money : shopMoney}
                                        variant="outlined"
                                    >
                                        {shoppingMoneyList.map((option) => (
                                            <option 
                                                key={option}
                                                value={option}
                                            >
                                                {option}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        fullWidth
                                        label="Contact person"
                                        name="contactperson"
                                        disabled
                                        value={collaborationData.contact}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item md={6}>
                                        <TextField
                                            fullWidth
                                            label="Swipe up link"
                                            name="Swipe up link"
                                            required
                                            onChange={handleDiscount}
                                            value={swipeUpLink}
                                            variant="outlined"
                                        />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        fullWidth
                                        label="Instagram name"
                                        name="instagramName"
                                        disabled
                                        value={collaborationData.influencer.socials.account_id}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                variant='contained'
                                color='primary'
                                style={{ margin: '30px 10px 30px 0' }}
                                onClick={function () {
                                    handleClick()
                                }}
                            >
                                Save
                            </Button>
                            <span className="posRelative">
                                <Button
                                    variant='contained'
                                    color='primary'
                                    disabled={!swipeUpLink || sendingSwipeUpLink}
                                    style={{ margin: '30px 0' }}
                                    onClick={function () {
                                        sendMailWithSwipeUpLink()
                                    }}
                                >
                                   Send email with swipe up link
                                </Button>
                                {sendingSwipeUpLink && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </span>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </span>
    );
}

export default InfluencerCampaignEdit;
