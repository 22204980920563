import { Typography, Grid, Button } from "@mui/material";
import toast from "react-hot-toast";

const UploadFileComponent = ({fileToUpload, setFileToUpload, setFileName}) => {       
        
    const handleFileUpload = (event) => {
        let selectedFile = event.files;
        let file = null;
        let fileName = "";
        if (selectedFile.length > 0) {
            let fileToLoad = selectedFile[0];
            if (fileToLoad.size > 1000000){
                toast.error('This file is to big to be loaded, Max 1MB')
                return
            }
            fileName = fileToLoad.name;
            let fileReader = new FileReader();
            fileReader.onload = function(fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                file = file.replace('data:application/pdf;base64,','')
                setFileName(fileName)
                setFileToUpload(file)
            };
            fileReader.readAsDataURL(fileToLoad);
        }
    }

    return (
            <>
                <Typography color="textSecondary" gutterBottom>
                    PDF File
                </Typography>
                <Typography variant="body2" component="span">
                    Choose a pdf file to add to the acceptance email
                </Typography>
                <Grid container spacing={0} style={{margin:'20px 0 20px 0'}}>
                    <Grid item md={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="label"
                        >
                            Choose File
                            <input
                                type="file"
                                id="pdfUpload"
                                name="pdfUpload"
                                accept="application/pdf"
                                hidden
                                onChange={event => handleFileUpload(event.target)}
                            />
                        </Button>
                    </Grid>
                </Grid>{
                fileToUpload ?
                        <iframe title="pdftosend" id="previewframe" src={`data:application/pdf;base64,${fileToUpload}`} width="100%" height="400" style={{margin:'20px 0 20px 0'}}></iframe>
                        :
                        ""
                }
            </>
    )
}

export default UploadFileComponent
