import React, { useContext } from 'react';
import withStyles from '@mui/styles/withStyles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import IconButton from '@mui/material/IconButton';
import { Context } from '../../../../Store/store';
import { SignInButton } from '../../../../views/login/signInButton';
import { useMsal } from "@azure/msal-react";

const StyledMenu = withStyles({
    paper: {
        border: '0px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

function handleLogout(instance) {
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });
}


export default function NavUserTwo() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [state, setState] = useContext(Context);
    const { instance } = useMsal();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (state.auth) {
        return (
            <div>
                <IconButton
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    className="mainColor"
                    onClick={handleClick}
                    size="large">
                    <AccountCircleIcon style={{ width: '35px', height: '35px' }} />
                </IconButton>
                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <StyledMenuItem onClick={handleClose}>
                        <ListItemIcon >
                            <PersonIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={state.auth.displayName} />
                    </StyledMenuItem>
                    <StyledMenuItem
                        onClick={handleClose}
                        divider
                    >
                        <ListItemIcon >
                            <MailOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={state.auth.mail} />
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => handleLogout(instance)}>
                        <ListItemIcon >
                            <ExitToAppIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary='Log out' />
                    </StyledMenuItem>
                </StyledMenu>
            </div>
        );
    } else {
        return (
            <div style={{ margin: '0 0 0 auto' }}>
                <IconButton
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    className='mainColor'
                    onClick={handleClick}
                    size="large">
                    <AccountCircleIcon style={{ width: '35px', height: '35px' }} />
                </IconButton>
                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <SignInButton/>
                </StyledMenu>
            </div>
        );
    }

}