import { FC, useState } from 'react';
import {
    TextField, Typography, Card, CardContent, Grid, Box, InputAdornment, SvgIcon, Link, Button, CircularProgress
} from '@mui/material';
import type { Registration } from '../../types/influencer';
import Page from '../page/Page';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {
    ExternalLink
} from 'react-feather';
import { InfluencerClient } from '../../utils/axios/InfluencerClient';
import DateFunctions from '../../utils/dates/dateClass';
import toast from 'react-hot-toast';


interface ResultsProps {
    registration: Registration[];
    openDialog: any;
    getRegistrations: any;
}

const translateCountryIsoCode = (iso) => {
    switch (iso) {
        case 'NL':
            return 'The Netherlands'
        
        case 'BE':
            return 'Belgium'
        
        case 'EN':
            return 'United Kingdom'
        default:
        break;
    }
}

const Results: FC<ResultsProps> = ({  registration, openDialog, getRegistrations }) => {
    const influencerClient = new InfluencerClient();
    const dateFunctions = new DateFunctions();
    const [isLoading, setIsLoading] = useState<boolean>(false)

    var registrations = [];
    registrations.push(registration)

    const acceptRegistration = () => {
        setIsLoading(true)
        influencerClient.acceptRegistration(registrations[0].id)
        .then(res => {
            if(res.status === 200){
                toast.success(`Registration accepted!`)
                openDialog()
                getRegistrations()
                setIsLoading(false)
            } else {
                toast.error(`Something went wrong, try again or contact development with the following: ${res.status}`)
            }
        })
        .catch(err =>{
            toast.error(`Something went wrong, try again or contact development with the following: ${err}`)
        })
    }

    const declineRegistration = () => {
        setIsLoading(true)
        influencerClient.declineRegistration(registrations[0].id)
        .then(res => {
            if (res.status === 200){
                toast.success(`Registration rejected!`)
                openDialog()
                getRegistrations()
                setIsLoading(false)
            } else {
                toast.error(`Something went wrong, try again or contact development with the following: ${res.status}`)
            }
        })
        .catch(err =>{
            toast.error(`Something went wrong, try again or contact development with the following: ${err}`)
        })
    }

    if (registrations[0].length === 0){
        return (
            <div>...Loading</div>
        )
    } else {
            return(
                <div>
                    {registrations.map((item) =>{
                        return(
                        <Page className="influencerProfileInput influencerProfileHeader">
                            <Card>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item md={6}>
                                        <Typography className="language">Personal data:</Typography>
                                        <Box my={2}>
                                            <TextField fullWidth variant='outlined' value={item.name} label="Name" disabled InputLabelProps={{shrink:true}}/>
                                        </Box>
                                        <Box my={2}>
                                            <TextField fullWidth variant='outlined' value={item.email} label="E-mail" disabled InputLabelProps={{shrink:true}}/>
                                        </Box>
                                        <Box my={2}>
                                            <TextField fullWidth variant='outlined' value={dateFunctions.genericDate(item.birth_date)} label="Birthdate" disabled InputLabelProps={{shrink:true}}/>
                                        </Box>
                                        <Box my={2}>
                                            <TextField fullWidth variant='outlined' value={item.motivation} label="Motivation" disabled InputLabelProps={{shrink:true}}/>
                                        </Box>
                                        </Grid>
                                        <Grid item md={6}>
                                        <Typography>Address data:</Typography>
                                        <Box my={2}>
                                            <TextField fullWidth variant='outlined' value={item.address.street +' '+ item.address.house_number_and_addition} label="Street" disabled InputLabelProps={{shrink:true}}/>
                                        </Box>
                                        <Box my={2}>
                                            <TextField fullWidth variant='outlined' value={item.address.postal_code} label="Postalcode" disabled InputLabelProps={{shrink:true}}/>
                                        </Box>
                                        <Box my={2}>
                                            <TextField fullWidth variant='outlined' value={item.address.city} label="City" disabled InputLabelProps={{shrink:true}}/>
                                        </Box>
                                        <Box my={2}>
                                            <TextField fullWidth variant='outlined' value={item.address ? translateCountryIsoCode(item.address.country_iso_code) : ''} label="Country" disabled InputLabelProps={{shrink:true}}/>
                                        </Box>
                                        </Grid>
                                        <Typography>Social Media data</Typography>
                                            {item.socials.map((social) =>{
                                                return (
                                                    <Grid container spacing={1}>
                                                        <Grid item md={4}>                                        
                                                            <Box my={2}>
                                                                <TextField fullWidth variant='outlined' value={social.account_id} label='Account name' disabled InputLabelProps={{shrink:true}}/>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item md={4}>                                        
                                                            <Box my={2}>
                                                                <TextField 
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    value={social.account_url}
                                                                    label='Account'
                                                                    disabled
                                                                    InputLabelProps={{shrink:true}}
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="start" style={{marginLeft:'7px'}}>
                                                                            <Link href={social.account_url} target='_blank'>
                                                                        <SvgIcon
                                                                            fontSize="small"
                                                                            color="action"
                                                                        >
                                                                                <ExternalLink/>
                                                                        </SvgIcon>     
                                                                        </Link>
                                                                        </InputAdornment>,
                                                                    }}
                                                                    />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item md={4}>
                                                            <Box my={2}>
                                                                <TextField
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    value={social.followers}
                                                                    label='Followers'
                                                                    disabled
                                                                    InputLabelProps={{shrink:true}}
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start" style={{marginLeft:'7px'}}>
                                                                        <SvgIcon
                                                                            fontSize="small"
                                                                            color="action"
                                                                        >
                                                                                <PeopleAltIcon/>
                                                                        </SvgIcon>     
                                                                        </InputAdornment>,
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                    </Grid>
                                    <Box my={5}>
                                    <Grid container spacing={1} className='flexBetween'>
                                        <Grid item md={4}>
                                            <div style={{position:'relative'}}>
                                                <Button fullWidth onClick={acceptRegistration} disabled={isLoading} variant="contained" color="primary" >Accept</Button>
                                                {isLoading && <CircularProgress size={24} style={{color:'green', position:'absolute', top:'50%', left:'50%', marginTop:-12, marginLeft:-12}} />}
                                            </div>
                                            
                                        </Grid>
                                        <Grid item md={4}>
                                            <div style={{position:'relative'}}>
                                                <Button fullWidth onClick={declineRegistration} disabled={isLoading} variant="outlined" color="primary">Reject</Button>
                                                {isLoading && <CircularProgress size={24} style={{color:'green', position:'absolute', top:'50%', left:'50%', marginTop:-12, marginLeft:-12}} />}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </Box>
                                </CardContent>
                            </Card>                    
                        </Page>
                        )
                    })}
                </div>
            );
        }
};

export default Results;
