import { makeStyles } from "@mui/styles";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chart from "react-apexcharts";
import type { ApexOptions } from 'apexcharts';
import { Grid } from '@mui/material';
import SkeletonCard from '../../../components/skeletonCard/skeletonCard';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        maxWidth:400,
        backgroundColor:'#f9f9f9 !important',
        padding:'16px 16px 0 16px !important'
    },
    card: {
        padding:'0 !important'
    },
});

const VoucherOverview = ({vouchers, loading}) => {
    const classes = useStyles();

    const chartOptions: ApexOptions = {
        chart: {
          background: 'transparent',
          stacked: false,
          toolbar: {
            show: false
          }
        },
        colors:['#E9B9C4'],
        fill: {
          opacity: 1
        },
        labels: ['Left'],
        plotOptions: {
          radialBar: {
            offsetX: -25,
            offsetY: -25,
            track: {
              background: 'white'
            }
          }
        },
      }; 

    return (
        <div>
            <div className="flexStart alignItemsCenter flowRowWrap">
                {loading
                        ?
                        (
                            <SkeletonCard numberOfCards={5}/>
                        )
                        :
                        (
                            vouchers.map((item) => {
                                return(
                                        <div key={item.amount} style={{maxWidth:'600px', margin:'0 25px 25px 0'}}>
                                            <Card className={classes.root}>
                                                <CardContent className={classes.card}>
                                                    
                                                    <Grid container spacing={1}>
                                                        <Grid item md={5}>
                                                            <Chart
                                                                height={200}
                                                                width={200}
                                                                options={chartOptions}
                                                                series={[Math.round(item.free_vouchers / item.total_vouchers * 100)]}
                                                                type="radialBar"
                                                            />
                                                        </Grid>
                                                        <Grid item md={7}>
                                                                <div className='voucherMoneyTitle'>
                                                                    € <span className='voucherMoneySubtitle'>{item.amount}</span>
                                                                </div>
                                                                <div className='voucherTitle'>
                                                                    Amount of free:  <span className="voucherSubtitle">{item.free_vouchers}</span>
                                                                </div>
                                                                <div className='voucherTitle'>
                                                                    Total amount of vouchers: <span className="voucherSubtitle" >{item.total_vouchers}</span>
                                                                </div>    
                                                        </Grid>                                    
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </div>                      
                                )   
                                })
                        )}
                
            </div>
        </div>
    );
};

export default VoucherOverview;
