import { format } from 'date-fns';

export class DateFunctions {
    public collaborationLastDate(date) {
        if (date == null) {
            return 'Geen  Collaboration tot nu toe';
        }
        return format(new Date(date), 'dd-MM-yyyy');
    }

    public genericDate(date) {
        if (date == null) {
            return format(new Date(), 'dd-MM-yyyy');
        }
        return format(new Date(date), 'dd-MM-yyyy');
    }

    public genericDateTime(date) {
        if (date == null) {
            return format(new Date(), 'dd-MM-yyyy HH:mm:ss');
        }
        return format(new Date(date), 'dd-MM-yyyy HH:mm:ss');
    }
}

export default DateFunctions;