import NextButton from "../FormComponents/Button";
import InputSelect from "../FormComponents/inputSelect";
import TextInput from "../FormComponents/textInput";
import { useTranslation } from 'react-i18next';
import DatePickerNative from '../../dateInput'
import { useState } from "react";
import { Alert } from "@mui/material/";

const InfluencerRegistrationFormPartOne = ({values, touched, errors, handleBlur, handleChange, showFirstForm, showSecondForm}) => {
    const { t } = useTranslation();
    const [dateError, setDateError] = useState<boolean>(false)



    const checkBirthdate = (date) => {
        setDateError(false)
        const birthdate = new Date(`${date}`)
        const year = birthdate.getFullYear()
        const month = birthdate.getMonth()
        const day = birthdate.getDate()
        const now = new Date(Date.now())
        if (year < now.getFullYear() - 16 && year <= now.getFullYear()){
            if ( year === now.getFullYear() && month >= now.getMonth() +1 && day >= now.getDate())
            {
                return false
            }
            else {
                return true
            }
        }
    }

    const checkIfValid = () => {
        if (values.email !== '' &&
            values.fullName !== '' &&
            values.country !== '' &&
            values.language !== '' &&
            values.birthdate !== ''
        ) {
            if (checkBirthdate(values.birthdate)){
                showFirstForm(false);
                showSecondForm(true);
                
            } else {
                setDateError(true)
            }
        }
    }

    const handleDateChange = (date) => {
        values.birthdate = date
        
    }

    return (
        <div className="partOne" id="partOne">
            <InputSelect
                error={Boolean(touched.language && errors.language)}
                value={values.language}
                onChange={handleChange}
                label={t('language')}
                name="language"
                options={[
                    {
                        value: 'NL',
                        name: 'Nederlands'
                    },
                    {
                        value: 'DE',
                        name: 'Deutsch'
                    },
                    {
                        value: 'BE',
                        name: 'Vlaams'
                    },
                    {
                        value: 'EN',
                        name: 'English'
                    },
                    {
                        value: 'FR',
                        name: 'Francais'
                    },
                ]}
            />
            <TextInput
                error={Boolean(touched.fullName && errors.fullName)}
                fullWidth
                helperText={touched.fullName && errors.fullName}
                label={t('Fullname')}
                name="fullName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fullName}
                isMultiLine={false}
                isType="text"
            />
            <TextInput
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label={t('Email')}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                isMultiLine={false}
                isType="email"
            />
            <DatePickerNative
                label={t('birthdate')}
                isFullWidth={true}
                value={values.birthdate}
                errorMessage={'Foutmelding'}
                changeDateMessage={'Wijzig datum'}
                dateChange={handleDateChange}
            />
            {dateError ? <Alert severity="error">Je bent helaas te jong om mee te doen.</Alert> : ''}
            <InputSelect
                error={Boolean(touched.country && errors.country)}
                value={values.country}
                onChange={handleChange}
                label={t('country')}
                name="country"
                options={[
                    {
                        value: 'NL',
                        name: 'Nederland'
                    },
                    {
                        value: 'DE',
                        name: 'Deutschland'
                    },
                    {
                        value: 'BE',
                        name: 'Belgie'
                    },
                    {
                        value: 'EN',
                        name: 'United Kingdom'
                    },
                    {
                        value: 'FR',
                        name: 'France'
                    },
                ]}
            />
            <NextButton
                setcolor="primary"
                isDisabled={false}
                isFullwidth={true}
                setSize={"large"}
                setVariant={"contained"}
                textContent={t('continue')}
                action={checkIfValid}
            />
        </div>
    )
}

export default InfluencerRegistrationFormPartOne


