import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const InfluencerRegistrationValidator = () => {
    const { t } = useTranslation();

    const influencerRegistrationValidator = Yup.object().shape({
        email: Yup.string().email().required(t('Required')),
        fullName: Yup.string().required(t('Required')),
        whyworkwithus: Yup.string().required(t('Required')),
        country: Yup.string().required(t('Required')),
        language: Yup.array().ensure().min(1, "Choose a language"),
        street: Yup.string().required(t('Required')),
        streetnumber: Yup.string().required(t('Required')),
        zipcode: Yup.string().required(t('Required')),
        city: Yup.string().required(t('Required')),
        policy: Yup.boolean().oneOf([true], t('Required'))
    })

    return influencerRegistrationValidator

}



export default InfluencerRegistrationValidator 