import 'date-fns';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/lab';
import { useTranslation } from 'react-i18next';


export default function DatePickerNative({label, isFullWidth, value, errorMessage, changeDateMessage, dateChange}) {
  const [selectedDate, handleDateChange] = useState(value);
  const { t } = useTranslation();
  
  useEffect(() => {
      dateChange(selectedDate)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedDate])

  return (
      <div style={{margin:'20px 4px'}}>
        <Grid container spacing={1}>
          <MobileDatePicker
            label={t('dateOfBirth')}
            inputFormat="dd-MM-yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </Grid>
      </div>
  );
}