import {Box} from '@mui/material'
import {Alert} from '@mui/material'

interface Props {
    onCloseAction: any;
    severityType: any;
    textcontent: string;
}

const StatusAlert: React.FC<Props> = ({onCloseAction, severityType, textcontent}) => {
    return (
    <Box>  
        <Alert
            severity={severityType}
            onClose={onCloseAction}
        >
            {textcontent}
        </Alert>
    </Box>
    )
}

export default StatusAlert