import { IconButton, Link, SvgIcon, TableCell, TableRow, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThumbsUp, ThumbsDown, Edit, Slash } from 'react-feather';
import { socialLinkToIcon } from "../../../utils/socialLinkToIcon";

const useStyles = makeStyles(_ => ({
    svgButton: {
        pointerEvents: 'none'
    }
}))

const InfluencerRegistrationsTableRow = ({ registration, onAccept, onDecline, onIgnore, onOpen }) => {
    const classes = useStyles();

    return (
        <TableRow hover key={registration.id}>
            <TableCell>
                {registration.id}
            </TableCell>
            <TableCell>
                <Link
                    variant="subtitle2"
                    color="textPrimary"
                    underline="none"
                    target='_blank'
                    href={registration.socials[0].account_url}>
                    {registration.name}
                </Link>
            </TableCell>
            <TableCell>
                {registration.email}
            </TableCell>
            <TableCell>
                {registration.address ? registration.address.country_iso_code : ''}
            </TableCell>
            <TableCell>
                {registration.socials.map(url => socialLinkToIcon(url.account_url))}
            </TableCell>
            <TableCell>
                {registration.socials[0].followers}
            </TableCell>
            <TableCell>
                {registration.language}
            </TableCell>
            <TableCell>
                <Tooltip title='Accept'>
                    <IconButton onClick={_ => onAccept(registration)} size="large">
                        <SvgIcon fontSize="small" className={classes.svgButton}>
                            <ThumbsUp />
                        </SvgIcon >
                    </IconButton>
                </Tooltip>
                <Tooltip title='Reject'>
                    <IconButton onClick={_ => onDecline(registration)} size="large">
                        <SvgIcon fontSize="small">
                            <ThumbsDown />
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
                <Tooltip title='Ignore'>
                    <IconButton onClick={_ => onIgnore(registration)} size="large">
                        <SvgIcon fontSize="small">
                            <Slash />
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
                <Tooltip title='Profile'>
                    <IconButton onClick={_ => onOpen(registration)} size="large">
                        <SvgIcon fontSize="small">
                            <Edit />
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
}

export default InfluencerRegistrationsTableRow