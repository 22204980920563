import {
    useState,
    useEffect,
} from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from "@mui/styles";
import Page from '../../components/page/Page';
import VoucherHeader from './voucherHeader';
import Vouchers from './voucherComponents/vouchers';

interface ResultsProps {
    title?: string;
    type?: string;
    url?: string
}


const useStyles = makeStyles((theme: any) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: 100
    }
}));

const VoucherIndex = () => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title="Voucher List"
        >
            <Container maxWidth={false}>
                <VoucherHeader title='Vouchers' />
                <Box mt={3}>
                    <Vouchers/>
                </Box>
            </Container>
        </Page>
    );
};

export default VoucherIndex;