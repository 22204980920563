import React, {FC} from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from "@mui/styles";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Button from '@mui/material/Button';

interface ResultsProps {
    title?: string;
    action: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        position: 'relative',
        marginTop:'20px'
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
        backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    }),
    );

const CircleValidation: FC<ResultsProps> = ({ title, action }) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef<number>();

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const handleButtonClick = () => {
        if (!loading) {
            setSuccess(false);
            setLoading(true);
            action()
            timer.current = window.setTimeout(() => {
                setSuccess(true);
                setLoading(false);
            }, 2000);
        }
    };

    return (
    <div className={classes.root}>
        <div className={classes.wrapper}>
        <Button
            variant="contained"
            color="primary"
            className={buttonClassname}
            disabled={loading}
            fullWidth={true}
            onClick={handleButtonClick}
        >
            {title}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    </div>
    );
}

export default CircleValidation
