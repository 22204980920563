import React from 'react';
import type { FC } from 'react';
import Chart from 'react-apexcharts';
import {
    Card,
    CardContent,
    Typography,
    useTheme
} from '@mui/material';

const LineChart: FC = () => {
    const theme = useTheme();

    const chart = {
        options: {
            chart: {
                background: theme.palette.background.paper,
                stacked: false,
                toolbar: {
                    show: false
                },
                zoom: false
            },
            colors: ['#1f87e6', '#ff5c7c'],
            dataLabels: {
                enabled: false
            },
            grid: {
                borderColor: theme.palette.divider,
                yaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'right',
                labels: {
                    colors: theme.palette.text.secondary
                }
            },
            markers: {
                size: 4,
                strokeColors: ['#1f87e6', '#27c6db'],
                strokeWidth: 0,
                shape: 'circle',
                radius: 2,
                hover: {
                    size: undefined,
                    sizeOffset: 2
                }
            },
            stroke: {
                width: 3,
                curve: 'smooth',
                lineCap: 'butt',
                dashArray: [0, 3]
            },
            theme: {
                mode: theme.palette.mode
            },
            tooltip: {
                theme: theme.palette.mode
            },
            xaxis: {
                axisBorder: {
                    color: theme.palette.divider
                },
                axisTicks: {
                    show: true,
                    color: theme.palette.divider
                },
                categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan', '10 Jan', '11 Jan', '12 Jan'],
                labels: {
                    style: {
                        colors: theme.palette.text.secondary
                    }
                }
            },
            yaxis: [
                {
                    axisBorder: {
                        show: true,
                        color: theme.palette.divider
                    },
                    axisTicks: {
                        show: true,
                        color: theme.palette.divider
                    },
                    labels: {
                        style: {
                            colors: theme.palette.text.secondary
                        }
                    }
                },
                {
                    axisTicks: {
                        show: true,
                        color: theme.palette.divider
                    },
                    axisBorder: {
                        show: true,
                        color: theme.palette.divider
                    },
                    labels: {
                        style: {
                            colors: theme.palette.text.secondary
                        }
                    },
                    opposite: true
                }
            ]
        },
        series: [
            {
                name: 'Page Views',
                data: [3350, 1840, 2254, 5780, 9349, 5241, 2770, 2051, 3764, 2385, 5912, 8323]
            },
            {
                name: 'Session Duration',
                data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35]
            }
        ]
    };

    return (
        <Card>
            <CardContent>
                <Typography
                    variant="h4"
                    color="textPrimary"
                >
                    Web Traffic
        </Typography>
                <Chart
                    type="line"
                    height="300"
                    {...chart}
                />
            </CardContent>
        </Card>
    );
};

export default LineChart;
