import { BackendClient } from './_BackendClient'


export class MailJobClient extends BackendClient {
    constructor() {
        super();
        this.apiurl = this.apiurl + '/mail_job'
    }

    public getAllMailJobs(page: number = 1, limit: number = 25, search: string = '', processed: boolean = false) {
        return this.makeRequest('POST', 'all', { page, limit, search, processed })
    }

    public processMailJob(id: number) {
        return this.makeRequest('POST', `process/${id}`)
    }

    public processMailJobs(ids: number[]) {
        return this.makeRequest('POST', `process/by_ids`, { ids });
    }

    public deleteJob(id: number) {
        return this.makeRequest('DELETE', `${id}`);
    }

    public deleteJobs(ids: number[]) {
        return this.makeRequest('DELETE', `by_ids`, { ids });
    }
}
