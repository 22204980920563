import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import '@fortawesome/fontawesome-free/css/all.css';
import NavBarUserMenu from './navbarComponents/navBarUserMenu/NavbarUserMenu';
import { IconButton } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';


interface NavBarProps {
    isAuthenticated: boolean;
    authButtonMethod: any;
    user: any;
    className: any;
    position: any;
    drawerOpen:any;
}

interface NavBarState {
    isOpen: boolean;
}
export default class NavBar extends React.Component<NavBarProps, NavBarState> {
    constructor(props: NavBarProps) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }


    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        // Only show calendar nav item if logged in
        return (
            <AppBar position={this.props.position} className={this.props.className}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={this.props.drawerOpen} style={{color:'rgba(0, 0, 0, 0.54)'}}>
                        <MenuOpenIcon />
                    </IconButton>

                    <img src="https://www.my-jewellery.com/media/logo/default/my-jewellery-logo.png" alt="mjLogo" style={{ width: '85px', margin:'0 auto', marginTop: '5px' }} />

                    <NavBarUserMenu/>
                </Toolbar>
            </AppBar>
        );
    }
}