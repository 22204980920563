import {Box, Button} from '@mui/material'

interface Props {
    setcolor: any;
    isDisabled: any;
    isFullwidth: boolean;
    setSize: any;
    setVariant: any;
    textContent: string;
    action:any
}

const NextButton: React.FC<Props> = ({action, setcolor, isDisabled, isFullwidth, setSize, setVariant, textContent}) => {
    return (
    <Box mt={2}>
        <Button
            color={setcolor}
            disabled={isDisabled}
            fullWidth={isFullwidth}
            size={setSize}
            variant={setVariant}
            onClick={action}
        >
            {textContent}
        </Button>
    </Box>
    )
}

export default NextButton