import { Container, Tab, Tabs } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useQuery } from "react-query";
import Page from "../../components/page/Page";
import { MailJobClient } from "../../utils/axios/MailJobClient";
import Header from "./mailJobHeader";
import Results from "./mailJobsummary";
import { Box, Card, InputAdornment, SvgIcon, TextField } from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    Search as SearchIcon,
} from 'react-feather';
import SkeletonTable from "../../components/skeletonTable/skeletonTable";

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: any) => ({
    root: {},
    bulkOperations: {
        position: 'relative'
    },
    bulkActions: {
        paddingLeft: 4,
        paddingRight: 4,
        marginTop: 6,
        position: 'absolute',
        width: '100%',
        zIndex: 2,
        backgroundColor: theme.palette.background.default
    },
    bulkAction: {
        marginLeft: theme.spacing(2)
    },
    queryField: {
        width: 500
    },
    categoryField: {
        flexBasis: 200
    },
    availabilityField: {
        marginLeft: theme.spacing(2),
        flexBasis: 200
    },
    stockField: {
        marginLeft: theme.spacing(2)
    },
    shippableField: {
        marginLeft: theme.spacing(2)
    },
    imageCell: {
        fontSize: 0,
        width: 68,
        flexBasis: 68,
        flexGrow: 0,
        flexShrink: 0
    },
    image: {
        height: 68,
        width: 68
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'center'
    },
    flexStart: {
        justifyContent: 'flex-start'
    },
    flexEnd: {
        justifyContent: 'flex-end'
    }
}));

const MailIndex= () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [previousPage, setPreviousPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [nextPage, setNextPage] = useState(2);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [items, setItems] = useState([]);
    const [limit, setLimit] = useState(25);
    const [query, setQuery] = useState('');
    const [search, setSearch] = useState('');
    const [isProcessed, setIsProcessed] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [isBusy, setIsBusy] = useState(false);
    const [selection, setSelection] = useState([]);
    const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout>(null);

    const mailJobClient = new MailJobClient();

    const { isLoading, refetch } = useQuery(
        ['getAllMailJobs', currentPage, limit, query, isProcessed],
        () => mailJobClient.getAllMailJobs(currentPage, limit, query, isProcessed),
        {
            onSuccess: data => {
                setPreviousPage(data.data.RESULTS.previousPage)
                setCurrentPage(data.data.RESULTS.currentPage)
                setNextPage(data.data.RESULTS.nextPage)
                setNumberOfPages(data.data.RESULTS.pages)
                setItems(data.data.RESULTS.items);
            }
        }
    );

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
        setSelection([]);
        if (newValue === 0) setIsProcessed(false);
        else if (newValue === 1) setIsProcessed(true);
    };

    const handleQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);

        // Clear the previous timeout so that the request is only sent when the user is done typing.
        if (currentTimeout !== null) clearTimeout(currentTimeout);

        // Save the timeout handle so we can clear it if the user continues typing.
        const timeout = setTimeout(() => {
            setQuery(event.target.value);
            // Reset the timeout
            setCurrentTimeout(null);
        }, 1000);

        setCurrentTimeout(timeout);
    }

    useEffect(() => {
        refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, limit, query, isProcessed]);

    return (
        <Page className={classes.root} title="Mail overview">
            <Container maxWidth={false}>
                <Header/>
                <Box mt={3}>
                    <Card>
                        <Box p={2}>
                            <Box display="flex" alignItems="center">
                                <TextField
                                    className={classes.queryField}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SvgIcon fontSize="small" color="action">
                                                    <SearchIcon />
                                                </SvgIcon>
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={handleQueryChange}
                                    placeholder="search.."
                                    value={search}
                                    variant="outlined"
                                />
                            </Box>
                        </Box>
                        <Box mt={3}>
                            {isLoading ? (
                                <SkeletonTable columns={6} rows={10} />

                            ) : (
                                <div>
                                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="simple tabs example">
                                        <Tab label="Queue" {...a11yProps(1)} />
                                        <Tab label="Processed" {...a11yProps(2)} />
                                    </Tabs>
                                    <Results
                                        loading={loading}
                                        setLoading={setLoading}
                                        mailJobs={items}
                                        limit={limit}
                                        previousPage={previousPage}
                                        currentPage={currentPage}
                                        nextPage={nextPage}
                                        numberOfPages={numberOfPages}
                                        isBusy={isBusy}
                                        selection={selection}
                                        setSelection={setSelection}
                                        setIsBusy={setIsBusy}
                                        search={setQuery}
                                        setLimit={setLimit}
                                        setPage={setCurrentPage}
                                        refetch={refetch} />
                                </div>
                            )}
                        </Box>
                    </Card>
                </Box>
            </Container>
        </Page>
    )
};

export default MailIndex;
