import BackendClient from "./_BackendClient";

export class TagClient extends BackendClient {
    constructor() {
        super();
        this.apiurl = this.apiurl + '/tag'
    }

    public getTags() {
        return this.makeRequest('GET', 'get_all')
    }

    public deleteTag(id: any){
        return this.makeRequest('DELETE', `${id}`)
    }
}