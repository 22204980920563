import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import {MenuItem, Tooltip, IconButton, Select} from '@mui/material';
import AlertDialog from '../../dialogs/dialog'
import { CollaborationClient } from '../../../utils/axios/CollaborationClient';
import {Utilities} from '../../../utils/axios/utilities'
import toast from 'react-hot-toast';

export default function VoucherMenu({collaboration, buttonText, refreshTable, renewingVoucher, shoppingMoneyList}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const collaborationClient = new CollaborationClient();
  const utils = new Utilities();
  const [shopMoneyList, setShopMoneyList] = useState([])
  const [shoppingMoney, setShoppingMoney] = useState(0);
  const buttontext = buttonText !== '' ? buttonText : ""

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);    
  };

  const generateCode = () => {
      renewingVoucher(true)
      const payload = utils.createRenewShoppingMoneyVoucherPayload(collaboration)
      collaborationClient.renewShoppingMoneyVoucher(payload)
      .then(res => {
        if (res.status === 200){
          toast.success(`New voucher send to: ${collaboration.map(item => {return `${item.influencer.name}, `})}`)
          refreshTable()
          renewingVoucher(false)
        } else {
          toast.error(`Something went wrong, try again or contact development with the following: ${res.status}`)
        renewingVoucher(false)
        }
      })
  }

  const handleChooseNewShoppingMoney = (e) => {
    setShoppingMoney(e.target.value)
  }

  const setNewShoppingMoneyAndGenerateNewCode = () => {
    let UpdateData = {
      collaboration_id: collaboration[0].id,
      shopping_money: shoppingMoney.toString(),
      swipe_up_link: ''
  }
  collaborationClient.updateShoppingMoneyAndSwipeUpLink(UpdateData)
    .then(res => {
      if (res.status === 200){
        generateCode()
    }
    })
  }


  return (
    <div>
      <Tooltip title="Voucher options" aria-label="add">
        {buttonText !== "" ? (
          <Button variant="contained" color="primary" startIcon={<ConfirmationNumberOutlinedIcon/>} onClick={handleClick}>
            {buttonText}
          </Button>
        ) : (
          <IconButton
            style={{transform:'rotate(135deg)'}}
            color="primary"
            onClick={handleClick}
            size="large">
            <ConfirmationNumberOutlinedIcon/>
            {buttontext}
          </IconButton>
        )}
    </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
            <AlertDialog
                title="Change amount and generate new code"
                text={
                    <div>
                        Choose a new amount
                        {collaboration.map(item => {
                              return <span key={item.id} style={{margin:'0 5px'}}><b>{item.influencer.name}</b></span>
                            })
                          }
                        Press Yes to generate new code:
                        <br></br>
                        <Select 
                          variant='outlined'
                          fullWidth
                          onChange={(e) => handleChooseNewShoppingMoney(e)}
                        >
                          {shoppingMoneyList.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        <br></br>
                    </div>                    
                }
                buttonTitle="Change amount and generate new code"
                variant="outlined"
                color="primary"
                actionYes={setNewShoppingMoneyAndGenerateNewCode}
            />
        </MenuItem>
        <MenuItem>
        <AlertDialog
                title="Generate new code"
                text={
                    <div>
                        Hello, You are about to generate a new couponcode for:
                        <br></br>
                        <br></br>
                        <div className='flexColumn'>
                          {collaboration.map(item => {
                              return <span key={item.id}><b>{item.influencer.name}</b></span>
                            })
                          }
                        </div>
                        <br></br>
                        Are you sure?
                    </div>                    
                }
                buttonTitle="Generate new code"
                variant="outlined"
                color="primary"
                actionYes={generateCode}
            /></MenuItem>
      </Menu>
    </div>
  );
}