import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Grid, SvgIcon, Typography } from '@mui/material';
import {
    PlusCircle as PlusCircleIcon,
} from 'react-feather';

interface HeaderProps {
    className?: string;
    title?: string;
    createCampaign: any;
}

const Header: FC<HeaderProps> = ({ className, title, createCampaign, ...rest }) => {

    const buttonTitle = title === 'Campaign' ? 'Create new campaign' : 'Create new campaign'
    const headertitle = title === 'Campaign' ? title : ' Campaign'

    return (
        <Grid
            container
            spacing={3}
            justifyContent="space-between"
            className={clsx(className)}
            {...rest}
        >
            <Grid item>
                <Typography
                    variant="h2"
                    color="textPrimary"
                    className="font40"
                >
                    {headertitle}
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={createCampaign}
                    component="button"
                    startIcon={
                        <SvgIcon fontSize="small">
                            <PlusCircleIcon />
                        </SvgIcon>
                    }
                >
                    {
                        buttonTitle
                    }
                </Button>
            </Grid>
        </Grid>
    );
};

Header.propTypes = {
    className: PropTypes.string
};

export default Header;
