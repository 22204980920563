import withStyles from '@mui/styles/withStyles';
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, TextField } from '@mui/material';
import {PostClient} from '../../utils/axios/PostClient';
import { post } from '../../types/collabData';


interface HeartRatingProps {
    post:any;
    id:number;
    setRating:any;
    setRatingContent:any;
    setSendMail:any;
    sendMail:boolean;
    reason:string;
}

const StyledRating = withStyles({
  iconFilled: {
    color: '#ff6d75',
  },
  iconHover: {
    color: '#ff3d47',
  },
})(Rating);


const labels: { [index: string]: string } = {
    1: 'Bad',
    2: 'Fine',
    3: 'Average',
    4: 'Good',
    5: 'Excellent',
  };

export default function HeartRating(props: HeartRatingProps) {
    let {post, id, setRating, setSendMail, setRatingContent, sendMail, reason} = props; 
    const [value, setValue] = useState<number>(0)
    const [hover, setHover] = useState<number>(post == null ? -1 : post);

    const handleSendMailCheckboxChange = () => {
        setSendMail(!sendMail);
    }
    
    const handleContentInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRatingContent(event.target.value);
    };

    return (
        <div>
            <Box style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <StyledRating
                    name={`heartrating-${id}`}
                    value={post !== null ? post : value}
                    id={`rating-${id}`}
                    style={{margin:'0', padding:'0 !important'}}
                    precision={1}
                    disabled={post == null ? false : true}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                        setRating(newValue);
                        newValue === 1 && setSendMail(true);
                        newValue === null && setSendMail(false);
                    }}
                    onChangeActive={(event, newHover) => {
                        setHover(newHover);
                    }}
                    icon={<FavoriteIcon fontSize="inherit" />}
                />
                <Box className='reviewTitle' ml={1}>{labels[hover !== -1 ? hover : value]}</Box>
            </Box>                   
            <div>
                {value > 0 && (
                    <div>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={sendMail || value === 1} disabled={value === 1} onChange={handleSendMailCheckboxChange} />} label="Stuur een mail" className='ratingCheckbox' />
                        </FormGroup>
                    </div>
                )}
                {sendMail === true || value === 1 ? (
                    <TextField
                        id="ratingContentInput"
                        label="Message to influencer"
                        multiline
                        fullWidth
                        rows={5}
                        onChange={handleContentInput}
                        variant="outlined"
                    />
                ) :
                (null)}
                {reason !== null && (
                    <TextField
                    id="ratingContentInput"
                    label="Message to influencer"
                    multiline
                    fullWidth
                    rows={5}
                    value={reason}
                    variant="outlined"
                    disabled
                    style={{marginTop:'20px'}}
                />
                )}
            </div>
        </div>
    );
}