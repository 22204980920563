import React ,{useState} from 'react';
import {FormControl, InputLabel, Select, Button} from '@mui/material'
import {ShoppingMoneyClient} from '../../../utils/axios/ShoppingMoneyClient'
import CircleValidation from '../../../components/validation/circleValidation'
import toast from 'react-hot-toast';

export default function VouchersAdd({ vouchers }) {
    const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 1048576; // 1mb max

    const [shopMoney, setShopmoney] = useState('')
    const [file, setFile] = useState(null)
    const shoppingMoneyClient = new ShoppingMoneyClient();

    const handleChange = (e) =>
        setShopmoney(e.target.value);
    
    const handleFileUpload = (e) => {
        if (!e.files || e.files.length === 0) {
            return;
        }

        var uploadedFile = e.files[0];
        if (uploadedFile.size > DEFAULT_MAX_FILE_SIZE_IN_BYTES || !new RegExp('.+(.csv)$').test(uploadedFile.name)) {
            toast.error(`Only CSV files of max ${ convertBytesToKB(convertBytesToKB(DEFAULT_MAX_FILE_SIZE_IN_BYTES)) } mb.`)
            return;
        }
        setFile(uploadedFile);
    }
    
    const removeFile = () =>
        setFile(null);
    
    const saveVouchers = () => {
        if (file == null || !shopMoney) {
            toast.error(`Select the amount of shopping money and upload the corresponding file.`)
            return;
        }
        shoppingMoneyClient.sendVouchers(shopMoney, file)
            .then((result) => {
                if (result.status === 200) {
                    if (result.data.success) {
                        toast.success(`${result.data.inserted} voucher(s) successfully added with ${shopMoney} shopping money.`)  
                        setShopmoney('');
                        setFile(null);
                    } else {
                        toast.error(`${result.data.inserted} voucher(s) are not successfully added with ${shopMoney} shopping money, verify the file.`)
                    }
                } else {
                    toast.error("Something went wrong, try again or contact Development")
                }
            })
            .catch(e => {
                toast.error('Something went wrong, try again or contact Development with the following: ' + e)
            });;
    }    

    const convertBytesToKB = (bytes) => Math.round(bytes / 1000);

    return (
        <div>
            <FormControl variant="outlined">
                <InputLabel htmlFor="shopping-money-amount">Kies je aantal Shopping Money</InputLabel>
                <Select
                    native
                    value={shopMoney}
                    onChange={handleChange}
                    style={{ width: 500 }}
                    label="Choose amount of Shopping Money"
                    inputProps={{
                    name: 'shopping-money-amount',
                    id: 'shopping-money-amount',
                    }}
                >
                    <option aria-label="None" value="" />
                    {vouchers.map((item) => {
                        return(
                            <option key={item.amount} value={item.amount}>
                                {item.amount}
                            </option>
                        )   
                        })
                    }
                </Select>
                <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    style={{marginTop:'20px'}} 
                >
                    Bestand uploaden
                    <input
                        type="file"
                        id="csvUpload"
                        name="csvUpload"
                        accept=".csv" 
                        hidden  
                        onChange={event => handleFileUpload(event.target)}
                    />
                </Button>
            </FormControl>

            {
                file != null ?
                    <section className="file-upload-result">
                        <div className="file" >
                            <span>{file.name}</span> - <span>{convertBytesToKB(file.size)} kb</span>
                            <i className="fas fa-trash-alt delete" onClick={() => removeFile()} />
                        </div>
                    </section>
                : ''
            }
                
            {
                file != null && shopMoney ?
                    <CircleValidation title={"Save vouchers"} action={saveVouchers} />
                : ''
            }
            
        </div>
    );
}
