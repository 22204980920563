import { Grid, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(_ => ({
    gridFlex: {
        display: 'flex',
        alignItems: 'center'
    },
    flexEnd: {
        justifyContent: 'flex-end'
    },
}));

interface PaginatedTableProps {
    columns: string[];
    rows: any[];
    page: number;
    setPage: (page: number) => void;
    pageCount: number;
    limit: number;
    setLimit: (limit: number) => void;
    limitOptions: number[];
}

const PaginatedTable: React.FC<PaginatedTableProps> = ({ columns, rows, page, setPage, pageCount, limit, setLimit, limitOptions }) => {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item md={12}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map(c => <TableCell>{c}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows}
                    </TableBody>
                </Table>
            </Grid>
            <Grid container>
                <Grid item md={5}></Grid>
                <Grid item md={3} className={`${classes.gridFlex} ${classes.flexEnd}`}>
                    Rows per page:
                    <Select onChange={e => setLimit(e.target.value as number)} value={limit} style={{ marginLeft: '10px' }} >
                        {limitOptions.map(pl => <MenuItem key={pl} value={pl}>{pl}</MenuItem>)}
                    </Select>
                </Grid>
                <Grid item md={2} className={`${classes.gridFlex} ${classes.flexEnd}`}>
                    Page {page} of {pageCount}
                </Grid>
                <Grid item md={2} className={`${classes.gridFlex} ${classes.flexEnd}`}>
                    <IconButton
                        disabled={page - 1 < 1}
                        aria-label="previous"
                        onClick={_ => setPage(page - 1)}
                        size="large">
                        <ArrowLeft />
                    </IconButton>
                    <IconButton
                        disabled={page + 1 > pageCount}
                        aria-label="next"
                        onClick={_ => setPage(page + 1)}
                        size="large">
                        <ArrowRight />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default PaginatedTable;