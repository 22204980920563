import { FC, useContext } from 'react'
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import {
    TextField,
    Typography,
    Container,
    Box,
    Card,
    CardContent,
    CardActions,
    Tab,
    Tabs,
    ButtonGroup,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { green } from '@mui/material/colors';
import { Campaign } from "../../models/Campaign";
import { useNavigate, useParams } from "react-router-dom";
import StorageIcon from '@mui/icons-material/Storage';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import Page from '../../components/page/Page'
import clsx from 'clsx';
import {CampaignClient} from '../../utils/axios/CampaignClient'
import toast from 'react-hot-toast';
import CreateACampaign from '../../components/createACampaign/createACampaign'
import { Context } from '../../Store/store';

const useStyles = makeStyles({
    buttonsDiv: {
        margin: "auto",
        width: "50%",
    },
    div: {
        padding: 10
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
        backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const CampaignDetail = () => {
    const campaignObject = new Campaign('title', 'publishDate', 'tags', 'shoppingUrl', 'exponea_template_id', 'type', 'country', 'pdf', 'pdf_name');
    const date = new Date().toISOString().slice(0, 10);
    let navigate = useNavigate();
    const campaignClient = new CampaignClient();
    const [state, setState] = useContext(Context);
    
    const [campaign, setCampaign] = useState(campaignObject);
    const [id, setId] = useState(0);
    const [shoppingUrl, setShoppingUrl] = useState('')
    const [title, setTitle] = useState('');
    const [campaignType, setCampaignType] = useState('')
    const [campaignCountry, setCampaignCountry] = useState('')
    const [templateId, setTemplateId] = useState('');
    const [oldTemplateId, setOldTemplateId] = useState('');
    const [publishDate, setPublishDate] = useState(date);
    const [isArchived, setIsArchived] = useState(false);
    const [value, setValue] = React.useState(0);
    const [tags, setTags] = useState('');
    const [fileUpload, setFileUpload] = useState('');
    const [fileName, setFileName] = useState('');
    const classes = useStyles();
    const params = useParams();
    const [savingFile, setSavingFile] = useState(false);
    const [success, setSuccess] = React.useState(false);
    const [sendingTestEmail, setSendingTestEmail] = React.useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleFileSave = () => {
        if (fileUpload){
            setSavingFile(true)
            if (!savingFile) {
            setSuccess(false);
            setSavingFile(true);            
            campaignClient.savePdf(`${id}`, {"name":fileName, "base64":fileUpload} )
                .then(res => {
                    if (res.status === 200) {
                        setSavingFile(false)
                        toast.success('Pdf Saved')
                    } else {
                        toast.error('Something went wrong, try again or contact Development')
                        setSavingFile(false)
                    }
                }).catch(err => {
                    toast.error('Something went wrong, try again or contact Development')
                    setSavingFile(false)
                })
            }
        } else {
            toast.error('No file loaded')
        }
    }

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    const handleFileUpload = (event) => {
        let selectedFile = event.files;
        let file = null;
        let fileName = "";
        if (selectedFile.length > 0) {
            let fileToLoad = selectedFile[0];
            if (fileToLoad.size > 1000000){
                toast.error('This file is to big to be loaded, Max 1MB')
                return
            }
            if (fileToLoad.name === '' || fileToLoad.name === null || typeof(fileToLoad.name) === 'undefined'){
                toast.error(`This file does not have a valid name, please check your file and try again`)
                return
            }
            fileName = fileToLoad.name;
            let fileReader = new FileReader();
            fileReader.onload = function(fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                file = file.replace('data:application/pdf;base64,','')
                setFileName(fileName)
                setFileUpload(file)
            };
            fileReader.readAsDataURL(fileToLoad);
        }
    }

    useEffect(() => {
        if (params.id !== '0') {
            GetCampaign(params.id);
            setId(params.id as unknown as number)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classes]);

    function GetCampaign(campaignId: string) {
        campaignClient.getCampaign(`${campaignId}`)
            .then(res => {
                setCampaignState(res.data.RESULTS)
                setFileUpload(res.data.RESULTS.pdf)
            })
            .catch(err => {
                console.log(err);
                Error("Error occured: " + err)
            })
    }

    function setCampaignState(newCampaign: any) {
        if (newCampaign.id) {
            setId(newCampaign.id)
        }
        setTitle(newCampaign.title)
        setPublishDate(newCampaign.publish_date)
        setTags(newCampaign.tags)
        setShoppingUrl(newCampaign.shopping_url)
        setTemplateId(newCampaign.exponea_template_id)
        setOldTemplateId(newCampaign.exponea_template_id)
        setIsArchived(newCampaign.is_archived)
        setCampaignType(newCampaign.campaign_type)
        setCampaignCountry(newCampaign.campaign_country)
    }

    function createCampaignObject() {
        campaign.title = title
        campaign.publish_date = publishDate
        campaign.tags = tags
        campaign.shopping_url = shoppingUrl
        campaign.exponea_template_id = templateId
    }

    function goToColleborations() {
        navigate(`/admin/collaboration/${id}`);
    }

    function goToCampaigns() {
        navigate("/admin/campaigns");
    }

    function confirmArchiveAction() {
        setShowDeleteConfirmation(true);
    }

    function archiveCampaign() {
        setShowDeleteConfirmation(false);
        campaignClient.archive(id, !isArchived)
            .then(res => {
                if (res.status === 200) {
                    setIsArchived(!isArchived);
                    let action = !isArchived ? 'archived' : 'activated';
                    toast.success(`Campaign ${action} successfully`)
                } else {
                    toast.error('Something went wrong, try again or contact Development')
                }
            });
    }

    function handlePreviewMail(){
        if (templateId === oldTemplateId){
            setSendingTestEmail(true)
            campaignClient.previewEmail({'campaign_id': id })
            .then(res => {
                if(res.data.success === true){
                    toast.success(`Preview email send to: ${state.auth.mail}`)
                    setSendingTestEmail(false)
                } else {
                    toast.error('Something went wrong, try again or contact Development')
                }
            })
        } else {
            toast.error(`You haven't saved the campaign, this results in a wrong email being send, save the campaign to save the template`)
        }
    }

    function postCampaign() {
        if (templateId){
            setIsSaving(true)
            createCampaignObject()
            campaignClient.postCampaign(id, campaign)
            .then(res => {
                setCampaignState(res.data.RESULTS)
                setIsSaving(false)
                toast.success('Campaign saved')
            })
            .catch(err => {
                toast.success('Error Occured' + err)
            });
        } else {
            toast.error('No templateId entered, Enter a template Id')
        }

    }

    return (
        <div>
            {id === 0 ? (
                <>
                    <CreateACampaign/>
                </>
            ) : (
                <>
                    <Page
                        title={`Edit Campaign` }
                    >
                        <Box mt={3}>
                            <Container maxWidth='lg'>
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item md={4}>
                                                <Typography variant='h4' align='left'>
                                                    Edit Campaign: {title}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={4}>

                                            </Grid>
                                            <Grid item md={4}>
                                                <ButtonGroup size="large" variant="outlined" color="primary" aria-label="contained primary button group" style={{display:'flex', flexDirection:'row', justifyContent: 'flex-end'}}>
                                                    <div className="posRelative">
                                                    <Button variant="contained" color="primary" disabled={isSaving} onClick={() => { postCampaign() }}>Save</Button>
                                                    {isSaving && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                    </div>
                                                    <div>
                                                    <Button variant="outlined" onClick={() => { goToCampaigns() }} color="primary">Cancel</Button>
                                                    {id === 0  ? '':
                                                    <Button variant="outlined" onClick={_ => confirmArchiveAction()} color="primary">{isArchived ? 'Activate' : 'Archive'}</Button>
                                                    }
                                                    </div>
                                                </ButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Container>
                        </Box>
                        <Box mt={3}>
                            <Container maxWidth='lg'>
                                <Card>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        variant="fullWidth"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        aria-label="icon label tabs example"
                                    >
                                        <Tab icon={<StorageIcon />} label="Information" />
                                        {/* TODO: Enable this when influencers are going to work with dates */}
                                        {/* <Tab icon={<DateRangeIcon />} label="Data" /> */}
                                        <Tab icon={<AllInboxIcon />} label="Email" />

                                    </Tabs>
                                    <TabPanel value={value} index={0}>
                                        <Grid
                                            container
                                            spacing={3}
                                        >
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    id="title"
                                                    label="Campaign title"
                                                    disabled
                                                    helperText=""
                                                    variant="outlined"
                                                    size="small"
                                                    value={title}
                                                    fullWidth
                                                    onChange={event => {
                                                        setTitle(
                                                            event.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    id="type"
                                                    label="Campaign type"
                                                    disabled
                                                    helperText=""
                                                    variant="outlined"
                                                    size="small"
                                                    value={campaignType}
                                                    fullWidth
                                                    onChange={event => {
                                                        setCampaignType(
                                                            event.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    id="country"
                                                    label="Campaign country"
                                                    disabled
                                                    helperText=""
                                                    variant="outlined"
                                                    size="small"
                                                    value={campaignCountry}
                                                    fullWidth
                                                    onChange={event => {
                                                        setCampaignCountry(
                                                            event.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    id="tags"
                                                    label="Tags"
                                                    variant='outlined'
                                                    size='small'
                                                    fullWidth
                                                    multiline
                                                    value={tags}
                                                    onChange={event => { setTags(event.target.value) }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    id="shoppingUrl"
                                                    label="Shopping URL"
                                                    variant='outlined'
                                                    size='small'
                                                    fullWidth
                                                    multiline
                                                    value={shoppingUrl}
                                                    onChange={event => { setShoppingUrl(event.target.value)}}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    {/* TODO: Enable this when influencers are going to work with dates */}
                                    {/* <TabPanel value={value} index={1}>
                                        <Grid
                                            container
                                            spacing={3}
                                        >
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <TextField
                                                    id="date"
                                                    label="select publish date"
                                                    type="Date"
                                                    variant='outlined'
                                                    size="small"
                                                    fullWidth
                                                    value={publishDate}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={event => setPublishDate(event.target.value)}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <TextField
                                                    id="date"
                                                    label="select start date"
                                                    type="Date"
                                                    variant='outlined'
                                                    size='small'
                                                    fullWidth
                                                    value={startDate}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={event => setStartDate(event.target.value)}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >

                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <TextField
                                                    id="date"
                                                    label="select end date"
                                                    value={endDate}
                                                    type="Date"
                                                    variant='outlined'
                                                    size='small'
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={event => setEndDate(event.target.value)}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                            >
                                                {
                                                    id !== 0 ?
                                                        <Button variant="contained" color="primary" size={"small"}>publiceer</Button>
                                                        : null
                                                }
                                            </Grid>

                                        </Grid>
                                    </TabPanel> */}
                                    <TabPanel value={value} index={1}>
                                    <Grid
                                            container
                                            spacing={3}
                                            >
                                                <Grid item md={6}>
                                                <Card variant="outlined" style={{margin:'0 0 20px 0;'}}>
                                                    <CardContent>
                                                        <Typography color="textSecondary" gutterBottom>
                                                        Template ID
                                                        </Typography>
                                                        <Typography variant="body2" component="span">
                                                        Insert the exponea template id
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions>
                                                    <TextField
                                                        id="templateId"
                                                        label="Template ID"
                                                        helperText=""
                                                        variant="outlined"
                                                        size="small"
                                                        value={templateId}
                                                        fullWidth
                                                        required
                                                        onChange={event => {
                                                            setTemplateId(
                                                                event.target.value)
                                                        }}
                                                    />
                                                    </CardActions>
                                                </Card>
                                                {templateId && id !== 0
                                                    ?
                                                    <Card variant="outlined">
                                                        <CardContent>
                                                            <Typography color="textSecondary" gutterBottom>
                                                            Email preview
                                                            </Typography>
                                                            <Typography variant="body2" component="span">
                                                            Send an acceptation email to yourself
                                                            </Typography>
                                                        </CardContent>
                                                        <CardActions>
                                                            <div className="posRelative">
                                                                <Button variant='contained' color='primary'size="small" disabled={sendingTestEmail} onClick={handlePreviewMail}>Send email</Button>
                                                                {sendingTestEmail && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                            </div>

                                                        </CardActions>
                                                    </Card>
                                                    :
                                                    <Card style={{marginTop:'20px'}}>
                                                        <CardContent>
                                                        When the campaign has been saved you can see acceptance emails here.
                                                        </CardContent>
                                                    </Card>
                                                }
                                                </Grid>
                                                <Grid item md={6}>
                                            <Card variant="outlined">
                                                <CardContent>
                                                <Typography color="textSecondary" gutterBottom>
                                                        PDF File
                                                    </Typography>
                                                    <Typography variant="body2" component="span">
                                                        Choose a pdf file to add to the acceptance email
                                                    </Typography>
                                                    <Grid container spacing={0} style={{margin:'20px 0 20px 0'}}>
                                                        <Grid item md={6}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                component="label"
                                                            >
                                                                Choose File
                                                                <input
                                                                    type="file"
                                                                    id="pdfUpload"
                                                                    name="pdfUpload"
                                                                    accept="application/pdf"
                                                                    hidden
                                                                    onChange={event => handleFileUpload(event.target)}
                                                            />
                                                            </Button>
                                                        </Grid>
                                                        <Grid item md={6} className="flexEnd">
                                                        <div className="posRelative">
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={savingFile}
                                                                className={buttonClassname}
                                                                onClick={handleFileSave}
                                                                >
                                                                    Save File
                                                            </Button>
                                                            {savingFile && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                        </div>
                                                        </Grid>
                                                    </Grid>{
                                                        fileUpload ?
                                                                    <iframe title="pdftosend" id="previewframe" src={`data:application/pdf;base64,${fileUpload}`} width="100%" height="400" style={{margin:'20px 0 20px 0'}}></iframe>
                                                                    :
                                                                    ""
                                                            }

                                                </CardContent>
                                            </Card>
                                            </Grid>
                                            </Grid>

                                    </TabPanel>
                                    <div className="flexCenter" style={{ padding: '30px 0' }}>
                                        <Button variant="contained" onClick={() => { goToColleborations() }} color="primary">Manage collaborations</Button>
                                    </div>
                                </Card>
                            </Container>
                        </Box>
                    </Page>
                    <Dialog open={showDeleteConfirmation}>
                        <DialogTitle>Confirm action</DialogTitle>
                        <DialogContent>Are you sure you want to { isArchived ? 'activate' : 'archive' } this campaign?</DialogContent>
                        <DialogActions>
                            <Button variant={'contained'} color={'primary'} onClick={_ => archiveCampaign()}>Yes</Button>
                            <Button variant={'outlined'} color={'primary'} onClick={_=> setShowDeleteConfirmation(false)}>No</Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}


        </div>
    )
}
export default CampaignDetail