import { CardContent, Grid, TextField, CardActions, Button, Card, Typography } from "@mui/material";
import { useState } from "react";
import { DateFunctions } from "../../utils/dates/dateClass";
import { Comment } from './influencerCommentsView';
import parse from 'html-react-parser';

import InitialsAvatar from 'react-initials-avatar';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import {
    Clock as ClockIcon,
} from 'react-feather';
import TextEditor from "../textEditor/TextEditor";

export interface CommentViewModel extends Comment {
    editing: boolean;
}

interface CommentViewProps {
    comment: CommentViewModel;
    autoFocus?: boolean;
    onSave: (commentId: number, content: string) => void;
    onDelete?: (commentId: number) => void;
}

const dateFn = new DateFunctions();

const CommentView: React.FC<CommentViewProps> = ({ comment, autoFocus, onSave, onDelete }) => {
    const [content, setContent] = useState(comment.content);
    const [editing, setEditing] = useState(comment.editing);

    const save = () => {
        onSave(comment.id, content);
        setContent(comment.id === 0 ? '' : content);
        setEditing(comment.id === 0);
    };

    const _delete = () => {
        if (onDelete) {
            onDelete(comment.id);
        }
    }

    const cancel = () => {
        setEditing(false);
    };

    const getSubHeader = () => {
        let subHeader = `created at ${dateFn.genericDate(comment.creation_date)}`;
        if (comment.creation_date.getTime() < comment.modified_date.getTime()) {
            subHeader = subHeader + ', updated on  ' + dateFn.genericDate(comment.modified_date);
        }
        return subHeader;
    };

    return (
        <Card>
            <div style={{ padding: '16px' }}>
                <Grid container>
                    {comment.id > 0 ?
                        <Grid item md={1} style={{ maxWidth: '5.333333%' }}>
                            <InitialsAvatar name={comment.commentor.replace('.', ' ').split('@')[0]} />
                        </Grid>
                        : ''
                    }
                    <Grid item md={11}>
                        <Typography variant='h5'>{comment.id > 0 ? 'Comment by ' + comment.commentor : 'new comment'}
                            {comment.id > 0 &&
                                <Typography variant='body1' className={'comment-Card-subtitle'}><ClockIcon className={'clockIcon'} /> {comment.id > 0 ? getSubHeader() : ''}</Typography>
                            }
                        </Typography>
                    </Grid>
                </Grid>

            </div>
            <CardContent>
                {editing
                    ?
                    <>
                        <TextEditor value={content} setValue={setContent}/>
                    </>
                    :
                    <pre>{parse(comment.content)}</pre>
                }
            </CardContent>
            <CardActions className={'comment-Card-actions'}>
                <Button
                    color={'primary'}
                    variant='contained'
                    onClick={_ => editing ? save() : setEditing(true)}
                    disabled={editing && content.length === 0}>
                    {editing ? 'Save' : 'Edit'}
                </Button>
                {editing && comment.id > 0 ?
                    <Button variant='outlined' color={'primary'} onClick={_ => cancel()}>Cancel</Button> : ''
                }
                {comment.id > 0 ?
                    <Button variant='outlined' color={'primary'} onClick={_ => _delete()}> Delete </Button> : ''
                }
            </CardActions>
        </Card>
    );
}

export default CommentView;