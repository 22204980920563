import {BackendClient} from './_BackendClient'


export class CampaignClient extends BackendClient {
    constructor(){
        super();
        this.apiurl = this.apiurl + '/campaign'
    }

    public getAllCampaigns(){
        return this.makeRequest('GET', 'all')
    }

    public getAllArchivedCampaigns() {
        return this.makeRequest('GET', 'all?archived');
    }

    public getCampaign(id:string){
        return this.makeRequest('GET', id )
    }

    public postCampaign(id:any, campaignDataObject:any){
        const methodType = id === 0 ? 'POST' : 'PUT';
        const url = id === 0 ? '' : '' + id;
        return this.makeRequest(methodType, url , campaignDataObject)
    }

    public savePdf(id:string, pdfData:any){
        return this.makeRequest('POST', `add-pdf-to-campaign/${id}`, pdfData)
    }

    public previewEmail(previewEmailDataObject:any){
        return this.makeRequest('POST', 'preview-email-acceptance', previewEmailDataObject)
    }

    public trackCreation(){
        return this.makeRequest('POST', 'track-creation-started')
    }

    public archive(id: number, isArchived: boolean) {
        return this.makeRequest('POST', `archive/${id}`, { is_archived: isArchived})
    }

    public filterByCountry(country: string){
        return this.makeRequest('GET', `filter/${country}`)
    }
}