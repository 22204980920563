import { EnvironmentVariablesProvider } from "../../environment/EnvironmentVariablesProvider";
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormControl, InputLabel, NativeSelect, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { Influencer } from "../../models/Influencer";
import { useParams } from "react-router-dom";


const useStyles = makeStyles({
    buttonsDiv: {
        margin: "auto",
        width: "50%",
    },
    div: {
        padding: 10
    }
});


function InfluencerDetail() {
    // const InfluencerObject = new Influencer(0,'rami',10200,'Nederland','geen')
    const accesToken = window.sessionStorage.getItem("accesToken");
    const environmentVariablesProvider = new EnvironmentVariablesProvider();
    const apiUrl = environmentVariablesProvider.getVariable("api_url");
    // const [influencer, ] = useState(InfluencerObject);
    const [id, setId] = useState(0);
    const [shoppingMoney, setShoppingMoney] = React.useState('');
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setShoppingMoney(event.target.value as string);
    };
    const classes = useStyles();
    const params = useParams()

    useEffect(() => {
        if (params.id !== '0') {
            GetInfluencer(params.id);
            setId(params.id as unknown as number)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classes]);

    function GetInfluencer(InfluencerId: string) {
        fetch(apiUrl + '/Influencer/' + InfluencerId, { headers: { "Authorization": accesToken ? 'Bearer ' + accesToken : "" } })
            .then(res => res.json())
            .then(json => {
                if (json["RESULTS"]) {
                    setInfluencerState(json["RESULTS"])
                }
            }).catch(err => {
                console.log(err);
                Error("Error  occured");
            });
    }

    function setInfluencerState(newInfluencer: any) {
        if (newInfluencer.id) {
            setId(newInfluencer.id)
        }
    }

    function getInfluencer() {
    }

    function postInfluencer() {
        getInfluencer()
        const methodType = id === 0 ? 'POST' : 'PUT';
        const route = id === 0 ? '/Influencer' : '/Influencer/' + id;
        fetch(apiUrl + route, {
            headers: { "Authorization": accesToken ? 'Bearer ' + accesToken : "" },
            method: methodType,
            body: JSON.stringify({ 'Influencer': Influencer })
        }).then(res => {
            return res.json();
        }).then(json => {
            if (json["RESULTS"]) {
                setInfluencerState(json["RESULTS"])
            }
        }).catch(err => {
            console.log(err);
            Error("Error occured");
        });
    }
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.buttonsDiv}>Create Influencers</Typography>
                </Grid>
                <Grid item xs={3} sm={3} />
                <Grid item xs={6} sm={3} >
                    <div className={classes.div}>
                        <TextField
                            id="title"
                            label="Influencer title"
                            helperText=""
                            variant="outlined"
                        />
                    </div>
                    <div className={classes.div}>
                        <TextField
                            id="date"
                            label="select publish date"
                            type="Date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {
                            id !== 0 ?
                                <Button variant="contained" color="primary" size={"small"}>publish</Button>
                                : null
                        }
                    </div>
                    <div className={classes.div}>
                        <TextField
                            id="date"
                            label="select start date"
                            type="Date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div className={classes.div}>
                        <TextField
                            id="date"
                            label="select end date"
                            type="Date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <div className={classes.div}>
                        <TextField
                            id="outlined-error-helper-text"
                            label="Swipe up link"
                            helperText="."
                            variant="outlined"
                        />
                    </div>
                    <div className={classes.div}>
                        <TextField
                            id="outlined-error-helper-text"
                            label="File server link"
                            helperText="."
                            variant="outlined"
                        />
                    </div>
                    <FormControl >
                        <InputLabel htmlFor="demo-customized-select-native">shoppingMoney</InputLabel>
                        <NativeSelect
                            id="demo-customized-select-native"
                            value={shoppingMoney}
                            onChange={handleChange}
                        >
                            <option aria-label="None" value="" />
                            <option value={55}>55</option>
                            <option value={75}>75</option>
                            <option value={100}>100</option>
                            <option value={125}>125</option>
                            <option value={150}>150</option>
                            <option value={175}>175</option>
                            <option value={200}>200</option>
                        </NativeSelect>
                    </FormControl>
                    <div className={classes.div}>
                        <Typography>Tags</Typography>
                    </div>
                </Grid>
                <div className={classes.buttonsDiv}>
                    <Button variant="contained" color="primary">Delete</Button>
                    <Button variant="contained" color="primary">Manage colleborations</Button>
                    <Button variant="contained" color="primary">Cancel</Button>
                    <Button variant="contained" color="primary" onClick={() => { postInfluencer() }}>Save</Button>
                </div>
            </Grid>
        </div>
    )
}
export default InfluencerDetail