import {
    AppBar,
    Box,
    Button,
    Card,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    SvgIcon,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../components/page/Page"
import Header from "./influencerRegistrationsViewHeader"
import InfluencerRegistrationsTable from "./table/influencerRegistrationsTable";
import { InfluencerClient } from '../../utils/axios/InfluencerClient';
import { ArrowLeft, ArrowRight, Search } from "react-feather";
import { useCallback, useEffect, useState } from "react";
import { Skeleton } from '@mui/material';
import SkeletonTable from "../../components/skeletonTable/skeletonTable";

const useStyles = makeStyles((theme: any) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: 100
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'center'
    },
    flexEnd: {
        justifyContent: 'flex-end'
    },
    queryField: {
        width: 500
    },
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}



const pageLimits = [5, 10, 25, 50, 100];

const InfluencerRegistrationsViewIndex = () => {
    const classes = useStyles();

    const [status, setStatus] = useState(1)
    const [limit, setLimit] = useState(50);
    const [term, setTerm] = useState('');
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [checkDate, setCheckDate] = useState(false);
    const [country, setCountry] = useState('null');
    const [tags, setTags] = useState([])
    const [registrations, setRegistrations] = useState([]);
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(true);

    const influencerClient = new InfluencerClient();

    const getRegistrations = useCallback(async (status, limit, term, page, checkDate, tags, country) => {
        try {
            influencerClient.getAllInfluencers(status, limit, term, page, checkDate, tags, country)
                .then(res => {
                    setRegistrations(res.data.RESULTS.influencers);
                    setPageCount(res.data.RESULTS.pages);
                    setLoading(false)
                })
        } catch (err) {
            console.error(err);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, limit, term, page, checkDate, tags, country]);

    const getRegs = () => {
        getRegistrations(1, limit, term, page, checkDate, tags, country)
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        switch (newValue) {
            case 0:
                setStatus(1)
                setLoading(true)
                getRegistrations(1, limit, term, page, checkDate, tags, country)
                break;

            case 1: 
                setStatus(3)
                setLoading(true)
                getRegistrations(3,limit, term, page, checkDate, tags, country)
                break;

            case 2: 
                setStatus(4)
                setLoading(true)
                getRegistrations(4, limit, term, page, checkDate, tags, country)
                break;

            default:
                break;
        }
        setValue(newValue);
      };

    useEffect(() => {
        getRegistrations(status, limit, term, page, checkDate, tags, country);
    }, [getRegistrations, status, limit, term, page, checkDate, tags, country])

    return (
        <Page className={classes.root} title="Influencer List">
            <Container maxWidth={false}>
                <Header />
                <Box mt={3}>
                    {/* Search field */}
                    <Card>
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="New registrations" {...a11yProps(1)} />
                            <Tab label="Rejected registrations" {...a11yProps(2)} />
                            <Tab label="Ignored registrations" {...a11yProps(3)} />
                        </Tabs>
                        <Box p={2}>                        
                            <Grid container spacing={0}>
                                <Grid item md={8}>
                                    <TextField
                                        className={classes.queryField}
                                        onChange={e => setTerm(e.target.value)}
                                        placeholder="Search.."
                                        value={term}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">
                                                                <SvgIcon fontSize="small" color="action">
                                                                    <Search />
                                                                </SvgIcon>
                                                            </InputAdornment>
                                            )
                                        }}                                        
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        {/* Table */}
                        {!loading ? (
                            <>
                                <InfluencerRegistrationsTable
                                registrations={registrations}
                                setRegistrations={setRegistrations}
                                influencerClient={influencerClient}
                                getRegistrations={getRegs} />
                            {/* Table controls */}
                            <Grid container>
                                <Grid item md={5}></Grid>
                                <Grid item md={3} className={`${classes.gridFlex} ${classes.flexEnd}`}>
                                    Rows per page:
                                    <Select onChange={e => setLimit(e.target.value as number)}
                                        value={limit}
                                        style={{ marginLeft: '10px' }} >
                                        {pageLimits.map(pl => <MenuItem key={pl} value={pl}>{pl}</MenuItem>)}
                                    </Select>
                                </Grid>
                                <Grid item md={2} className={`${classes.gridFlex} ${classes.flexEnd}`}>
                                    Page {page} of {pageCount}
                                </Grid>
                                <Grid item md={2} className={`${classes.gridFlex} ${classes.flexEnd}`}>
                                    <IconButton
                                        disabled={page - 1 < 1}
                                        aria-label="previous"
                                        onClick={_ => setPage(page - 1)}
                                        size="large">
                                        <ArrowLeft />
                                    </IconButton>
                                    <IconButton
                                        disabled={page + 1 > pageCount}
                                        aria-label="next"
                                        onClick={_ => setPage(page + 1)}
                                        size="large">
                                        <ArrowRight />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            </>
                        ) : (
                            <SkeletonTable columns={8} rows={10}/>
                        )
                        }

                    </Card>
                </Box>
            </Container>
        </Page>
    );
}

export default InfluencerRegistrationsViewIndex;