import { LogLevel } from "@azure/msal-browser";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

export const msalConfig = {
    auth: {
        clientId: 'a74a2978-ddf0-46f1-97fe-be44dcb92bf6',
        authority: 'https://login.microsoftonline.com/6c8ca006-4f17-48d3-acaa-62a7a1b50aff',
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
            if (containsPii) {	
                return;	
            }
            switch (level) {	
                case LogLevel.Error:	
                    console.error(message);	
                    return;	
                case LogLevel.Info:	
                    console.info(message);	
                    return;	
                case LogLevel.Verbose:	
                    console.debug(message);	
                    return;	
                case LogLevel.Warning:	
                    console.warn(message);	
                    return;	
                default:
                    return;
            }
        }
    }
};

export const loginRequest = {
    scopes: ["User.Read"]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
}
