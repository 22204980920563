import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Box,
    Button,
} from "@mui/material"
import { useState } from "react";
import toast from "react-hot-toast";
import GenericDialog from "../../../components/dialogs/genericDialog";
import InfluencerProfileIndex from "../../../components/influencerProfile/influencerProfileIndex";
import { Registration } from "../../../types/influencer";
import InfluencerRegistrationsTableRow from "./InfluencerRegistrationsTableRow";

const columns = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Name' },
    { field: 'email', headerName: 'E-mail' },
    { field: 'country', headerName: 'Country' },
    { field: 'socials', headerName: 'Socials' },
    { field: 'followers', headerName: 'Followers' },
    { field: 'language', headerName: 'Language' },
    { field: 'actions', headerName: 'Actions' },
]

const InfluencerRegistrationsTable = ({ registrations, setRegistrations, influencerClient, getRegistrations }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRegistration, setSelectedRegistration] = useState<Registration>(null);

    const updateRegistrations = reg => setRegistrations(registrations.filter(r => r.id !== reg.id));

    const handleResponse = (res, reg, msg) => {
        if (res.status === 200) {
            updateRegistrations(reg)
            toast.success(msg)
        } else {
            handleError(res.status);
        }
    };

    const handleError = err => {
        toast.error("Something went wrong, try again or contact Development")
    };

    const accept = (registration: Registration) => {
        influencerClient.acceptRegistration(registration.id)
            .then(res => handleResponse(res, registration, `Registration accepted!`))
            .catch(err => handleError(err));
    }

    const decline = (registration: Registration) => {
        influencerClient.declineRegistration(registration.id)
            .then(res => handleResponse(res, registration, `Registration rejected!`))
            .catch(err => handleError(err));
    }

    const ignore = (registration: Registration) => {
        influencerClient.ignoreRegistration(registration.id)
        .then(res => handleResponse(res, registration, 'Registration ignored'))
        .catch(err => handleError(err));
    }

    const open = reg => {
        setSelectedRegistration(reg);
        setOpenDialog(true);
    };

    return (
        <Box minWidth={1200}>
            <Table>
                <TableHead>
                    <TableRow key={JSON.stringify(columns)}>
                        {columns.map(col => <TableCell key={col.field} id={col.field}>{col.headerName}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {registrations.map(reg =>
                        <InfluencerRegistrationsTableRow key={reg.id} registration={reg} onAccept={accept} onDecline={decline} onIgnore={ignore} onOpen={open} />
                    )}
                </TableBody>
            </Table>
            <GenericDialog
                content={<InfluencerProfileIndex id={`${selectedRegistration?.id}`} title={"Influencer Registration"} openDialog={setOpenDialog} getRegistrations={getRegistrations} />}
                isOpen={openDialog}
                onClose={_ => {
                    setOpenDialog(false)
                }}
                buttons={[
                    <Button
                        key={'dialogCloseButton'}
                        autoFocus
                        color="primary"
                        variant="contained"
                        onClick={_ => { setOpenDialog(false); setSelectedRegistration(null) }}>
                        Sluiten
                    </Button>
                ]}
            />
        </Box>
    )
}

export default InfluencerRegistrationsTable
