import React, { useContext, useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from "@mui/styles";
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import { Typography, Box } from '@mui/material/';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon'
import InstagramIcon from '@mui/icons-material/Instagram';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MailIcon from '@mui/icons-material/Mail';
import SettingsIcon from '@mui/icons-material/Settings';
import { NavLink as RouterNavLink } from 'react-router-dom';
import WorkIcon from '@mui/icons-material/Work';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import NavBar from '../../components/navbar/NavBar';
import Login from '../../views/login/login'
import { TagClient } from '../../utils/axios/TagsClient';
import { Context } from '../../Store/store';
import clsx from 'clsx';
import { SettingsClient } from '../../utils/axios/SettingsClient';

interface Sideprops {
    isAuthenticated: boolean;
    authButtonMethod: any;
    user: any;
    children: React.ReactNode;
}

const tagClient = new TagClient();
const drawerWidth = 210;
const settingsClient = new SettingsClient()
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            marginTop: '65px'
        },
        appBar: {
            boxShadow: 'none',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            backgroundColor: '#ffffff',
        },
        drawerPaper: {
            width: drawerWidth,
            flexShrink: 0,
            top: '65px'
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            backgroundColor: '#fafafa',
            padding: theme.spacing(3),
            marginLeft: -drawerWidth,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
          },
    }),
);

export default function Sidebar(props: Sideprops) {
    const classes = useStyles();
    
    const [state, setState] = useContext(Context);
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(!open);
      };
 

    if (props.isAuthenticated) {
        return (
            <div className={classes.root}>
                <CssBaseline />
                <NavBar
                    position="fixed"
                    className={classes.appBar}
                    isAuthenticated={props.isAuthenticated}
                    authButtonMethod={props.authButtonMethod}
                    user={props.user}
                    drawerOpen={handleDrawerOpen}
                />

                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    anchor="left"
                >
                    <Box style={{ margin: '30px auto 30px auto' }}>
                        <ListItem button key='Home' component={RouterNavLink} className="flexColumn" to="/admin">
                            <AccountCircleIcon style={{ width: '80px', height: '80px', color: 'rgba(0, 0, 0, 0.12)' }} />
                            <Typography variant="subtitle2" className="textCenter">
                                Logged in as <br></br>{state.auth ? state.auth.displayName : 'No data stored'}
                            </Typography>
                        </ListItem>
                    </Box>
                    <Divider />
                    <List>
                        <ListItem button key='Campaigns' component={RouterNavLink} to="/admin/Campaigns">
                            <ListItemIcon><WorkIcon /></ListItemIcon>
                            <ListItemText primary='Campaigns' />
                        </ListItem>

                        <ListItem button key='Vouchers' component={RouterNavLink} to="/admin/vouchers">
                            <ListItemIcon><LoyaltyIcon /></ListItemIcon>
                            <ListItemText primary='Vouchers' />
                        </ListItem>

                        <ListItem button key='Influencers' component={RouterNavLink} to="/admin/influencers">
                            <ListItemIcon><InstagramIcon /></ListItemIcon>
                            <ListItemText primary='Influencers' />
                        </ListItem>

                        <ListItem button key='Influencer Registration' component={RouterNavLink} to="/admin/influencer-registration-overview">
                            <ListItemIcon><PersonAddIcon /></ListItemIcon>
                            <ListItemText primary='Influencer Registration' />
                        </ListItem>

                        <ListItem button key='Mail Overview' component={RouterNavLink} to="/admin/mails">
                            <ListItemIcon><MailIcon /></ListItemIcon>
                            <ListItemText primary='Mail Overview' />
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                    <ListItem button key='Settings' component={RouterNavLink} to="/admin/settings">
                            <ListItemIcon><SettingsIcon /></ListItemIcon>
                            <ListItemText primary='Settings' />
                        </ListItem>
                    </List>
                </Drawer>
                <main className={clsx(classes.content, {
                                    [classes.contentShift]: open,
                                    })}>
                    <div className={classes.toolbar} />
                    {props.children}
                </main>
            </div >
        );
    } else {
        return (
            <Login/>
        )
    }
}

