const influencerRegistrationInitialValues = {  
    email: '',
    accountName: '',
    fullName: '',
    birthdate: '01/01/1990',
    age: '',
    country: '',
    language: 'NL',
    street: '',
    streetnumber: '',
    zipcode: '',
    city: '',
    whyworkwithus: '',
    instagram: '',
    tiktok: '',
    youtube: '',
    instagramFollowers: '',
    tiktokFollowers: '',
    youtubeFollowers: '',
    policy: false,
    submit: null
}

export default influencerRegistrationInitialValues