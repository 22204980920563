/* eslint-disable react-hooks/exhaustive-deps */
import {
    useState,
    useEffect,
} from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import Page from '../../components/page/Page';
import type { Registration } from '../../types/influencer';
import Header from './influencerProfileHeader';
import Results from './influencerProfileResults';
import {InfluencerClient} from '../../utils/axios/InfluencerClient'

interface ResultsProps {
    title?: string;
    type?: string;
    id?: string;
    openDialog: any;
    getRegistrations:any;
}




const InfluencerProfileIndex: FC<ResultsProps> = ({ title, id, openDialog, getRegistrations }) => {
    const [influencers, setInfluencers] = useState<Registration[]>([]);
    const influencerClient = new InfluencerClient();

    const closeDialog = () => {
        openDialog(false)
    }

    useEffect(() => {
        try {
            influencerClient.getRegistration(id)
            .then(res => {
                setInfluencers(res.data.RESULTS);
            })
        } catch (err) {
            console.error(err);
        }
    }, []);

    return (
        <Page
            title="Influencer List"
        >
            <Container maxWidth={false}>
                <Header title={title} />
                {influencers && (
                    <Box mt={3}>
                        <Results registration={influencers}  openDialog={closeDialog} getRegistrations={getRegistrations}/>
                    </Box>
                )}
            </Container>
        </Page>
    );
};



export default InfluencerProfileIndex;
