import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authentication/authConfig";
import {Button} from '@mui/material'
import {ReactComponent as MyLogoWhite} from '../../assets/svgs/myLogoWhite.svg'

function handleLoginPopup(instance: { loginPopup: (arg0: { scopes: string[]; }) => Promise<any>; }) {
    instance.loginPopup(loginRequest).catch(e => {
        console.error(e);
    });
}

function handleLoginRedirect(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <Button variant="contained" fullWidth className='MJButton' color='primary' startIcon={<MyLogoWhite style={{width:'45px', height:'45px'}} />} sx={{margin:'20px 0', padding:'0'}} onClick={() => handleLoginRedirect(instance)}>
            My Jewellery Account
        </Button>
    );
}