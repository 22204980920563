import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
} from '@mui/material';

interface HeaderProps {
    className?: string;
    title?: string;
}

const Header: FC<HeaderProps> = ({ className, title, ...rest }) => {
    return (
        <Grid
            container
            spacing={3}
            justifyContent="space-between"
            className="influencerProfileHeader"
            {...rest}
        >
            <Grid item>
                <Typography
                    variant="h2"
                    color="textPrimary"
                    className="font40 Capitalize"
                >
                    {title}
                </Typography>
            </Grid>
        </Grid>
    );
};

Header.propTypes = {
    className: PropTypes.string
};

export default Header;
