import { useEffect, useState } from "react";
import type { FC, ChangeEvent } from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  InputAdornment,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  CircularProgress,
  IconButton,
  Tooltip,
  FormControlLabel,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Tabs,
  Tab,
} from "@mui/material";
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  Instagram as Insta,
} from "react-feather";
import StatusChangeDialog from "../../components/dialogs/statusChangeDialog";
import type { collabData, CollabStatus } from "../../types/collabData";
import Label from "../../components/label/Label";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import InfluencerCampaignEdit from "../../components/modals/campaignInfuencerEdit";
import { CollaborationClient } from "../../utils/axios/CollaborationClient";
import VoucherMenu from "../../components/menus/vouchermenu/vouchermenu";
import DateFunctions from "../../utils/dates/dateClass";
import "moment/min/locales";
import { Skeleton } from '@mui/material';
import { useConfirm } from "material-ui-confirm";
import { ShoppingMoneyClient } from "../../utils/axios/ShoppingMoneyClient";
import { InfluencerClient } from "../../utils/axios/InfluencerClient";
import GenericDialog from "../../components/dialogs/genericDialog";
import InstaPostReviewIndex from "../../components/instaPostReview/instaPostReviewIndex";
import toast from "react-hot-toast";


const dateFunctions = new DateFunctions();

interface ResultsProps {
  className?: string;
  collabData: collabData[];
  getCollaborations: any;
}

interface Filters {
  status?: any;
  contact?: string;
  updateDate?: any;
  shoppingMoney?: string;
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const getStatusLabel = (collabStatus: CollabStatus): JSX.Element => {
  const map = {
    "1": {
      text: "Invited",
      color: "primary",
      numberito: "1",
    },
    "2": {
      text: "Shopping Money Reminder send",
      color: "warning",
      numberito: "2",
    },
    "3": {
      text: "Shopping Money used",
      color: "success",
      numberito: "3",
    },
    "4": {
      text: "Swipe up link send",
      color: "success",
      numberito: "4",
    },
    "5": {
      text: "Reminder to post send",
      color: "warning",
      numberito: "5",
    },
    "6": {
      text: "Post rejected",
      color: "error",
      numberito: "6",
    },
    "7": {
      text: "Has posted",
      color: "success",
      numberito: "7",
    },
    "8": {
      text: "Deleted",
      color: "error",
      numberito: "8",
    },
    "9": {
      text: "Finished",
      color: "success",
      numberito: "9",
    },
  };

  const { text, color }: any = map[collabStatus];
  return (
    <Label color={color} style={{ cursor: "pointer" }} className='statusLabel'>
      {text}
    </Label>
  );
};

const applyFilters = (
  collabData: collabData[],
  query: string,
  filters: Filters
): collabData[] => {
  return collabData.filter((collabData) => {
    let matches = true;

    if (
      query.toLowerCase() &&
      !collabData.influencer.name.toLowerCase().includes(query.toLowerCase())
    ) {
      matches = false;
    }

    if (filters.status) {
      if (filters.status && collabData.status !== filters.status) {
        matches = false;
      }
    }

    if (filters.contact) {
      if (filters.contact && collabData.contact !== filters.contact) {
        matches = false;
      }
    }

    if (filters.shoppingMoney) {
      if (
        filters.shoppingMoney &&
        collabData.shopping_money_voucher.shopping_money !==
        filters.shoppingMoney
      ) {
        matches = false;
      }
    }

    if (filters.updateDate) {
      if (filters.updateDate && dateFunctions.genericDate(collabData.update_date) !== filters.updateDate) {
        matches = false;
      }
    }

    return matches;
  });
};

const applyPagination = (
  collabData: collabData[],
  page: number,
  limit: number
): collabData[] => {
  return collabData.slice(page * limit, page * limit + limit);
};

const Results: FC<ResultsProps> = ({
  className,
  collabData,
  getCollaborations,
  ...rest
}) => {
  const [showDeletedCollabs, setShowDeletedCollabs] = useState(false);
  const collabDataStatusFilter = [];
  const collabDataStatus = [];
  const collabDataDates = [];
  const collabsMoney = [];
  const collaborationClient = new CollaborationClient();
  const influencerClient = new InfluencerClient();
  const confirm = useConfirm();
  const shoppingMoneyClient = new ShoppingMoneyClient();
  const [shoppingMoneyList, setShoppingMoneyList] = useState([]);
  const [collaborationToBeDeleted, setSelectedForDelete] = useState<collabData[]>([]);
  const [showRemovalDialog, setShowRemovalDialog] = useState(false);
  const [addRemovalComment, setAddRemovalComment] = useState(false);
  const [removalComment, setRemovalComment] = useState<string>(null);

  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    shoppingMoneyClient.getShoppingMoneyList().then((res) => {
      setShoppingMoneyList(res.data.RESULTS);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoadingTable(true);

    if (showDeletedCollabs === true) {
        getCollaborations("8")
        setLoadingTable(false)   
           
    } else {
        getCollaborations()
        setLoadingTable(false)   
    }    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDeletedCollabs])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    switch (newValue) {
        case 0:
            getCollaborations()
            break;

        case 1:
            getCollaborations('8')
            break;

        default:
            break;
    }
    setTabValue(newValue);
};

  const reworkInfluencerTypes = (collabData) => {
    const influencerTypes = [];
    collabData.map((item) => influencerTypes.push(item.status));
    const uniqueInfluencerTypes = Array.from(new Set(influencerTypes));

    uniqueInfluencerTypes.sort();
    uniqueInfluencerTypes.unshift("All");
    uniqueInfluencerTypes.forEach((item, index) => {
      switch (item) {
        case "All":
          collabDataStatusFilter.push({
            id: `${item}`,
            name: `All`,
          });
          break;

        case 1:
          collabDataStatusFilter.push({
            id: `${item}`,
            name: `Invited`,
          });
          break;

        case 2:
          collabDataStatusFilter.push({
            id: `${item}`,
            name: `Shopping Money Reminder send`,
          });
          break;

        case 3:
          collabDataStatusFilter.push({
            id: `${item}`,
            name: `Shopping Money used`,
          });
          break;

        case 4:
          collabDataStatusFilter.push({
            id: `${item}`,
            name: `Swipe up link send`,
          });
          break;

        case 5:
          collabDataStatusFilter.push({
            id: `${item}`,
            name: `Reminder to post send`,
          });
          break;

        case 6:
          collabDataStatusFilter.push({
            id: `${item}`,
            name: `Post rejected`,
          });
          break;

        case 7:
          collabDataStatusFilter.push({
            id: `${item}`,
            name: `Has posted`,
          });
          break;

        case 8:
          collabDataStatusFilter.push({
            id: `${item}`,
            name: `Deleted`,
          });
          break;

        case 9:
          collabDataStatusFilter.push({
            id: `${item}`,
            name: `Finished`,
          });
          break;
      }
    });
  };

  const reworkContactPerson = (collabData) => {
    const influencerContactPersons = [];
    collabData.map((item) => influencerContactPersons.push(item.contact));
    const influencerContactPersonsUnique = Array.from(
      new Set(influencerContactPersons)
    );

    influencerContactPersonsUnique.sort();
    influencerContactPersonsUnique.unshift("All");
    influencerContactPersonsUnique.forEach((item, index) =>
      collabDataStatus.push({
        id: `${item}`,
        name: `${item}`,
      })
    );
  };

  const reworkUpdateDate = (collabData) => {
    const dates = [];
    collabData.map((item) => dates.push(dateFunctions.genericDate(item.update_date)));

    const datesUnique = Array.from(new Set(dates));
    datesUnique.sort();
    datesUnique.unshift("All");
    datesUnique.forEach((item, index) =>
      collabDataDates.push({
        id: `${item}`,
        name: `${item}`,
      })
    );
  };

  const reworkShopMoney = (collabData) => {
    const shopMoney = [];
    collabData.map((item) =>
      shopMoney.push(item.shopping_money_voucher.shopping_money)
    );

    const smoneyUnique = Array.from(new Set(shopMoney));
    smoneyUnique.sort();
    smoneyUnique.unshift("All");
    smoneyUnique.forEach((item, index) =>
      collabsMoney.push({
        id: `${item}`,
        name: `${item}`,
      })
    );
  };

  reworkInfluencerTypes(collabData);
  reworkContactPerson(collabData);
  reworkUpdateDate(collabData);
  reworkShopMoney(collabData);

  const [filters, setFilters] = useState<Filters>({
    status: null,
    contact: null,
    updateDate: null,
    shoppingMoney: null,
  });
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [loadingPost, setLoadingPost] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  const [query, setQuery] = useState<string>("");
  const [openDialog, setOpenDialog] = useState(false);
  const filteredProducts = applyFilters(collabData, query, filters);
  const paginatedProducts = applyPagination(filteredProducts, page, limit);
  const enableBulkOperations = selectedProducts.length > 0;
  const selectedSomeProducts =
    selectedProducts.length > 0 && selectedProducts.length < collabData.length;
  const selectedAllProducts = selectedProducts.length === collabData.length;
  const [isRenewingVoucher, setRenewingVoucher] = useState<boolean>(false);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [collab, setCollab] = useState(null)


  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();

    let value = null;

    if (event.target.value !== "All") {
      value = event.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value,
    }));
  };

  const handleInfluencerIdChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    event.persist();

    let value = null;

    if (event.target.value !== "All") {
      value = event.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      contact: value,
    }));
  };

  const handleUpdateDateFilter = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    event.persist();

    let value = null;

    if (event.target.value !== "All") {
      value = event.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      updateDate: value,
    }));
  };

  const handleShopMoneyFilter = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    event.persist();

    let value = null;

    if (event.target.value !== "All") {
      value = event.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      shoppingMoney: value,
    }));
  };

  const handleSelectAllProducts = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    let collection = [];
    if (event.target.checked) {
      if (selectedProducts.length === 0) {
        paginatedProducts.forEach((item) => {
          if (item.status) {
            collection.push(item);
          }
        });
      } else {
        collection = [];
      }
    }
    setSelectedProducts(collection);
  };

  const handleSelectOneProduct = (
    event: ChangeEvent<HTMLInputElement>,
    influencer: any
  ): void => {
    if (!selectedProducts.some((item) => item.id === influencer.id)) {
      setSelectedProducts((prevSelected) => [...prevSelected, influencer]);
    } else {
      setSelectedProducts((prevSelected) =>
        prevSelected.filter((item) => item.id !== influencer.id)
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const sendPostReminder = () => {
    const Collaborations = selectedProducts;
    setLoadingPost(true);
    collaborationClient.sendReminderToPost(Collaborations).then((res) => {
      setLoadingPost(false);
      getCollaborations();
      if (selectedProducts.length === 1) {
        toast.success(`Reminder send to: ${selectedProducts.forEach(
          (item) => item.influencer.name
        )}`)
      } else {
        toast.success(`Reminder send to: ${selectedProducts.forEach(
          (item) => item.influencer.name
        )}`)
      }
    });
  };

  const openRemoveDialog = (selection) => {
    setShowRemovalDialog(true);
    setSelectedForDelete(selection);
  };

  const closeRemoveDialog = () => {
    setShowRemovalDialog(false);
    setAddRemovalComment(false);
    setSelectedForDelete(null);
  };

  const removeFromCampaign = () => {
    setLoadingTable(true);
    collaborationClient.removeFromCampaign(collaborationToBeDeleted).then((res) => {
      if (res.status === 200) {
        getCollaborations();
        collaborationToBeDeleted.forEach(item => {
          influencerClient.createComment(item.influencer_id , removalComment)
        })
        toast.success(`${collaborationToBeDeleted.length > 1 ? collaborationToBeDeleted.map((item) => {
          return item.influencer.name;
        }) : collaborationToBeDeleted[0].influencer.name} ${collaborationToBeDeleted.length > 1 ? `no` : 'no'} longer participates in this campaign`)
        setLoadingTable(false);
        setSelectedProducts([])
        closeRemoveDialog()
      } else {
        toast.error('Something went wrong, try again or contact Development')
        setLoadingTable(false);
      }
    });
  };

  const socialName = (socialLink) => {
    if (socialLink.includes("instagram")) {
      socialLink = socialLink.replace("https://www.instagram.com/", "");
      socialLink = socialLink.replace("/", "");
    } else if (socialLink.includes("tiktok")) {
      socialLink = socialLink.replace("https://www.tiktok.com/", "");
    }

    return socialLink;
  };

  return (

    <Card className={clsx(className)} {...rest}>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
            <Tab label="Collaborations" {...a11yProps(1)} />
            <Tab label="Deleted / Rejected Collaborations" {...a11yProps(2)} />
        </Tabs>
      <Box p={2}>
        <Box display="flex" alignItems="center">
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Search.."
            value={query}
            variant="outlined"
          />
        </Box>
        <Box mt={3} display="flex" alignItems="center">
          <Box mr={1} sx={{width:'100%'}}>
            <TextField
              label="Status"
              name="status"
              onChange={handleStatusChange}
              select
              fullWidth
              SelectProps={{ native: true }}
              value={filters.status || "All"}
              variant="outlined"
            >
              {collabDataStatusFilter.map((influencerOption) => (
                <option
                  className="Capitalize"
                  key={influencerOption.id}
                  value={influencerOption.id}
                >
                  {influencerOption.name}
                </option>
              ))}
            </TextField>
          </Box>
          <Box mr={1} sx={{width:'100%'}}>
            <TextField
              label="Contact Person"
              name="contact"
              onChange={handleInfluencerIdChange}
              select
              fullWidth
              SelectProps={{ native: true }}
              value={filters.contact || "All"}
              variant="outlined"
            >
              {collabDataStatus.map((avalabilityOption) => (
                <option key={avalabilityOption.id} value={avalabilityOption.id}>
                  {avalabilityOption.name}
                </option>
              ))}
            </TextField>
          </Box>
          <Box mr={1} sx={{width:'100%'}}>
            <TextField
              label="Date last update"
              name="updateDate"
              onChange={handleUpdateDateFilter}
              select
              fullWidth
              SelectProps={{ native: true }}
              value={filters.updateDate || "All"}
              variant="outlined"
            >
              {collabDataDates.map((date) => (
                <option key={date.id} value={date.id}>
                  {date.name}
                </option>
              ))}
            </TextField>
          </Box>
          <Box mr={1} sx={{width:'100%'}}>
            <TextField
              label="Shopping Money"
              name="shoppingMoney"
              onChange={handleShopMoneyFilter}
              select
              fullWidth
              SelectProps={{ native: true }}
              value={filters.shoppingMoney || "All"}
              variant="outlined"
            >
              {collabsMoney.map((ShopMoney) => (
                <option key={ShopMoney.id} value={ShopMoney.id}>
                  {ShopMoney.name}
                </option>
              ))}
            </TextField>
          </Box>
        </Box>
      </Box>
      {enableBulkOperations && (
        <div>
          <div>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item md={2}>
                <Checkbox
                  checked={selectedAllProducts}
                  indeterminate={selectedSomeProducts}
                  onChange={handleSelectAllProducts}
                />
              </Grid>
              <Grid item md={10}>
                <div className="flexEnd f-row alignItemsCenter">
                  <div style={{ margin: "0 10px 0 0 " }}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<DeleteIcon />}
                      onClick={() => openRemoveDialog(selectedProducts)}
                    >
                      Remove from campaign
                    </Button>
                  </div>
                  <div>
                    <VoucherMenu
                      buttonText="Vouchers"
                      renewingVoucher={setRenewingVoucher}
                      refreshTable={getCollaborations}
                      collaboration={selectedProducts}
                      shoppingMoneyList={shoppingMoneyList}
                    />
                  </div>

                  {selectedProducts.every((item) => item.status !== 3) ? (
                    <div className="posRelative" style={{ marginLeft: "10px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="flex"
                        disabled={loadingPost}
                        startIcon={<MailRoundedIcon />}
                        style={{ margin: "0 0 0 auto" }}
                        onClick={sendPostReminder}
                      >
                        Send reminder to post
                      </Button>
                      {loadingPost && (
                        <CircularProgress
                          size={24}
                        />
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      {/* <PerfectScrollbar> */}
        <Box minWidth={1200}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllProducts}
                    indeterminate={selectedSomeProducts}
                    onChange={handleSelectAllProducts}
                  />
                </TableCell>
                <TableCell>Influencer Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Shopping Money</TableCell>
                <TableCell>Social Name</TableCell>
                <TableCell>Contact Person</TableCell>
                <TableCell>Discount code</TableCell>
                <TableCell>Last Update</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedProducts.map((c) => {
                const isProductSelected = selectedProducts.some(
                  (id) => id.id === c.id
                );
                const stati = getStatusLabel(c.status).props.children;
                const disa = stati === "Bigquery" ? true : false;
                return (
                  <TableRow
                    hover
                    key={c.id}
                    selected={isProductSelected}
                    aria-checked={isProductSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        disabled={disa}
                        checked={isProductSelected}
                        onChange={(event) =>
                          handleSelectOneProduct(event, c)
                        }
                        value={isProductSelected}
                      />
                    </TableCell>
                    <TableCell>
                      {loadingTable && <Skeleton variant="text" />}
                      {!loadingTable && (
                        <div>
                          {c.influencer.socials[0].account_id ? (
                            <Link
                              variant="subtitle2"
                              color="textPrimary"
                              underline="none"
                              target="_blank"
                              href={
                                c.influencer.socials[0].account_url
                              }
                            >
                              {c.influencer.name}
                            </Link>
                          ) : (
                            c.influencer.name
                          )}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {loadingTable && <Skeleton variant="text" />}
                      {!loadingTable && (
                        <StatusChangeDialog
                          color="primary"
                          title={c.influencer.name}
                          data={c}
                          showDeleted={showDeletedCollabs}
                          actionYes={getCollaborations}
                          buttonContent={getStatusLabel(c.status)}
                          setLoadingTable={setLoadingTable}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {loadingTable && <Skeleton variant="text" />}
                      {!loadingTable && (
                        <Grid container spacing={0}>
                          <Grid item md={8}>
                            {isRenewingVoucher && (
                              <CircularProgress
                              />
                            )}
                            € {c.shopping_money_voucher.shopping_money}{" "}
                            <br></br>{" "}
                            <b>
                              Code: {c.shopping_money_voucher.voucher}
                            </b>
                          </Grid>
                          <Grid
                            item
                            md={4}
                            className="flexStart alignItemsCenter"
                          >
                            <VoucherMenu
                              buttonText=""
                              renewingVoucher={setRenewingVoucher}
                              refreshTable={getCollaborations}
                              collaboration={[c]}
                              shoppingMoneyList={shoppingMoneyList}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </TableCell>
                    <TableCell>
                      {loadingTable && <Skeleton variant="text" />}
                      {!loadingTable && (
                        <div>
                          {c.influencer.socials[0].account_id ? (
                            <Link
                              variant="subtitle2"
                              color="textPrimary"
                              underline="none"
                              target="_blank"
                              href={
                                c.influencer.socials[0].account_url
                              }
                            >
                              {socialName(
                                c.influencer.socials[0].account_id
                              )}
                            </Link>
                          ) : (
                            c.instagram_name
                          )}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {loadingTable && <Skeleton variant="text" />}
                      {!loadingTable && (
                        <div>{c.contact.split(" ")[0]}</div>
                      )}
                    </TableCell>
                    <TableCell>
                      {loadingTable && <Skeleton variant="text" />}
                      {!loadingTable && (
                        <div>
                          {c.discount_code
                            ? c.discount_code
                            : "Geen discount code"}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {loadingTable && <Skeleton variant="text" />}
                      {!loadingTable && (
                        <div>
                          {c.update_date
                            ? dateFunctions.collaborationLastDate(c.update_date)
                            : dateFunctions.collaborationLastDate(
                                c.creation_date
                              )}
                        </div>
                      )}
                    </TableCell>
                    <TableCell className='actionCell'>
                      <Tooltip title={"View post"}>
                        <span>
                          {c.post.length > 0 ? <span onClick={() => [setOpenDialog(!openDialog), setCollab(c)]}><IconButton size="large"><Insta/></IconButton></span> : null}
                        </span>
                      </Tooltip>
                      
                      <Tooltip title={"Edit collaboration"}>
                        <span>
                            <InfluencerCampaignEdit
                            modalTitle="Edit"
                            getCollaborations={getCollaborations}
                            variant="outlined"
                            contentTitle="Edit influencer settings in collab"
                            collaborationData={c}
                            shoppingMoneyListFromParent={shoppingMoneyList}
                          />
                        </span>
                      </Tooltip>

                      <Tooltip title={"Delete collaboration"}>
                        <span>
                          <IconButton size="large">
                            <DeleteIcon
                              onClick={() => openRemoveDialog([c])}
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={filteredProducts.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </Box>
      {/* </PerfectScrollbar> */}
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={showRemovalDialog}
        onClose={closeRemoveDialog}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Box minWidth={200}>
            <FormControlLabel
              label={"Add Comment"}
              control={<Checkbox onChange={_ => setAddRemovalComment(!addRemovalComment)} checked={addRemovalComment} />} />
            <div hidden={!addRemovalComment}>
              <TextField onChange={e => setRemovalComment(e.target.value)} value={removalComment} fullWidth multiline variant='outlined' rows={4} />
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={removeFromCampaign} variant="contained" color="primary">Yes</Button>
          <Button onClick={closeRemoveDialog} variant="contained" color="primary">No</Button>
        </DialogActions>
      </Dialog>
      <GenericDialog
          isOpen={openDialog}
          onClose={_ => {
            setOpenDialog(false)
            getCollaborations()
          }}
          buttons={[
            <Button
              key={'dialogCloseButton'}
              color="primary"
              variant="contained"
              onClick={_ => {
                setOpenDialog(false)
                getCollaborations()
              }}
            >
                Close
            </Button>
          ]}
          content={collab ? <InstaPostReviewIndex posts={collab.post} collaboration={collab}/> : null}
        />
    </Card>
  );
};

export default Results;