/* eslint-disable no-mixed-operators */
import { Box, Card, CardContent, Container, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import AdornmentInput from "../FormComponents/adornmentTextInput";
import { useTranslation } from "react-i18next";
import DatePickerNative from "../../dateInput";
import NextButton from "../FormComponents/Button";
import InputSelect from "../FormComponents/inputSelect";
import TextInput from "../FormComponents/textInput";
import { InfluencerClient } from "../../../../../utils/axios/InfluencerClient";
import { Utilities } from "../../../../../utils/axios/utilities";
import * as Yup from 'yup';
import DateFunctions from '../../../../../utils/dates/dateClass';
import toast from "react-hot-toast";


const CreateSimplifiedInfluencer = () => {
    return {
        name: '',
        email: '',
        birth_date: null,
        language: 'NL',
        country: 'NL',
        influencer_type: '',
        instagram: '',
        instagramFollowers: '',
        tiktok: '',
        tiktokFollowers: '',
        youtube: '',
        youtubeFollowers: '',
        tags:[],
    }
}

const yupSocialValidatorOpts = {
    name: 'socialsTest',
    message: 'Vul minimaal één van de socials in',
    test: function (value) {
        return this.parent.instagram && this.parent.instagramFollowers ||
            this.parent.tiktok && this.parent.tiktokFollowers ||
            this.parent.youtube && this.parent.youtubeFollowers;
    },
};

const SimplifiedInfluencerRegistrationValidator = () => {
    const { t } = useTranslation();

    const validator = Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().email().required('Required'),
        language: Yup.array().ensure().min(1, 'Choose language'),
        country: Yup.string().required('Required'),
        instagram: Yup.string().test(yupSocialValidatorOpts),
        instagramFollowers: Yup.number().test(yupSocialValidatorOpts),
        tiktok: Yup.string().test(yupSocialValidatorOpts),
        tiktokFollowers: Yup.number().test(yupSocialValidatorOpts),
        youtube: Yup.string().test(yupSocialValidatorOpts),
        youtubeFollowers: Yup.number().test(yupSocialValidatorOpts)
    })

    return validator;
}

const SimplifiedInfluencerRegistrationForm = () => {
    const { t } = useTranslation();

    const languageOptions = [
        {
            value: 'NL',
            name: 'Dutch'
        },
        {
            value: 'DE',
            name: 'German'
        },
        {
            value: 'BE',
            name: 'Flemish'
        },
        {
            value: 'EN',
            name: 'English'
        },
        {
            value: 'FR',
            name: 'French'
        }
    ];

    const countryOptions = [
        {
            value: 'NL',
            name: 'The Netherlands'
        },
        {
            value: 'DE',
            name: 'Germany'
        },
        {
            value: 'BE',
            name: 'Belgium'
        },
        {
            value: 'EN',
            name: 'United Kingdom'
        },
        {
            value: 'FR',
            name: 'France'
        }
    ];

    const utils = new Utilities();

    const influencerClient = new InfluencerClient();

    const form = useFormik({
        initialValues: CreateSimplifiedInfluencer(),
        validationSchema: SimplifiedInfluencerRegistrationValidator(),
        onSubmit: (values, { resetForm }) => {
            influencerClient.createSimplifiedInfluencer({
                influencer: {
                    name: values.name,
                    email: values.email,
                    birth_date: values.birth_date,
                    influencer_type: values.influencer_type,
                    language: values.language,
                    
                },
                socials: utils.createInfluencerCreateSocialsData(values),
                tags: []
            }).then(res => {
                if (res.data.RESULTS.response_code === 11 || res.status === 200) {
                    resetForm()
                    toast.success("Influencer added")

                } else {
                    toast.error("Something went wrong, try again or contact development")
                }
            });
        }
    });

    const handleDateChange = (date) => {
        form.values.birth_date = date
    }

    return (
        <div>
            <Box mt={3}>
                <Container maxWidth={'lg'}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <Typography variant='h4' align='left'>
                                        Create influencer profile
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <TextInput
                                        error={Boolean(form.touched.name && form.errors.name)}
                                        fullWidth
                                        helperText={form.touched.name && form.errors.name}
                                        label={t('Fullname *')}
                                        name="name"
                                        onBlur={form.handleBlur}
                                        onChange={form.handleChange}
                                        value={form.values.name}
                                        isMultiLine={false}
                                        isType="text"
                                    />
                                    <TextInput
                                        error={Boolean(form.touched.email && form.errors.email)}
                                        fullWidth
                                        helperText={form.touched.email && form.errors.email}
                                        label={t('Email *')}
                                        name="email"
                                        onBlur={form.handleBlur}
                                        onChange={form.handleChange}
                                        value={form.values.email}
                                        isMultiLine={false}
                                        isType="email"
                                    />
                                    <DatePickerNative
                                        label={'Birthdate'}
                                        isFullWidth={true}
                                        value={null}
                                        errorMessage={'Foutmelding'}
                                        changeDateMessage={'Wijzig datum'}
                                        dateChange={handleDateChange}
                                    />
                                    <InputSelect
                                        error={Boolean(form.touched.country && form.errors.country)}
                                        value={form.values.country}
                                        onChange={form.handleChange}
                                        label={'Country *'}
                                        name="country"
                                        options={countryOptions}
                                    />
                                    <InputSelect
                                        error={Boolean(form.touched.language && form.errors.language)}
                                        value={form.values.language}
                                        onChange={form.handleChange}
                                        label={'Language *'}
                                        name="language"
                                        options={languageOptions}
                                    />
                                    <TextInput
                                        error={Boolean(form.touched.influencer_type && form.errors.influencer_type)}
                                        fullWidth
                                        helperText={form.touched.influencer_type && form.errors.influencer_type}
                                        label={'Influencer type'}
                                        name="influencer_type"
                                        onBlur={form.handleBlur}
                                        onChange={form.handleChange}
                                        value={form.values.influencer_type}
                                        isMultiLine={false}
                                        isType="text"
                                    />
                                    <Grid container spacing={1}>
                                        <Grid item md={12} sm={12}>
                                            <Box mt={2}>
                                                <Typography variant={'caption'}>
                                                    * It is mandatory that at least 1 social media account is provided.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item md={9} sm={12}>
                                            <AdornmentInput
                                                error={Boolean(form.touched.instagram && form.errors.instagram)}
                                                fullWidth
                                                helperText={form.touched.instagram && form.errors.instagram}
                                                label={t('instagram')}
                                                name="instagram"
                                                Istype='text'
                                                onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                value={form.values.instagram}
                                                isMultiLine={false}
                                            />
                                        </Grid>
                                        <Grid item md={3} sm={12}>
                                            <AdornmentInput
                                                error={Boolean(form.touched.instagramFollowers && form.errors.instagramFollowers)}
                                                fullWidth
                                                helperText={form.touched.instagramFollowers && form.errors.instagramFollowers}
                                                label={'Followers'}
                                                name="instagramFollowers"
                                                Istype='number'
                                                onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                value={form.values.instagramFollowers}
                                                isMultiLine={false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1}>
                                        <Grid item md={9} sm={12}>
                                            <AdornmentInput
                                                error={Boolean(form.touched.tiktok && form.errors.tiktok)}
                                                fullWidth
                                                helperText={form.touched.tiktok && form.errors.tiktok}
                                                label={t('tiktok')}
                                                name="tiktok"
                                                Istype='text'
                                                onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                value={form.values.tiktok}
                                                isMultiLine={false}
                                            />
                                        </Grid>
                                        <Grid item md={3} sm={12}>
                                            <AdornmentInput
                                                error={Boolean(form.touched.tiktokFollowers && form.errors.tiktokFollowers)}
                                                fullWidth
                                                helperText={form.touched.tiktokFollowers && form.errors.tiktokFollowers}
                                                label={t('followers')}
                                                name="tiktokFollowers"
                                                onBlur={form.handleBlur}
                                                Istype='number'
                                                onChange={form.handleChange}
                                                value={form.values.tiktokFollowers}
                                                isMultiLine={false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1}>
                                        <Grid item md={9} sm={12}>
                                            <AdornmentInput
                                                error={Boolean(form.touched.youtube && form.errors.youtube)}
                                                fullWidth
                                                helperText={form.touched.youtube && form.errors.youtube}
                                                label={t('youtube')}
                                                name="youtube"
                                                Istype='text'
                                                onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                value={form.values.youtube}
                                                isMultiLine={false}
                                            />
                                        </Grid>
                                        <Grid item md={3} sm={12}>
                                            <AdornmentInput
                                                error={Boolean(form.touched.youtubeFollowers && form.errors.youtubeFollowers)}
                                                fullWidth
                                                helperText={form.touched.youtubeFollowers && form.errors.youtubeFollowers}
                                                label={t('followers')}
                                                name="youtubeFollowers"
                                                onBlur={form.handleBlur}
                                                Istype='number'
                                                onChange={form.handleChange}
                                                value={form.values.youtubeFollowers}
                                                isMultiLine={false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <NextButton
                                        setcolor="primary"
                                        isDisabled={false}
                                        isFullwidth={true}
                                        setSize={"large"}
                                        setVariant={"contained"}
                                        textContent={t('aanmaken')}
                                        action={form.handleSubmit}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </div>
    );
}

export default SimplifiedInfluencerRegistrationForm;
