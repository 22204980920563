import {
    Box,
    TextField,
    InputAdornment,
    SvgIcon
} from '@mui/material';
import {ReactComponent as Instagram} from '../../../../../assets/svgs/socialmedia/instagram.svg';
import {ReactComponent as Youtube} from '../../../../../assets/svgs/socialmedia/youtube.svg';
import {ReactComponent as TikTok} from '../../../../../assets/svgs/socialmedia/tiktok.svg';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

const TextInput = ({error,helperText,label,fullWidth, name, onBlur, onChange, value, Istype, isMultiLine}) => {    

    return (
        <div>{
            Istype === 'number' ? (
                <Box mt={2}>
                    <TextField
                        error={Boolean(error)}
                        fullWidth={fullWidth}
                        helperText={helperText}
                        label={label}
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        variant="outlined"
                        type='number'
                        multiline={isMultiLine}
                        InputProps={{
                            startAdornment: <InputAdornment position="start" style={{marginRight:0}}>
                            <SvgIcon
                                fontSize="small"
                                color="action"
                            >
                                {   name === "instagram" ? <Instagram/> :
                                    name === 'tiktok' ? <TikTok/> :
                                    name === 'youtube' ? <Youtube />:
                                    <PeopleAltIcon/>}
                            </SvgIcon>
                                {name.includes('Followers') ?  <span style={{marginLeft:'7px'}}></span> : <span style={{marginLeft:'7px', color:'rgb(173 173 173 / 87%)'}}>
                                   {
                                       name === "instagram" ? "https://www.instagram.com/" :
                                           name === "tiktok" ? "https://www.tiktok.com/@" :
                                               name === "youtube" ? "https://www.youtube.com/channel/" : ""
                                   }
                                </span>}
                                
                            </InputAdornment>,
                            inputProps: { min: '0'}
                        }}
                    />
                </Box>
            ) : (
                <Box mt={2}>
                    <TextField
                        error={Boolean(error)}
                        fullWidth={fullWidth}
                        helperText={helperText}
                        label={label}
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        variant="outlined"
                        type={Istype}
                        multiline={isMultiLine}
                        InputProps={{
                            startAdornment: <InputAdornment position="start" style={{marginRight:0}}>
                            <SvgIcon
                                fontSize="small"
                                color="action"
                            >
                                {   name === "instagram" ? <Instagram/> :
                                    name === 'tiktok' ? <TikTok/> :
                                    name === 'youtube' ? <Youtube />:
                                    <PeopleAltIcon/>}
                            </SvgIcon>
                                {name.includes('Followers') ?  <span style={{marginLeft:'7px'}}></span> :
                                    <span style={{marginLeft:'7px', color:'rgb(173 173 173 / 87%)'}}>
                                        {
                                            name === "instagram" ? "https://www.instagram.com/" :
                                            name === "tiktok" ? "https://www.tiktok.com/@" :
                                            name === "youtube" ? "https://www.youtube.com/channel/" : ""
                                        }
                                    </span>
                                }

                            </InputAdornment>,
                        }}
                    />
                </Box>
            )
            }
            
        </div>
    )
}

export default TextInput;