import {Suspense, lazy} from 'react'
import CampaignDetail from '../../views/campaigns/campaignDetail';
import CampaignIndex from '../../views/campaigns/campaignOverview/campaignIndex';
import Dashboard from '../../views/dashboard';
import CollaborationIndex from '../../views/collaborations/collaborationsIndex'
import InfluencerRegistration from '../../views/InfluencerRegistration/InfluencerRegistration';
import InfluencerDetail from "../../views/influencers/influencerDetail";
import InfluencerIndex from '../../views/influencers/influencerIndex';
import InfluencerProfile from '../../views/influencerProfilePage/Index'
import MailIndex from '../../views/mailJobs/mailJobIndex';
import Settings from '../../views/settings/settings';
import Login from '../../views/login/login';
import VoucherIndex from '../../views/vouchers/voucherIndex';
import InfluencerRegistrationsViewIndex from '../../views/influencerRegistrationsOverView/influencerRegistrationsViewIndex';


const routes: any[] = [
    {
        path:'/campaigns',
        element: <CampaignIndex
                    title="Campagnes"
                    url='/admin/Campaigns'
                  />
    },
    {
        path:'/dashboard',
        element: <Dashboard/>
    },
    {
        path:'/campaignDetail/:id',
        element: <CampaignDetail/>
    },
    {
        path:'/collaboration/:id',
        element: <CollaborationIndex/>
    },
    {
        path:'/influencers',
        element: <InfluencerIndex/>
    },
    {
        path:'/influencers/create',
        element: <InfluencerRegistration/>
    },
    {
        path:'/influencerDetail/:id',
        element: <InfluencerDetail/>
    },
    {
        path:'/vouchers',
        element: <VoucherIndex/>
    },
    {
        path:'/influencer-registration-overview',
        element: <InfluencerRegistrationsViewIndex/>
    },
    {
        path:'/influencer-profile/:id',
        element: <InfluencerProfile/>
    },
    {
        path:'/mails',
        element: <MailIndex/>
    },
    {
        path:'/settings',
        element: <Settings/>
    },
    
]

  export default routes;