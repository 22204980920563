import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from "@mui/styles";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { IconButton } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }),
);


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const FullScreenDialog = ({content, className, startIcon, variant, color, buttonTitle, actionSave, typeButton }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (content.props === true) handleClose();

    return (
        <span>
            {typeButton === 'icon' ? (
                <IconButton onClick={handleClickOpen} size="large">
                    {startIcon}
                </IconButton>
                )
                :
                (
                <Button variant={variant} color={color} onClick={handleClickOpen} startIcon={startIcon}>
                    {buttonTitle}
                </Button>
                )
            }
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>

                        </Typography>
                        <Button
                            autoFocus
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                handleClose();
                                actionSave();
                            }}
                            className="language"
                        >
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                {content}
            </Dialog>
        </span>
    );
}

export default FullScreenDialog