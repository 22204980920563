export class EnvironmentVariablesProvider {
    private localVariables = new Map<string, string>([
        ["api_url_local", "http://localhost:5000"],
        ["api_url_dev", "https://my-influencers-api-dev-24lrlyasca-ez.a.run.app"],
        ["api_url_prod", "https://my-influencers-api-prod-24lrlyasca-ez.a.run.app"],
    ])

    public getVariable(key: string): string {
        const keyWithSuffix = this.addSuffix(key);

        return this.localVariables.get(keyWithSuffix) ?? String(process.env[keyWithSuffix])
    }

    private addSuffix(key: string): string {
        const stage = process.env.REACT_APP_STAGE;
        const suffix =
            ["localhost", "127.0.0.1"].includes(window.location.hostname)
                ? "_local"
                : stage === "prod" ? "_prod" : "_dev";
        return key + suffix;
    }
}

