import { useContext, useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import loginbracelet from '../../assets/images/loginbracelet.png'
import loginnew22 from '../../assets/images/loginnew22.jpg'
import {SignInButton} from '../login/signInButton'
import { Context } from '../../Store/store';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import SideBar from '../../layouts/sidebar/sidebar'
import { useRoutes } from 'react-router-dom';
import routes from '../../utils/routing/routes';
import { Toaster } from 'react-hot-toast';
import {
    QueryClient,
    QueryClientProvider,
  } from 'react-query'
import { SettingsClient } from '../../utils/axios/SettingsClient';
import { TagClient } from '../../utils/axios/TagsClient';
import MainLoader from '../../components/loaders/mainLoader';
import { useIsAuthenticated } from "@azure/msal-react";
import { callMsGraph } from '../../authentication/msGraphApiCall';

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
defaultOptions: {
    queries: {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    retry: false,
    staleTime: twentyFourHoursInMs,
    },
},
});

const tagClient = new TagClient();
const settingsClient = new SettingsClient();

const AdminView = () => {
    const [state, setState] = useContext(Context)
    const content = useRoutes(routes)
    const [loading, setLoading] = useState(false);
    const [apiData, setApiData] = useState(null);
    const [settingsData,setSettingsData] = useState(false);
    const isAuthenticated = useIsAuthenticated();


    useEffect(()=>{   
        if (isAuthenticated){
            setLoading(true)
            const initStore = {
                tags:[],
                campaign_types:[],
                campaign_countries:[],
                auth:[],
            }

            callMsGraph().then(res => {
                initStore.auth = res
            })

            tagClient.getTags()
                .then(res =>{
                    if(res){
                        res.data.RESULTS.forEach(item => {
                            initStore.tags = []
                            setSettingsData(true)
                            initStore.tags.push(
                                {
                                    creation_date: item.creation_date,
                                    id: item.id,
                                    modified_date: item.modified_date,
                                    tag: item.tag,
                                    tagger: item.tagger,
                                }
                            )
                        })    
                    }                
            })
        
            settingsClient.get_all_campaign_types().then(res => {
                if(res){
                    initStore.campaign_types = res.data.RESULTS
                    setSettingsData(true)
                }
            })        
    
            settingsClient.get_all_campaign_countries().then(res => {
                if(res){
                    initStore.campaign_countries = res.data.RESULTS
                    setSettingsData(true)
                }
            })
            setState(initStore)
    
            setTimeout(function(){setLoading(false)},1000)
        }   
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isAuthenticated, settingsData ])
    
    if (loading) {
        return <MainLoader/>
    }


    return (
        <>
            <UnauthenticatedTemplate>
                <Grid container style={{maxHeight:'100vh', overflow:'hidden', maxWidth:'100vw', padding:'0 !important'}}>
                        <Grid item md={6} sm={12}>
                            <img src={loginnew22} alt='LoginImage' style={{width:'100%', maxHeight:'100vh', objectFit:'cover'}}/>
                        </Grid>
                        <Grid item md={6} sm={12} style={{flexBasis:'50%', flexGrow:0, maxWidth:'50%', display:'flex', alignItems:'center'}}>
                            <Grid container style={{ boxSizing: 'border-box', flexFlow:'row wrap', width: '100%', display: 'flex', justifyContent: 'center'}}>
                                <Grid item md={9} style={{flexBasis:'50%', flexGrow:0, maxWidth:'50%', boxSizing:'border-box', margin:'0', flexDirection:'row'}}>
                                    <Box p={3}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant='h4' sx={{fontWeight:'700', fontSize:'1.5rem', lineHeight:'1.5'}}>
                                                    Influencer Marketing Tool
                                                </Typography>
                                                <Typography variant='body2' sx={{fontSize:'0.875rem', fontWeight:'500'}}>
                                                    Login with your My Jewellery account
                                                </Typography>
                                                <SignInButton/>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
                <QueryClientProvider client={queryClient}>
                <div><Toaster position="bottom-center"/></div>
                <SideBar isAuthenticated={true} authButtonMethod={null} user={null}>
                    {content}
                </SideBar>
                </QueryClientProvider>
            </AuthenticatedTemplate>


        </>
    );
};

export default AdminView;
