import {Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, useMediaQuery, useTheme, MenuItem, Box, Typography, TextField, CircularProgress } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ChangeEvent, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Campaign } from "../../../models/Campaign";
import { Context } from "../../../Store/store";
import { Influencer } from "../../../types/influencer";
import {CampaignClient} from '../../../utils/axios/CampaignClient'
import { CollaborationClient } from "../../../utils/axios/CollaborationClient";
import { ShoppingMoneyClient} from '../../../utils/axios/ShoppingMoneyClient'
import getContactPerson from "../../../utils/getContactPerson";


interface Props {
    influencer: Influencer
}

export default function AddToCampaign ({influencer}: Props) {
    const campaignClient = new CampaignClient();
    const collaborationClient = new CollaborationClient();
    const shoppingMoneyClient = new ShoppingMoneyClient();
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [campagne, setCampagne] = useState('');
    const [listOfCampaigns, setListOfCampaigns] = useState<Campaign[]>([])
    const [chosenShoppingMoney, setChosenShoppingMoney] = useState('')
    const [listOfShoppingMoney, setListOfShoppingMoney] = useState([])
    const [discountCode, setDiscountCode] = useState('')
    const [state, setState] = useContext(Context)

 
    const handleChange = (event: SelectChangeEvent) => {
        setCampagne(event.target.value);
    };

    const handleShoppingMoneyChoice = (event: SelectChangeEvent) => {
        setChosenShoppingMoney(event.target.value as string);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDiscountCodeField = (event: ChangeEvent<HTMLInputElement> ) => {
        setDiscountCode(event.target.value)
    }

    const handleSave = (influencer:Influencer,) => {
      setIsLoading(true)
      const influencerData = []
      influencerData.push(influencer.id)
      const data = {
        campaign_id: campagne,
        contact_name: getContactPerson(state.auth),
        shopping_money: `${chosenShoppingMoney}`,
        influencers: influencerData,
        discount_code: discountCode,
      };

      collaborationClient.createCollaboration(data)
        .then(res => {
           if (res.status === 200) {
             handleClose()
             toast.success(`${influencer.name} is added to campaign: ${campagne}`)
             setIsLoading(false)
           } else {
            toast.error("Something went wrong, try again or contact Development")
            setIsLoading(false)
           }
        })
    }

    useEffect(() => {
        campaignClient.getAllCampaigns()
        .then(res => {
            setListOfCampaigns(res.data.RESULTS)
            shoppingMoneyClient.getShoppingMoneyList()
                .then(res => {
                    setListOfShoppingMoney(res.data.RESULTS)
                })
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
      <div onClick={handleClickOpen}>
        Add to campaign
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{`Add ${influencer ? influencer.name : ''} to campaign`}</DialogTitle>
        <DialogContent>
        <DialogContentText>
            Choose which campaign you want to add {influencer ? influencer.name : ''} to.
        </DialogContentText>
        <Select
            variant='outlined'
            value={campagne}
            onChange={handleChange}
            fullWidth
            required
        >
                {listOfCampaigns.map(campaign => (
                    <MenuItem key={campaign.id} value={campaign.id}>
                        {campaign.title}
                    </MenuItem>
                ))}
          </Select>
          {campagne && listOfShoppingMoney && (
              <Box my={2}>
                  <Typography variant='body1' color="textSecondary" style={{marginBottom:'12px'}}>
                    Choose how much shopping money to send.
                  </Typography>
                 <Select
                    variant='outlined'
                    value={chosenShoppingMoney}
                    onChange={handleShoppingMoneyChoice}
                    fullWidth
                    required
                >
                        {listOfShoppingMoney.map(shopmoney => (
                            <MenuItem key={shopmoney} value={shopmoney}>
                                {shopmoney}
                            </MenuItem>
                        ))}
                </Select>
                  
              </Box>
          )}
          {campagne && chosenShoppingMoney && (
            <div>
                <Typography variant='body1' color="textSecondary" style={{marginBottom:'12px'}}>
                    Add optional discount code.
                </Typography>
                <TextField variant='outlined' onChange={handleDiscountCodeField} fullWidth/>
            </div>
          )}
          

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} variant='outlined' color="primary">
            Cancel
          </Button>
          <div style={{position:'relative'}}>
            <Button onClick={() => handleSave(influencer)} disabled={isLoading} variant='contained' color="primary" autoFocus>
              Add
            </Button>
            {isLoading && <CircularProgress size={24} className='buttonProgress' />}
          </div>
        </DialogActions>
      </Dialog>
    </div>
    )
}