import clsx from 'clsx';
import { Button, Grid, SvgIcon, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { FC } from 'react';


const useStyles = makeStyles((theme: any) => ({
    root: {},
    action: {
        marginBottom: theme.spacing(1),
        '& + &': {
            marginLeft: theme.spacing(1)
        }
    }
}));

interface HeaderProps {
    title:string;
}

const VoucherHeader:FC<HeaderProps> = ({title}) => {
    const classes = useStyles();
    return (
        <Grid
            container
            spacing={3}
            justifyContent="space-between"
            className={clsx(classes.root)}
        >
            <Grid item>
                <Typography
                    variant="h2"
                    color="textPrimary"
                    className="font40"
                >
                    {title}
                </Typography>
            </Grid>
            <Grid item>
               
            </Grid>
        </Grid>
    );
};


export default VoucherHeader;
