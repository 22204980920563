import DateFnsUtils from "@date-io/date-fns";
import { Grid, TableCell, TableRow, TextField } from "@mui/material";
import DatePicker from '@mui/lab/DatePicker';
import { useCallback, useEffect, useState } from "react";
import PaginatedTable from "../../../components/table/PaginatedTable";
import { VoucherClient } from "../../../utils/axios/VoucherClient";
import DateFunctions from "../../../utils/dates/dateClass";
import { socialLinkToIcon } from "../../../utils/socialLinkToIcon";
import { MobileDatePicker } from "@mui/lab";


const VouchersRedeemed = () => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const [vouchers, setVouchers] = useState([]);
    const dateFunction = new DateFunctions();

    const today = new Date();
    const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1));
    const [endDate, setEndDate] = useState(new Date(today.getFullYear(), today.getMonth(), today.getDate()));

    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [selectedVoucher, setSelectedVoucher] = useState(null);

    const openMenu = (event, selectedVoucher) => {
        setMenuAnchor(event.currentTarget);
        setSelectedVoucher(selectedVoucher);
    }

    const closeMenu = () => {
        setMenuAnchor(null);
        setSelectedVoucher(null);
    }

    const voucherClient = new VoucherClient();

    const getRedeemedVoucher = useCallback(() => {
        voucherClient.getRedeemedInPeriod(startDate, endDate, page, limit)
            .then(res => {
                setVouchers(res.data.RESULTS.items);
                setPage(res.data.RESULTS.page);
                setPageCount(Math.max(res.data.RESULTS.pageCount, 1));
            })
            .catch(err => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, page, limit]);

    useEffect(() => {
        getRedeemedVoucher();
    }, [getRedeemedVoucher]);

    return (
        <Grid container>
            <Grid container>
                <Grid item md={5}>
                        <div style={{ margin: '20px 4px' }}>
                            <Grid container spacing={1}>
                                <MobileDatePicker
                                    disableFuture
                                    inputFormat="dd-MM-yyyy"
                                    label={'Vanaf'}
                                    value={startDate}
                                    onChange={(newValue) => {
                                        setStartDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />

                            </Grid>
                        </div>
                </Grid>
                <Grid item md={2}></Grid>
                <Grid item md={5}>
                        <div style={{ margin: '20px 4px' }}>
                            <Grid container spacing={1}>
                                <MobileDatePicker
                                    disableFuture
                                    inputFormat="dd-MM-yyyy"
                                    label={'Vanaf'}
                                    value={endDate}
                                    onChange={(newValue) => {
                                        setEndDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                            </Grid>
                        </div>
                </Grid>
            </Grid>
            <PaginatedTable
                page={page} setPage={setPage} pageCount={pageCount}
                limit={limit} setLimit={setLimit} limitOptions={[5, 10, 25, 50, 100]}
                columns={['Date', 'Campaign', 'Influencer', 'Shopping money', 'Voucher', 'Actions']}
                rows={vouchers.map(voucher =>
                    <TableRow>
                        <TableCell>{dateFunction.genericDate(voucher.redeem_time)}</TableCell>
                        <TableCell>{voucher.campaign}</TableCell>
                        <TableCell>{voucher.influencer_name}</TableCell>
                        <TableCell>{voucher.shopping_money}</TableCell>
                        <TableCell>{voucher.voucher}</TableCell>
                        <TableCell>
                            {voucher.social_account_urls.map(url => socialLinkToIcon(url))}
                        </TableCell>
                    </TableRow>
                )} />
        </Grid>
    );
}

export default VouchersRedeemed;
