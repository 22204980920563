import { IconButton } from "@mui/material";
import {ReactComponent as TikTokLogo} from '../assets/svgs/socialmedia/tiktok.svg'

import {
    Instagram as Insta,
    // eslint-disable-next-line no-useless-rename
    Youtube as Youtube
} from 'react-feather';


export const socialLinkToIcon = social_url => {
    if (social_url.includes('instagram')) {
        return <IconButton key={social_url} href={social_url} target="_blank" size="large"><Insta/></IconButton>;
    } else if (social_url.includes('tiktok')) {
        return <IconButton key={social_url} href={social_url} target="_blank" size="large"><TikTokLogo/></IconButton>;
    } else if (social_url.includes('youtube')) {
        return <IconButton key={social_url} href={social_url} target="_blank" size="large"><Youtube/></IconButton>;
    }

    return null;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    socialLinkToIcon,
};
