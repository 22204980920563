import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import { Skeleton } from '@mui/material';
import { FC } from "react";

interface SkeletonProps {
    columns: number;
    rows: number;
}

const SkeletonTable:FC<SkeletonProps> = ({columns, rows}) => {

    const NumberOfColumns = Array(columns).fill(columns)
    const NumberOfRows = Array(rows).fill(rows)
    
    return (
        <Table>
            <TableHead>
                <TableRow>
                    
                </TableRow>
            </TableHead>
            <TableRow>
                    {NumberOfColumns.map(item =><TableCell><Skeleton/></TableCell>)}
            </TableRow>
                    {NumberOfRows.map(item =><TableRow>
                        {NumberOfColumns.map(item => <TableCell><Skeleton/></TableCell>)}
                    </TableRow>)}
            
        </Table>
    )
}

export default SkeletonTable;