import { Box, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { useContext } from 'react';
import { Context } from '../../Store/store';
import CampaignCountrySettings from './campaignCountries';
import CampaignTypesSettings from './campaignTypes';
import TagSettings from './tagSettings'

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={{width:'100%'}}
        {...other}
      >
        {value === index && (
          <Box px={2}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

const Settings = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
      };
    return (
        <>
        <Typography variant='h5'>
            Settings
        </Typography>
        <Box
            sx={{ flexGrow: 1, display: 'flex' }}
            mt={4}
        >
            
            <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider', textAlign:'left' }}
            >
                <Tab style={{alignItems:'flex-start', textAlign:'left'}} label="Tags" {...a11yProps(0)} />
                <Tab style={{alignItems:'flex-start', textAlign:'left'}} label="Campaign Types" {...a11yProps(1)} />
                <Tab style={{alignItems:'flex-start', textAlign:'left'}} label="Campaign Countries" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <TagSettings/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <CampaignTypesSettings/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <CampaignCountrySettings/>
            </TabPanel>
      </Box>
      </>
    )

}

export default Settings;