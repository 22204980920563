export class FormatString extends String {
    constructor(public value: string) {
        super(value);
    }

    format(...args) {
        // var args = arguments;
        return this.value.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined'
                ? args[number]
                : match
                ;
        });
    }
}