import { createTheme } from "@mui/material";

const theme = createTheme({
    breakpoints:{
        keys:['xs', 'sm', 'md', 'lg', 'xl'],
        values: {
            xs:0,
            sm:600,
            md:900,
            lg:1200,
            xl:1536,
        },
        unit:"px",
    },

    palette: {
        common: {
            black:'#000',
            white: '#fff'
        },
        primary: {
            main: '#ff5c8e',
            dark: '#000000',
            contrastText: '#fff',
        },
        secondary: {
            main: '#000000',
        },
        action: {
            hover: 'e9b9c4',
        },
    },
    typography: {
        fontFamily: 'Work Sans',
    },
    shape :{
        borderRadius:'9px',
    },
    components: {
        MuiButton: {
          styleOverrides: {
            root: {
              '&:hover': 'purple',
            },
          },
        },
      },
});

export default theme