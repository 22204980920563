
export class Influencer {
    id: number;
    accountsNaam: string;
    volgers: number;
    land: string;
    foto: string;

    constructor(id:number,accountsNaam: string,volgers: number,land: string,foto: string,
              ) {
        this.id = id;
        this.accountsNaam = accountsNaam;
        this.volgers = volgers;
        this.land = land;
        this.foto = foto
        this.volgers = volgers;
    }

}