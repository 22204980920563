import {createContext, useState, useEffect} from 'react';
import { callMsGraph } from '../authentication/msGraphApiCall';

const initialState = {
    tags:[],
    campaign_types:[],
    campaign_countries:[],
    auth:[]
}

export const Context = createContext();

const Store = ({ children }) => {
    const [state, setState] = useState(initialState);

    return (
        <Context.Provider value={[state, setState]}>
            {children}
        </Context.Provider>
    )

}

export default Store;