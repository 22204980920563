import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styling/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import theme from "./theme";
import { ConfirmProvider } from 'material-ui-confirm';
import Store from './Store/store'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authentication/authConfig";
import { useTranslation, I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import RequestInterceptor from './utils/axios/interceptors/RequestInterceptor';


export const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Store>      
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
              <ConfirmProvider>                
                  <MsalProvider instance={msalInstance}>
                    <I18nextProvider i18n={i18n}>
                      <RequestInterceptor>
                        <App/>
                      </RequestInterceptor>
                    </I18nextProvider>
                  </MsalProvider>
              </ConfirmProvider>
            </ThemeProvider>
        </LocalizationProvider>
    </Store>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
