import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputAdornment, MenuItem, Select} from '@mui/material'
import { Globe as GlobeIcon} from 'react-feather'

const LanguageSwitch = () => {
    const [language, setLanguage] = useState('nl');
    const { i18n } = useTranslation();

    const languages = [
        {iso:'nl', label:'Nederlands'},
        {iso:'en', label:'English'},
        {iso:'fr', label:'Francais'},
        {iso:'de', label:'Deutsch'},
    ]

    const switchTheLanguage = (e) => {
        e.preventDefault();
        setLanguage(e.target.value);
        const lng = e.target.value;
        i18n.changeLanguage(lng);
    }

    return (

        <FormControl size="small">
        <Select
            labelId="language-switcher-select-label"
            id="language-switcher-select"
            value={language}
            label="Language"
            className='language-select'
            onChange={switchTheLanguage}
            variant={'standard'}
            startAdornment={
                <InputAdornment position="start">
                    <GlobeIcon size={18}/>
                </InputAdornment>
            }
        >
            {languages.map(item => (
                <MenuItem key={`${item.iso}-${item.label}`} value={item.iso}>{item.label}</MenuItem>
            ))}

        </Select>
    </FormControl>
    )
}

export default LanguageSwitch

