import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';


const InputSelect = ({error, label, name, onChange, value, options}) => {
    
    return (
      <Box mt={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
          <Select
              native
              fullWidth
              error={Boolean(error)}
              value={value}
              onChange={onChange}
              label={label}
              name={name}
          >
              <option aria-label="None" value="" disabled />
              {options.map(item =>{
                  return (
                      <option 
                        key={item.value}
                        aria-label={item.value}
                        value={item.value}>
                          {item.name}
                      </option>
                  )
              })}
          </Select>
          </FormControl>
        </Box>
    )

    
}


export default InputSelect;