import {
    useState,
    useEffect,
} from 'react';
import type { FC } from 'react';
import { Box, Container, Tab, Tabs } from '@mui/material';
import Page from '../../components/page/Page';
import type { Influencer } from '../../types/influencer';
import Header from './collaborationsHeader';
import Results from './collaborationsResults';
import { useParams } from "react-router-dom";
import {CollaborationClient} from '../../utils/axios/CollaborationClient'
import { collabData } from '../../types/collabData';

interface ResultsProps {
    title?: string;
    user?:any;
}

const ProductListView: FC<ResultsProps> = ({ title, user }) => {
    const collaborationClient = new CollaborationClient()
    const params = useParams()
    const [collaborations, setCollaborations] = useState<collabData[]>([]);

    const getCollaborations =  (status?:string) => {
        try {
            collaborationClient.getAllCollaborations(`${params.id}${typeof(status) !== 'undefined' ? `?status=${status}` : ''}`)
            .then(res => {
                setCollaborations(res.data.RESULTS);
            })
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getCollaborations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <Page
            title="Influencer List"
        >
            <Container maxWidth={false}>
                <Header 
                    title={title}
                    campaignName={collaborations[0] ? collaborations[0].campaign_title : 'Loading campaign...'}
                    campaignId={params.id}
                    getCollaborations={getCollaborations}
                    user={user}
                />
                {collaborations && (
                    <Box mt={3}>
                        <Results collabData={collaborations} getCollaborations={getCollaborations} />
                    </Box>
                )}
            </Container>
        </Page>
    );
};

export default ProductListView;
