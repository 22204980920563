import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { InfluencerClient } from "../../utils/axios/InfluencerClient";
import CommentView from "./influencerCommentView";


export interface Comment {
    id: number;
    creation_date: Date;
    modified_date: Date;
    commentor: string;
    content: string;
}

const commentToViewmodel = (comment: Comment, editing: boolean = false) => {
    let vm = Object.assign({ editing }, comment);
    vm.creation_date = new Date(vm.creation_date);
    vm.modified_date = new Date(vm.modified_date);
    return vm;
}

const createEmptyComment = () => {
    return commentToViewmodel({
        id: 0,
        creation_date: null,
        modified_date: null,
        commentor: '',
        content: ''
    }, true);
}

const InfluencerCommentsView = ({ influencer }) => {
    const [comments, setComments] = useState([]);
    const [selectedForDelete, setSelectedForDelete] = useState<Comment>(null);

    const influencerClient = new InfluencerClient();
    const handleHttpError = (msg: string = null) => {
        msg ?? toast.error('Something went wrong, try again or contact development.')
    };

    const getComments = () => {
        if (influencer?.id) {
            influencerClient.getComments(influencer.id)
                // Reverse the comments so that the newest comments are shown first.
                .then(res => setComments(res.data.RESULTS.map(c => commentToViewmodel(c)).reverse()))
                .catch(_ => () => handleHttpError());
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => getComments(), []);

    const updateCommentsFromResult = (commentId: number, res: any) => {
        if (res.status === 200) {
            const comment = commentToViewmodel(res.data.RESULTS);
            setComments(
                commentId === 0 ?
                    // If the comment was newly added, prepend it to the comments array.
                    [comment].concat(comments) :
                    // Else replace the existing one in the coments array.
                    comments.map(c => c.id !== commentId ? c : comment)
            );
        }
    };

    const saveComment = (commentId: number, content: string) => {
        if (commentId === 0) {
            influencerClient.createComment(influencer.id, content)
                .then(res => updateCommentsFromResult(commentId, res))
                .catch(_ => () => handleHttpError());
        } else {
            influencerClient.updateComment(commentId, content)
                .then(res => updateCommentsFromResult(commentId, res))
                .catch(_ => () => handleHttpError());
        }
    }

    const deleteComment = (commentId: number, doWork: boolean = false) => {
        if (!doWork) {
            setSelectedForDelete(comments.find(c => c.id === commentId));
        } else {
            influencerClient.deleteComment(commentId)
                .then(res => {
                    if (res.status === 204) {
                        setComments(comments.filter(c => c.id !== commentId));
                    }
                })
                .catch(_ => () => handleHttpError(`Something went wrong, try again or contact development.`));
            setSelectedForDelete(null);
        }
    }

    return (
        <Grid container alignItems={'center'} justifyContent={'center'} className={'commentMain'}>
            <Grid item sm={10}>
                <Typography>
                    <h3>Comments for {influencer?.name}</h3>
                </Typography>
            </Grid>
            <Grid item sm={10}>
                <Box mt={3} >
                    <Grid spacing={2} container>
                        <Grid item sm={12}>
                            <CommentView autoFocus={true} comment={createEmptyComment()} onSave={saveComment} />
                        </Grid>
                        {comments.map(c =>
                            <Grid item sm={12}>
                                <CommentView key={c.id} comment={c} onSave={saveComment} onDelete={deleteComment} />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Grid>
            <Dialog open={selectedForDelete !== null}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>Are you sure you want to delete this comment?</DialogContent>
                <DialogActions>
                    <Button variant={'contained'} color={'primary'} onClick={() => deleteComment(selectedForDelete.id, true)}>Yes</Button>
                    <Button variant={'outlined'} color={'primary'} onClick={() => setSelectedForDelete(null)}>No</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default InfluencerCommentsView;
