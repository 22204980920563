import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { useNavigate} from "react-router-dom";
import clsx from 'clsx';
import { Button, Grid, SvgIcon, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    PlusCircle as PlusCircleIcon,
} from 'react-feather';
import FullscreenDialog from '../../components/dialogs/fullscreenDialog'
import SimplifiedInfluencerRegistrationForm from '../../components/inputs/components/InfluencerRegistration/Simplified/simplifiedInfluencerRegistrationForm';

interface HeaderProps {
    className?: string;
    title?: string;
}

const useStyles = makeStyles((theme: any) => ({
    root: {},
    action: {
        marginBottom: theme.spacing(1),
        '& + &': {
            marginLeft: theme.spacing(1)
        }
    }
}));

const Header: FC<HeaderProps> = ({ className, title, ...rest }) => {
    const classes = useStyles();

    const buttonTitle = title === 'Influencers coupled to campaign' ? 'Add influencer to campaign' : 'New influencer'
    const headertitle = title === 'Influencers coupled to campaign' ? title : ' Influencers'
    let navigate = useNavigate();
    return (
        <Grid
            container
            spacing={3}
            justifyContent="space-between"
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Grid item>
                <Typography
                    variant="h2"
                    color="textPrimary"
                    className="font40"
                >
                    {headertitle}
                </Typography>
            </Grid>
            <Grid item>
                <FullscreenDialog
                    className={classes.action}
                    startIcon={<SvgIcon fontSize="small"><PlusCircleIcon /></SvgIcon>}
                    content={<SimplifiedInfluencerRegistrationForm />}
                    variant='contained'
                    color="primary"
                    buttonTitle={"Add influencer"}
                    actionSave={() => { }}
                    typeButton="button"
                />
            </Grid>
        </Grid>
    );
};

Header.propTypes = {
    className: PropTypes.string
};

export default Header;
