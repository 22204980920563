import React, { useEffect, useState, useContext } from "react";
import type { FC, ChangeEvent } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Backdrop,
  Card,
  Checkbox,
  CircularProgress,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Grid,
  Select,
  MenuItem,
  IconButton,
  FormControlLabel,
  Autocomplete
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Search as SearchIcon,
  ArrowRight as NextIcon,
  ArrowLeft as PreviousIcon,
} from "react-feather";
import type { Influencer } from "../../../types/influencer";
import AddInfluencerToCollab from "../../../components//dialogs/addInfluencerToCollaborationDialog";
import { CollaborationClient } from "../../../utils/axios/CollaborationClient";
import DateFunctions from "../../../utils/dates/dateClass";
import SkeletonTable from "../../../components/skeletonTable/skeletonTable";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Context } from '../../../Store/store';
import toast from "react-hot-toast";
import getContactPerson from "../../../utils/getContactPerson";

interface ResultsProps {
  className?: string;
  influencers: Influencer[];
  campaignId?: string;
  user: any;
  getInfluencersNotInCampaign: any;
  nextPage: number;
  numberOfPages: number;
  previousPage: any;
  currentPage: number;
  checkDate: boolean;
  loading: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  root: {},
  bulkOperations: {
    position: "relative",
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: "absolute",
    width: "100%",
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  queryField: {
    width: 500,
  },
  categoryField: {
    flexBasis: 200,
  },
  availabilityField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200,
  },
  stockField: {
    marginLeft: theme.spacing(2),
  },
  shippableField: {
    marginLeft: theme.spacing(2),
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0,
  },
  image: {
    height: 68,
    width: 68,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    background: "rgba(0,0,0,0.8)",
    fontSize: "18px",
  },
  gridFlex: {
    display: "flex",
    alignItems: "center",
  },
  flexStart: {
    justifyContent: "flex-start",
  },

  flexEnd: {
    justifyContent: "flex-end",
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;



const Results: FC<ResultsProps> = ({
  className,
  checkDate,
  influencers,
  campaignId,
  user,
  getInfluencersNotInCampaign,
  numberOfPages,
  nextPage,
  previousPage,
  currentPage,
  loading,
  ...rest
}) => {
  const influencerTypes = [
    "Instagram",
    "TikTok",
    "Sporter",
    "Youtube",
    "Fashion & Jewellery",
    "Fashion",
    "Jewellery",
    "Mommy",
    "High engagement",
    "Model",
  ];
  const collaborationClient = new CollaborationClient();
  const [state, setState] = useContext(Context);
  const contact = getContactPerson(state.auth)
  const classes = useStyles();
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [tags, setTags] = useState([])
  const [limit, setLimit] = useState<number>(50);
  const [query, setQuery] = useState<string>("");
  const [typeFilter, setTypeFilter] = useState<string>("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [lastUpdateDateCheck, setLastUpdateDateCheck] =
    useState<boolean>(false);
  const dateFunctions = new DateFunctions();

  useEffect(() => {
    getInfluencersNotInCampaign(
      campaignId,
      limit,
      query,
      1,
      tags,
      lastUpdateDateCheck
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdateDateCheck, tags]);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);
    setTimeout(() => {
      getInfluencersNotInCampaign(
        campaignId,
        limit,
        event.target.value,
        1,
        tags,
        lastUpdateDateCheck
      );
    }, 1000);
    setPage(1);
  };

  const selectLastUpdateDate = () =>
    setLastUpdateDateCheck(!lastUpdateDateCheck);

  const handleSelectAllProducts = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedProducts(event.target.checked ? influencers : []);
  };

  const handleSelectOneProduct = (
    event: ChangeEvent<HTMLInputElement>,
    influencer: any
  ): void => {
    if (!selectedProducts.some((item) => item.id === influencer.id)) {
      setSelectedProducts((prevSelected) => [...prevSelected, influencer]);
    } else {
      setSelectedProducts((prevSelected) =>
        prevSelected.filter((item) => item.id !== influencer.id)
      );
    }
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
    getInfluencersNotInCampaign(
      campaignId,
      event.target.value,
      query,
      page,
      tags,
      lastUpdateDateCheck
    );
  };

  const handlePreviousPage = () =>
    getInfluencersNotInCampaign(
      campaignId,
      limit,
      query,
      previousPage,
      tags,
      lastUpdateDateCheck
    );

  const handleNextPage = () =>
    getInfluencersNotInCampaign(
      campaignId,
      limit,
      query,
      nextPage,
      tags,
      lastUpdateDateCheck
    );

  const handleTypeFilter = (event: ChangeEvent<HTMLInputElement>): void => {
    setTypeFilter(event.target.value);
    handleQueryChange(event);
  };

  const handleAddToCampaign = (shoppingmoney, discount_codes) => {
    setIsLoading(true);
    let influencersData = [];
    let data = {};
    influencers.forEach((item) => {
      selectedProducts.forEach((selitem) => {
        if (item.id === selitem.id) {
          influencersData.push(item.id);
        }
      });
    });

    data = {
      campaign_id: campaignId,
      contact_name: getContactPerson(state.auth),
      shopping_money: shoppingmoney,
      influencers: influencersData,
      discount_code: discount_codes,
    };
    collaborationClient.createCollaboration(data).then((res) => {
      setSelectedProducts([]);
      if (res.status === 200) {
        getInfluencersNotInCampaign(campaignId, limit, query, 1, tags,checkDate);
        setTimeout(function () {
          setIsLoading(false);
          toast.success("Influencer added to campaign")
        }, 1000);
      } else {
        setIsLoading(false);
        toast.error("Something went wrong, try again or contact Development")
      }
    });
  };

  const enableBulkOperations = selectedProducts.length > 0;
  const selectedSomeProducts =
    selectedProducts.length > 0 && selectedProducts.length < influencers.length;
  const selectedAllProducts = selectedProducts.length === influencers.length;

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <CircularProgress color="primary" />
        Add Influencers
      </Backdrop>
      <Box p={2}>
        <Box display="flex" alignItems="center">
          <TextField
            className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Search.."
            value={query}
            variant="outlined"
          />
          <TextField
            className={classes.availabilityField}
            label="Influencer type"
            name="InfluencerTypeFilter"
            onChange={handleTypeFilter}
            select
            SelectProps={{ native: true }}
            value={typeFilter || "Alle"}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          >
            <option key="" value="">
              All
            </option>
            {influencerTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </TextField>
          
          <Autocomplete
            multiple
            disableCloseOnSelect
            selectOnFocus
            fullWidth
            limitTags={3}
            style={{maxWidth:'50%', marginLeft:'10px'}}
            clearOnBlur
            id="search-by-tags"
            options={state.tags}
            value={tags}
            onChange={(event: any, newValue: any) => {
                setTags(newValue);
            }}
            getOptionLabel={(option) => {
                return option.tag;
            }}
            defaultValue={[]}
            renderOption={(option:any, { selected }) => (
            <>
                <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                />
                {option.tag}
            </>
            )}
            renderInput={(params) => (
            <TextField
                {...params}
                variant="outlined"
                label="Search by tag"
            />
            )}
          />

        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                name="lastUpdateDateCheck"
                onChange={selectLastUpdateDate}
              />
            }
            label="Collaboration longer than 3 months ago"
          />
        </Box>
      </Box>
      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllProducts}
              indeterminate={selectedSomeProducts}
              onChange={handleSelectAllProducts}
            />
            <AddInfluencerToCollab
              actionYes={handleAddToCampaign}
              influencers={selectedProducts}
              buttonTitle="Add"
              variant="contained"
              color="primary"
              className={classes.bulkAction}
              title="Add influencer(s) to campaign?"
              text={selectedProducts.map((item) => {
                return `${item.name}, `;
              })}
            />
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box minWidth={1200}>
          {loading ? (
            <>
              <SkeletonTable columns={8} rows={10} />
            </>
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedAllProducts}
                        indeterminate={selectedSomeProducts}
                        onChange={handleSelectAllProducts}
                      />
                    </TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Influencer Type</TableCell>
                    <TableCell>Date last collaboration</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {influencers.map((influencer) => {
                    const isProductSelected = selectedProducts.some(
                      (item) => item.id === influencer.id
                    );

                    return (
                      <TableRow
                        hover
                        key={influencer.id}
                        selected={isProductSelected}
                        aria-checked={isProductSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isProductSelected}
                            onChange={(event) =>
                              handleSelectOneProduct(event, influencer)
                            }
                            value={isProductSelected}
                          />
                        </TableCell>
                        <TableCell>
                          {influencer.socials[0] ? (
                            <a
                              style={{ color: "black", textDecoration: "none" }}
                              rel="noreferrer"
                              href={influencer.socials[0].account_url}
                              target="_blank"
                            >
                              {influencer.name}
                            </a>
                          ) : (
                            <span>{influencer.name}</span>
                          )}
                        </TableCell>
                        <TableCell>{influencer.email}</TableCell>
                        <TableCell>{influencer.influencer_type}</TableCell>
                        <TableCell>
                          {dateFunctions.collaborationLastDate(
                            influencer.last_collaboration_date
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <div>
                <Grid container>
                  <Grid item md={5}></Grid>
                  <Grid
                    item
                    md={3}
                    className={`${classes.gridFlex} ${classes.flexEnd}`}
                  >
                    Rows per page:
                    <Select
                      labelId="row-amount"
                      id="row-amount-id"
                      value={limit}
                      onChange={handleLimitChange}
                      style={{ marginLeft: "10px" }}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </Grid>
                  <Grid
                    item
                    md={2}
                    className={`${classes.gridFlex} ${classes.flexEnd}`}
                  >
                    Page {currentPage} of {numberOfPages}
                  </Grid>
                  <Grid
                    item
                    md={2}
                    className={`${classes.gridFlex} ${classes.flexEnd}`}
                  >
                    <div>
                      <IconButton
                        disabled={previousPage === 0 ? true : false}
                        aria-label="previous"
                        onClick={handlePreviousPage}
                        size="large">
                        <PreviousIcon />
                      </IconButton>
                      <IconButton aria-label="next" onClick={handleNextPage} size="large">
                        <NextIcon />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  influencers: PropTypes.array.isRequired,
};

Results.defaultProps = {
  influencers: [],
};

export default Results;
