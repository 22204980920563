import { BackendClient } from './_BackendClient'

export class ShoppingMoneyClient extends BackendClient {
    constructor(){
        super();
        this.apiurl = this.apiurl + '/voucher'
    }

    public getShoppingMoneyList(){
        return this.makeRequest('GET', 'all-distinct-values')
    }
    
    public makeShoppingMoneyRequest(method: string, url: string, makeShoppingMoneyRequestDataObject: any = null){
        return this.makeRequest(method, `${url}`, makeShoppingMoneyRequestDataObject)
    }

    public getVoucherInfo(){
        return this.makeRequest("GET", "get-voucher-information")
    }

    public sendVouchers(shopMoney:string, file:any){
        return this.makeRequest('POST', `import-voucher-csv/${shopMoney}`, file, { 'Content-Type': file.type })
    }
}