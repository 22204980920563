import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Box, Typography, Collapse, Divider, Button } from "@mui/material"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import { useConfirm } from "material-ui-confirm"
import { useContext, useState } from "react"
import { Context } from "../../Store/store"
import { makeStyles } from "@mui/styles";
import CreateCountryDialog from "./components/createCountryDialog"
import DeleteIcon from '@mui/icons-material/Delete';
import { SettingsClient } from "../../utils/axios/SettingsClient"
import toast from "react-hot-toast"

const settingsClient = new SettingsClient()

const useStyles = makeStyles({
    root: {
      maxWidth: 275,
      minWidth: 275,
      margin:'20px 20px 20px 0',
    },
    bold: {
        fontWeight:800
    }
  });

const CampaignCountrySettings = () => {
    
    const [state, setState] = useContext(Context);
    const [open, setOpen] = useState(false);
    const confirm = useConfirm();

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'campaign_country',
          headerName: 'Campaign Country',
          width: 250,
          editable: true,
        },
        {
          field: 'campaign_country_abbreviation',
          headerName: 'Campaign Country Abbreviation',
          width: 250,
          editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName:'Delete',
            getActions: (params: any) => [
              <GridActionsCellItem icon={<DeleteIcon />} onClick={() => deleteCountry(params.id, params.row.campaign_country)} label="Delete" />,
            ]
          }
      ];
  

    const handleClick = () => {
        setOpen(!open);
    };

    function deleteCountry (id:any, country: string){
        confirm({ description: `You are about to delete country with id: ${id} and name : ${country}` , confirmationButtonProps: { autoFocus: true }})
          .then(() => { settingsClient.delete_campaign_country(id).then(res=> {
           if (res.status === 200){
            toast.success('Country has been deleted')
            settingsClient.get_all_campaign_countries()
            .then(res =>{
              setState({
                  ...state,
                  campaign_countries: res.data.RESULTS
              })
        })
           } else {
            toast.error("Something went wrong, try again or contact Development");
           }
          })})
          .catch(() => { /* ... */ });
    };
    
    return (
        <>
            <Box>
                <Box  style={{display:'flex', flexWrap:'wrap'}}>
                    <Box my={2}>
                        <CreateCountryDialog/>
                    </Box>
                    <div style={{ height: 550, width: '100%' }}>
                    <DataGrid
                        rows={state.campaign_countries}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                    />
                    </div>
                </Box>
            </Box>
           
        </>
    )
}

export default CampaignCountrySettings;