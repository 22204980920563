import { Card, CardContent, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Skeleton } from '@mui/material';
import { FC } from "react";

interface SkeletonCardProps {
    numberOfCards:any;
}

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        maxWidth:400,
        height:170,
        backgroundColor:'#f9f9f9 !important',
        padding:'16px !important'
    },
    card: {
        padding:'0 !important'
    },
});

const SkeletonCard:FC<SkeletonCardProps> = ({numberOfCards}) => {
    const classes = useStyles();
    const NumberOfCardsArray = Array(numberOfCards).fill(numberOfCards)

    return <>
     {NumberOfCardsArray.map(item =>(
        <div style={{maxWidth:'600px', width:'400px', height:'170px', margin:'0 25px 25px 0'}}>
            <Card className={classes.root}>
                <CardContent className={classes.card}>
                    <Grid container spacing={1}>
                        <Grid item md={5}>
                            <Skeleton variant="circular" width={105} height={105} />
                        </Grid>
                        <Grid item md={7}>
                            <Skeleton variant="rectangular" width={'100%'} height={25} />
                            <br/>
                            <Skeleton variant="rectangular" width={'100%'} height={25} />
                            <br/>
                            <Skeleton variant="rectangular" width={'100%'} height={25} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>        
    ))}
    </>;
}

export default SkeletonCard;