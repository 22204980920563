import {
    Box,
    TextField
} from '@mui/material';

const TextInput = ({error, helperText, label, fullWidth, name, onBlur, onChange, value, isMultiLine, isType}) => {
    return (
        <div>
            <Box mt={2}>
                <TextField
                error={Boolean(error)}
                fullWidth={fullWidth}
                helperText={helperText}
                label={label}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                variant="outlined"
                multiline={isMultiLine}
                type={isType}
                InputLabelProps={{
                    shrink: true,
                }}
                />
            </Box>
        </div>
    )
}

export default TextInput;