
export class Campaign {
    id?: number;
    title: string;
    publish_date: string;
    tags: string;
    shopping_url: string;
    exponea_template_id: string;
    campaign_type:string;
    campaign_country:string;
    is_archived: boolean;
    pdf:any;
    pdf_name:string;


    constructor(
        title: string,
        publish_date: string,
        tags:string,
        shopping_url: string,
        exponea_template_id: string,        
        campaign_type:string,
        campaign_country:string,        
        pdf:any,
        pdf_name:string,
        id?:number,
        is_archived: boolean = false,
    )
    {
        this.id = id;
        this.title = title;
        this.publish_date = publish_date;
        this.tags = tags;
        this.shopping_url = shopping_url;
        this.exponea_template_id = exponea_template_id;
        this.campaign_type = campaign_type;
        this.campaign_country = campaign_country
        this.is_archived = is_archived;
        this.pdf = pdf;
        this.pdf_name = pdf_name;
    }
}