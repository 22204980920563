import {
    useState,
    useEffect,
} from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from "@mui/styles";
import Page from '../../../components/page/Page';
import type { Influencer } from '../../../types/influencer';
import Header from './Header';
import Results from './Results';
import {InfluencerClient} from '../../../utils/axios/InfluencerClient'

interface ResultsProps {
    title?: string;
    type?: string;
    campaignId?: string;
    user: any;
}

const useStyles = makeStyles((theme: any) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: 100
    }
}));



const ProductListView: FC<ResultsProps> = ({ title, campaignId, user }) => {
    const classes = useStyles();
    const [influencers, setInfluencers] = useState<Influencer[]>([]);
    const influencerClient = new InfluencerClient();
    const [pageLimit, setPageLimit] = useState(25);
    const [checkDate, setCheckDate] = useState(false);
    const [page, setPage] = useState(1);
    const [tags, setTags] = useState([])
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [nextPage, setNextPage] = useState(2);
    const [previousPage, setPreviousPage] = useState(0);
    const [search_term, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true)

    const getInfluencers = (campaignId, limit, term, page, tags, checkDate?) => {
        try {
            influencerClient.getInfluencersNotInCampaign(campaignId, limit, term, page, tags, checkDate)
            .then(res => {
                setInfluencers(res.data.RESULTS.influencers);
                setPages(res.data.RESULTS.pages);
                setNextPage(res.data.RESULTS.nextPage);
                setCurrentPage(res.data.RESULTS.currentPage);
                setLoading(false)
                if (res.data.RESULTS.previousPage == null){
                    setPreviousPage(0);
                } else {
                    setPreviousPage(res.data.RESULTS.previousPage);
                }
            })
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getInfluencers(campaignId, pageLimit, search_term, page, tags, checkDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page
            className={classes.root}
            title="Influencer List"
        >
            <Container maxWidth={false}>
                <Header title={title} />
                {influencers && (
                    <Box mt={3}>
                        <Results loading={loading} influencers={influencers} campaignId={campaignId} user={user} getInfluencersNotInCampaign={getInfluencers} checkDate={checkDate} nextPage={nextPage} previousPage={previousPage} numberOfPages={pages} currentPage={currentPage} />
                    </Box>
                )}
            </Container>
        </Page>
    );
};

export default ProductListView;
