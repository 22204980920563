import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Typography,
  Fab 
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FC, useState } from "react";
import { collabData, post } from "../../types/collabData";
import InstaPostReview from "./instaPostReview";
import DateFunctions from '../../utils/dates/dateClass'
import {
  MessageCircle,
  Check
} from 'react-feather';
import GenericDialog from "../dialogs/genericDialog";
import InfluencerCommentsView from '../../components/modals/influencerCommentsView';

interface postReviewIndexProps {
  posts: post[];
  collaboration: collabData;
}

const InstaPostReviewIndex: FC<postReviewIndexProps> = ({ posts, collaboration }) => {
  const dateFunctions = new DateFunctions();
  const [showCommentsDialog, setShowCommentsDialog] = useState(false);

  const openCommentsDialog = (influencer) => {
    setShowCommentsDialog(true);
  }

  const closeCommentsDialog = () => {
      setShowCommentsDialog(false);
  }
  
  posts.sort((a,b) => Date.parse(b.timestamp) - Date.parse(a.timestamp))

  return (
    <div style={{padding:'20px', width:'50vw', margin:'0 auto'}}>
      <Box p={2}>
        <div style={{display:'flex', flexDirection:'row'}}>
            <Typography variant='h2' style={{margin:'0 0 20px 0'}}>
              Post reviews
            </Typography>
            <Fab color="primary" aria-label="comments" className='floatingActionButton' onClick={_ => openCommentsDialog(collaboration.influencer)}>
              <MessageCircle />
            </Fab>
        </div>
        
        {posts.map((p, i) => (        
            <Accordion key={p.id} defaultExpanded={p.score ? false : true}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{fill:'white'}} />}
                aria-controls={`panel${i}a-content`}
                id={`panel${i}a-header`}
                style={{backgroundColor:'#e40b7D', color:'white'}}
            >
                <div style={{width:'100%', display:'flex', alignItems:'center'}}>
                    <div style={{fontSize:'14px', fontWeight:'bold'}}>
                        {dateFunctions.genericDate(p.timestamp)}
                    </div> 
                    <div style={{margin:'0 0 0 auto'}}>
                        {p.score &&  <Chip label="BEOORDEELD" icon={<Check/>} style={{backgroundColor:'white', color:'black', borderRadius:'4px'}} />}
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <InstaPostReview post={p} collaboration={collaboration}/>
            </AccordionDetails>
            </Accordion>
            ))}
      </Box>
      <GenericDialog
                content={<InfluencerCommentsView influencer={collaboration.influencer} />}
                buttons={[<Button variant={'contained'} color={'primary'} onClick={_ => closeCommentsDialog()}>Sluiten</Button>]}
                isOpen={showCommentsDialog}
                onClose={_ => closeCommentsDialog()}
            />
    </div>
  );
};

export default InstaPostReviewIndex;
