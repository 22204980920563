import { BackendClient } from './_BackendClient'
import { Utilities } from './utilities'
const utils = new Utilities();

export class InfluencerClient extends BackendClient {
    constructor() {
        super();
        this.apiurl = this.apiurl + '/influencer';
    }

    public getAllInfluencers(status, limit, term, page, checkDate = false, tags, country) {
        const influencerPaginationPayload = utils.createInfluencerPaginationPayload(status, limit, term, page, checkDate, tags, country);
        return this.makeRequest('POST', `all`, influencerPaginationPayload);
    }

    public getInfluencerById(InfluencerId) {
        return this.makeRequest('GET', `${InfluencerId}`);
    }

    public deleteInfluencer(InfluencerId, comment: string) {
        let body = { "comment": comment.length === 0 ? null : comment };
        return this.makeRequest('DELETE', `${InfluencerId}`, body);
    }

    public undoDeleteInfluencer(InfluencerId) {
        return this.makeRequest('POST', `${InfluencerId}/undo-delete`);
    }

    public getInfluencersNotInCampaign(id, limit, term, page,tags, checkDate = false) {
        const influencerNotInCampaignPaginationPayload = utils.createInfluencerNotInCampaignPaginationPayload(id, limit, term, page, tags, checkDate)
        return this.makeRequest('POST', `get-not-in-campaign`, influencerNotInCampaignPaginationPayload)
    }

    public createInfluencer(createInfluencerDataObject: any) {
        return this.makeRequest("POST", "create-influencer", createInfluencerDataObject)
    }

    public createSimplifiedInfluencer(influencerData: any) {
        return this.makeRequest("POST", "create-influencer-simplified", influencerData);
    }

    public updateInfluencer(id: string, updateInfluencerDataObject: any) {
        return this.makeRequest("PUT", `update-influencer/${id}`, updateInfluencerDataObject)
    }

    public getOpenRegistrations() {
        return this.makeRequest("GET", 'get-open-registrations')
    }

    public getRegistration(registrationId) {
        return this.makeRequest('GET', `get-registration/${registrationId}`)
    }

    public acceptRegistration(registrationId) {
        return this.makeRequest('GET', `accept-registration/${registrationId}`)
    }

    public declineRegistration(registrationId) {
        return this.makeRequest('GET', `decline-registration/${registrationId}`)
    }

    public ignoreRegistration(registrationID) {
        return this.makeRequest('GET', `ignore-registration/${registrationID}`)
    }

    public getInfluencersFromBQ() {
        return this.makeRequest("GET", "get-big-query-influencers")
    }

    public getComments(influencerId: number) {
        return this.makeRequest('GET', `${influencerId}/comment`)
    }

    public createComment(influencerId: number, content: string) {
        return this.makeRequest('POST', `${influencerId}/comment`, { content })
    }

    public updateComment(commentId: number, content: string) {
        return this.makeRequest('PUT', `comment/${commentId}`, { content })
    }

    public deleteComment(commentId: number) {
        return this.makeRequest('DELETE', `comment/${commentId}`)
    }
}