import TextInput from "../FormComponents/textInput";
import { useTranslation } from 'react-i18next';
import { Grid } from "@mui/material";

const InfluencerRegistrationFormPartTwo = ({ values, touched, errors, handleBlur, handleChange}) => {

    const { t } = useTranslation();

    return (
        <div className="partThree" id="partThree">
            <Grid container spacing={1}>
                <Grid item md={9} sm={12}>
                    <TextInput
                        error={Boolean(touched.street && errors.street)}
                        fullWidth
                        helperText={touched.street && errors.street}
                        label={t('street')}
                        name="street"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.street}
                        isMultiLine={false}
                        isType="text"
                    />
                </Grid>
                <Grid item md={3} sm={12}>
                    <TextInput
                        error={Boolean(touched.streetnumber && errors.streetnumber)}
                        fullWidth
                        helperText={touched.streetnumber && errors.streetnumber}
                        label={t('streetnumber')}
                        name="streetnumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.streetnumber}
                        isMultiLine={false}
                        isType="text"
                    />
                </Grid>
            </Grid>
            <TextInput
                error={Boolean(touched.zipcode && errors.zipcode)}
                fullWidth
                helperText={touched.zipcode && errors.zipcode}
                label={t('zipcode')}
                name="zipcode"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.zipcode}
                isMultiLine={false}
                isType="text"
            />
            <TextInput
                error={Boolean(touched.city && errors.city)}
                fullWidth
                helperText={touched.city && errors.city}
                label={t('city')}
                name="city"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.city}
                isMultiLine={false}
                isType="text"
            />
        </div>
    )
}

export default InfluencerRegistrationFormPartTwo


