import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Box, Typography, Collapse, Divider, Button } from "@mui/material"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import { useConfirm } from "material-ui-confirm"
import { useContext, useState } from "react"
import { Context } from "../../Store/store"
import { makeStyles } from "@mui/styles";
import CreateTypeDialog from "./components/createTypeDialog"
import DeleteIcon from '@mui/icons-material/Delete';
import { SettingsClient } from "../../utils/axios/SettingsClient"
import toast from "react-hot-toast"

const settingsClient = new SettingsClient();

const useStyles = makeStyles({
    root: {
      maxWidth: 275,
      minWidth: 275,
      margin:'20px 20px 20px 0',
    },
    bold: {
        fontWeight:800
    }
  });

const CampaignTypesSettings = () => {
    
    const [state, setState] = useContext(Context)
    const [open, setOpen] = useState(false);
    const confirm = useConfirm();

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'campaign_type',
          headerName: 'Campaign Type',
          width: 250,
          editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName:'Delete',
            getActions: (params: any) => [
              <GridActionsCellItem icon={<DeleteIcon />} onClick={() => deleteType(params.id, params.row.campaign_type)} label="Delete" />,
            ]
          }
      ];
  

    const handleClick = () => {
        setOpen(!open);
    };

    function deleteType (id:any, type: string){
        confirm({ description: `You are about to delete type with id: ${id} and name : ${type}` , confirmationButtonProps: { autoFocus: true }})
          .then(() => { settingsClient.delete_campaign_type(id).then(res=> {
           if (res.status === 200){
            toast.success('Type has been deleted')
            settingsClient.get_all_campaign_types()
            .then(res =>{
              setState({
                  ...state,
                  campaign_types: res.data.RESULTS
              })
        })
           } else {
            toast.error("Something went wrong, try again or contact Development");
           }
          })})
          .catch(() => { /* ... */ });
    };
    
    return (
        <>
            <Box>
                    <Box  style={{display:'flex', flexWrap:'wrap'}}>
                        <Box my={2}>
                            <CreateTypeDialog/>
                        </Box>
                        <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={state.campaign_types}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                        />
                        </div>
                    </Box>
            </Box>           
        </>
    )
}

export default CampaignTypesSettings;